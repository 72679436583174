import { Storage } from "aws-amplify";
import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";

const flashNotificationsModule = getModule(FlashNotifications);
/* 
Upload media file to S3 bucket
param file 
param type 
param name 
*/
export async function uploadMediaFile(payload: {
    file: File;
    type: string;
    name: string;
    srcFileName: string;
    hideMessage?: boolean;
    key: string;
}): Promise<string> {
    const fileObj = payload.file as any;
    const metadata = {
        filename: payload.name,
    };
    const blockMessage = payload.hideMessage ? payload.hideMessage : false;
    fileObj.metadata = metadata;
    const mediaType = payload.type;
    const fileName = payload.name;
    const folderKey = payload.key;

    const folderName = getS3Folder(mediaType);
    const key = `${folderName.name}/${folderKey}/${fileName}`;
    const result = `${folderKey}/${fileName}`;

    if (mediaType == "document") {
        folderName.contentType = fileObj.type;
    }

    try {
        const fileUpload = await Storage.put(key, fileObj, {
            level: "public",
            // resumable: true,
            contentDisposition: "inline",
            contentType: fileObj.type,
            metadata: {
                srcFile: payload.srcFileName,
            },
        });
    } catch (error) {
        console.error(error);
        flashNotificationsModule.error({
            message: `Unexpected error while uploading ${error}`,
            duration: 10000,
        });
    }
    if (!blockMessage) {
        flashNotificationsModule.success({
            message: `Successfully uploaded`,
            duration: 5000,
        });
    }
    return result;
}
/* 
Get a link for a file frome S3 bucket, this link will expired in 3000 sec
*/
export async function getS3FileUrl(payload: {
    fileName: string;
    type: string;
    workId?: number;
}): Promise<string> {
    const fileName = payload.fileName;
    const fileType = payload.type;
    const folderName = getS3Folder(fileType);

    if (process.env.VUE_APP_MEDIA_URL) {
        return `${process.env.VUE_APP_MEDIA_URL}${folderName.name}/${fileName}`;
    } else {
        let fileURL = "";
        try {
            // Storage.configure({ level: "public" });
            fileURL = await Storage.get(`${folderName.name}/${fileName}`, {
                validateObjectExistence: false,
            });
            return fileURL;
        } catch (e) {
            return "";
        }
    }
}
// get a folder name for the file path base on the factor type
function getS3Folder(type: string): any {
    const folderNameAndType = {
        name: "files",
        contentType: "application/*",
    };
    switch (type) {
        case "image":
            folderNameAndType.name = "images";
            folderNameAndType.contentType = "image/*";
            break;
        case "video":
            folderNameAndType.name = "videos";
            folderNameAndType.contentType = "video/*";
            break;
        case "audio":
            folderNameAndType.name = "audios";
            folderNameAndType.contentType = "audio/*";
            break;
        case "document":
            folderNameAndType.name = "documents";
            folderNameAndType.contentType = "application/*";
            break;
        default:
            folderNameAndType.name = "files";
            folderNameAndType.contentType = "application/*";
            break;
    }

    return folderNameAndType;
}

export async function removeFile(payload: {
    type: string;
    fileName: string;
    hideMessage?: boolean;
}): Promise<boolean> {
    const folderName = getS3Folder(payload.type);

    const key = `${folderName.name}/${payload.fileName}`;
    try {
        await Storage.remove(key);
        if (!payload.hideMessage)
            flashNotificationsModule.success({
                message: `Successfully removed file`,
                duration: 5000,
            });
    } catch (error) {
        console.error(error);
        flashNotificationsModule.error({
            message: `Unexpected error while removing file: ${error}`,
            duration: 10000,
        });
    }
    return false;
}
