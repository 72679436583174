
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Viewpoint, ViewpointItem } from "@/graphql/API";
import Viewpoints from "@/store/modules/Viewpoints";
import draggable from "vuedraggable";

const viewpointsModule = getModule(Viewpoints);

@Component({
    components: {
        draggable,
    },
})
export default class ViewpointToggle extends Vue {
    /* Selected Viewpoint IDS */
    @PropSync("viewpointList")
    syncedViewpointList!: number[];

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: true, type: Boolean })
    isTab!: boolean;

    @Prop({ default: false, type: Boolean })
    createToggle!: boolean;

    @PropSync("newLock")
    syncedLock!: boolean;

    @PropSync("userEnabled")
    syncedUserEnabled!: boolean;

    /* Available IDS */
    @PropSync("ids")
    syncedIds!: number[];

    @PropSync("sortDef")
    syncedSortDef!: boolean;

    @PropSync("selectUserViewpoints")
    syncedSelectUserViewpoints!: boolean;

    @Prop({ default: () => [], type: Array })
    userViewpoints!: number[];

    private drag = false;
    private loading = false;

    get showAutoSelect(): boolean {
        return (
            !this.allItemsSelected &&
            (!this.syncedLock || this.syncedUserEnabled)
        );
    }

    get viewpoints(): { [id: number]: Viewpoint } {
        return viewpointsModule.viewpointList;
    }

    get unSelectedLength(): number {
        if (this.syncedIds) {
            return this.syncedIds.filter(
                (id) => !this.syncedViewpointList.includes(id)
            ).length;
        } else {
            return 0;
        }
    }

    get allItemsSelected(): boolean {
        return this.unSelectedLength == 0;
    }

    get dragDisabled(): boolean {
        return this.syncedSortDef;
    }

    private selectItem(val: Viewpoint, isCopy: boolean): void {
        this.$emit("viewpoint-select", {
            viewpoint: val,
            isCopy: isCopy,
        });
    }

    private createNew(): void {
        this.$emit("viewpoint-select", {
            viewpoint: null,
            isCopy: false,
        });
    }

    private toggleItem(id: number): void {
        if (this.syncedViewpointList.includes(id)) {
            this.syncedViewpointList.splice(
                this.syncedViewpointList.indexOf(id),
                1
            );
        } else {
            this.syncedViewpointList.push(id);
        }
    }

    private selectAllItems(val: boolean): void {
        if (val) {
            this.syncedIds.forEach((id) => {
                if (!this.syncedViewpointList.includes(id)) {
                    this.syncedViewpointList.push(id);
                }
            });
        } else {
            this.syncedViewpointList.splice(0);
        }
    }

    private selectNewViewpoints(viewpoints: Viewpoint[]): void {
        if (viewpoints.length && (this.isTab || this.embed)) {
            viewpoints.forEach((viewpoint) => {
                if (
                    viewpoint &&
                    !this.syncedViewpointList.includes(viewpoint.id)
                ) {
                    this.syncedViewpointList.push(viewpoint.id);
                }
            });
        }
    }
}
