var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-factor-group",class:{
        'vp-factor-group--right': _vm.languageRight,
        'vp-factor-group--drag': _vm.drag,
        'vp-factor-group--inner': _vm.depth > 0,
    }},[_c('draggable',{staticClass:"vp-factor-group-drag-area",class:{
            'vp-factor-group-drag-area--empty': _vm.currentTreeLevelLength == 0,
        },attrs:{"list":_vm.currentTreeLevel,"group":"factor-tree","disabled":!_vm.editModel || _vm.depth == 0,"ghost-class":"vp-factor-ghost","drag-class":"vp-factor-drag-class","handle":".vp-factor-item__drag"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.dragChange($event, _vm.parentId)}}},[_vm._l((_vm.currentTreeLevel),function(factor,index){return _c('div',{key:factor.id,staticClass:"vp-factor-drag-list"},[(factor.is_group || factor.is_table)?_c('v-expansion-panels',{staticClass:"vp-accordion vp-accordion--plain vp-accordion--clear",attrs:{"accordion":"","flat":""},model:{value:(_vm.syncedOpen[factor.id]),callback:function ($$v) {_vm.$set(_vm.syncedOpen, factor.id, $$v)},expression:"syncedOpen[factor.id]"}},[_c('v-expansion-panel',{on:{"change":function($event){return _vm.loadChildren(factor.id)}}},[_c('v-expansion-panel-header',{attrs:{"expand-icon":""},on:{"click":function($event){$event.stopPropagation();}}},[_c('RecrusiveModelFactor',{attrs:{"factor":factor,"is-group":"","depth":_vm.depth,"type":factor.is_group ? 'group' : 'table',"active":_vm.selectedFactors.includes(factor.id),"last":index == _vm.currentTreeLevelLength - 1,"filters":_vm.filters,"hidden-depth":_vm.hiddenDepth,"edit-model":_vm.editModel,"hide-drag":_vm.hideDragId ? _vm.hideDragId == factor.id : false},on:{"toggle-factor":_vm.sendToggledFactor}},[_c('v-btn',{staticClass:"btn-vp-expand",attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.languageRight ? "mdi-menu-left" : "mdi-menu-right")+" ")])],1)],1)],1),_c('v-expansion-panel-content',[_c('RecursiveModelGroup',{attrs:{"selectedFactors":_vm.selectedFactors,"parentId":factor.id,"depth":_vm.depth + 1,"editModel":_vm.editModel,"filters":_vm.filters,"open":_vm.syncedOpen,"parent-last":index == _vm.currentTreeLevelLength - 1,"hidden-depth":index == _vm.currentTreeLevelLength - 1
                                    ? [..._vm.hiddenDepth, _vm.depth]
                                    : _vm.hiddenDepth,"loading":_vm.syncedLoading},on:{"update:open":function($event){_vm.syncedOpen=$event},"update:loading":function($event){_vm.syncedLoading=$event},"toggle-factor":_vm.sendToggledFactor}})],1)],1)],1):_c('RecrusiveModelFactor',{attrs:{"factor":factor,"depth":_vm.depth,"type":factor.value_type,"active":_vm.selectedFactors.includes(factor.id),"last":index == _vm.currentTreeLevelLength - 1,"parent-last":_vm.parentLast,"filters":_vm.filters,"hidden-depth":_vm.hiddenDepth,"edit-model":_vm.editModel},on:{"toggle-factor":_vm.sendToggledFactor}})],1)}),(_vm.currentTreeLevelLength == 0 && _vm.depth > 0)?_c('div',{staticClass:"vp-factor-group-drag-area__empty"},[_c('div',{staticClass:"vp-factor-item__offset-wrapper"},_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"vp-factor-item__offset vp-factor-item__offset--last",class:{
                        'vp-factor-item__offset--parent-last': _vm.parentLast,
                        'vp-factor-item__offset--hidden': _vm.hiddenDepth.includes(index),
                    }})}),0),_c('div',{staticClass:"vp-factor-item__icon"},[(_vm.syncedLoading.includes(_vm.parentId))?_c('v-progress-circular',{attrs:{"indeterminate":"","size":18,"color":"primary"}}):_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" mdi-dots-square ")])],1)]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }