
import { Component, Prop, Vue } from "vue-property-decorator";
import VpDialog from "@/components/ui/VpDialog.vue";

@Component({
    components: {
        VpDialog,
    },
})
export default class UnSavedChanges extends Vue {
    @Prop({ type: Boolean, default: false })
    value!: boolean;

    @Prop()
    tabLink!: string;

    @Prop({ default: false, type: Boolean })
    routingWarning!: boolean;

    private closeDialog(): void {
        this.$emit("close");
    }

    private saveDialog(): void {
        this.$emit("save");
    }
}
