
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { TableHeader, TableAction } from "@/graphql/API";
import vuetify from "@/plugins/vuetify";

@Component({
    components: {},
})
export default class TableManagerSettings extends Vue {
    @Prop({ type: Boolean, default: false })
    embed!: boolean;

    @Prop({ type: Boolean, default: true })
    appCreate!: boolean;

    @PropSync("checkboxes")
    syncedCheckboxes!: boolean;

    @PropSync("headers")
    syncedHeaders!: { [id: string]: TableHeader };

    @PropSync("actions")
    syncedActions!: { [id: string]: TableAction };

    @PropSync("tools")
    syncedTools!: { [id: string]: TableAction };

    @Prop({ type: Boolean, default: true })
    write!: boolean;

    private open = [0, 1, 2];

    get languageRight(): boolean {
        return vuetify.framework.rtl;
    }

    private toggleLock(val: boolean | undefined): boolean {
        if (val == undefined || val == false) {
            console.log("true")
            return true;
        } else {
            console.log("False")
            return false;
        }
    }
}
