import {
    Changes,
    Viewpoint,
    ViewpointCopyInput,
    ViewpointMapping,
    ViewpointMappingUpdateInputL,
} from "@/graphql/API";
import {
    CopyViewpoint,
    CreateViewpoint,
    CreateViewpointL,
    CreateViewpointMapping,
    CreateViewpointMappingL,
    DeleteViewpoint,
    DeleteViewpointMapping,
    GetViewpointMappings,
    GetViewpoints,
    OnViewpointChange,
    OnViewpointMappingChange,
    UpdateViewpoint,
    UpdateViewpointMapping,
    UpdateViewpointMappingL,
    BatchUpdateValuePresentation,
    SetInstanceAsDefault,
    BatchUpdateViewpointMappingL,
} from "@/graphql/custom";
import { getViewpointMappings, getViewpoints } from "@/graphql/queries";
import { API } from "aws-amplify";
import {
    Action,
    config,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import { GraphQLResult } from "@aws-amplify/api";
import store from "..";
import {
    copyViewpoint,
    createViewpointL,
    createViewpointMappingL,
    deleteViewpoint,
    deleteViewpointMapping,
    updateViewpoint,
    updateViewpointMappingL,
    batchUpdateValuePresentation,
    setInstanceAsDefault,
    batchUpdateViewpointMappingL,
    batchUpdateViewpointWeightL,
} from "@/graphql/mutations";
import Scoring from "./Scoring";
import Vue from "vue";
import Factors from "./Factors";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import Users from "@/store/modules/Users";

config.rawError = true;

const name = "viewpoints";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Viewpoints extends VuexModule {
    viewpoints: Viewpoint[] = [];
    selectedViewpointId: number | null = null;
    viewpointMappings: {
        [viewpointId: number]: { [factorId: number]: ViewpointMapping };
    } = {};

    createdViewpointId: number | null = null;

    get viewpointList(): { [id: number]: Viewpoint } {
        return this.viewpoints.reduce((acc, a) => {
            return {
                ...acc,
                [a.id]: a,
            };
        }, {});
    }

    get currentUserId(): string | null {
        return getModule(Users).currentUserId;
    }

    get userViewpoints(): Viewpoint[] {
        if (this.currentUserId) {
            return this.viewpoints.filter((viewpoint) => {
                if (this.currentUserId && viewpoint?.owner) {
                    return (
                        viewpoint.owner.localeCompare(this.currentUserId) == 0
                    );
                } else {
                    return false;
                }
            });
        } else {
            return [];
        }
    }

    @Mutation
    clear(): void {
        this.viewpoints = [];
        this.selectedViewpointId = null;
        this.viewpointMappings = {};
    }

    @Mutation
    setViewpoints(viewpoints: Viewpoint[]): void {
        this.viewpoints = viewpoints;
    }

    @Mutation
    setActiveViewpoint(id: number | null): void {
        this.selectedViewpointId = id;
    }

    @Mutation
    setCreatedViewpointId(id: number | null): void {
        this.createdViewpointId = id;
    }

    @Mutation
    setViewpointMappings(payload: {
        viewpointMappings: ViewpointMapping[];
    }): void {
        const viewpointMappings = payload.viewpointMappings;
        // const dictionary = Object.assign(
        //     {},
        //     ...viewpointMappings.map((x) => ({ [x.factor_id]: x }))
        // );
        for (const viewpointMapping of viewpointMappings) {
            const viewpoint_id = viewpointMapping.viewpoint_id;
            if (this.viewpointMappings[viewpointMapping.viewpoint_id] == null) {
                Vue.set(this.viewpointMappings, viewpoint_id, {});
            }
            Vue.set(
                this.viewpointMappings[viewpoint_id],
                viewpointMapping.factor_id,
                viewpointMapping
            );
        }
    }

    @Mutation
    setViewpointMapping(viewpointMapping: ViewpointMapping): void {
        const viewpointId = viewpointMapping.viewpoint_id;
        const factorId = viewpointMapping.factor_id;
        if (this.viewpointMappings.hasOwnProperty(viewpointId)) {
            Vue.set(
                this.viewpointMappings[viewpointId],
                factorId,
                viewpointMapping
            );
        } else {
            Vue.set(this.viewpointMappings, viewpointId, {});
            Vue.set(
                this.viewpointMappings[viewpointId],
                factorId,
                viewpointMapping
            );
        }
    }

    @Mutation
    removeViewpointMapping(viewpointMapping: ViewpointMapping): void {
        const viewpointId = viewpointMapping.viewpoint_id;
        const factorId = viewpointMapping.factor_id;
        if (this.viewpointMappings.hasOwnProperty(viewpointId)) {
            Vue.delete(this.viewpointMappings[viewpointId], factorId);
            if (Object.keys(this.viewpointMappings[viewpointId]).length === 0) {
                Vue.delete(this.viewpointMappings, viewpointId);
            }
        }
    }

    @Mutation
    addViewpoint(viewpoint: Viewpoint): void {
        const index = this.viewpoints.findIndex((vp) => vp.id === viewpoint.id);
        if (index >= 0) {
            this.viewpoints.splice(index, 1, viewpoint);
        } else this.viewpoints.push(viewpoint);
    }

    @Mutation
    deleteStateViewpoint(viewpoint: Viewpoint): boolean {
        const index = this.viewpoints.findIndex((vp) => vp.id === viewpoint.id);
        if (index >= 0) {
            this.viewpoints.splice(index, 1);
            return true;
        }
        return false;
    }

    @Action
    async fetchViewpoints(decisionId: number): Promise<Viewpoint[]> {
        if (decisionId != null) {
            this.clear();
            const viewpoints = (await GRAPHQL_API.graphqlQueryRequest({
                query: getViewpoints,
                variables: {
                    decision_id: decisionId,
                },
            })) as GraphQLResult<GetViewpoints>;
            if (viewpoints.data?.getViewpoints) {
                this.setViewpoints(viewpoints.data?.getViewpoints || null);
            }
            return viewpoints.data?.getViewpoints || [];
        }
        return [];
    }

    @Action
    async copyViewpoint(input: ViewpointCopyInput): Promise<Viewpoint | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: copyViewpoint,
            variables: {
                input,
            },
        })) as GraphQLResult<CopyViewpoint>;
        const viewpoint = res.data?.copyViewpoint?.viewpoints[0];
        if (viewpoint != null) {
            this.fetchViewpointMappings({
                viewpoint_id: viewpoint.id,
                root_only: true,
            });
            this.addViewpoint(viewpoint);
            return viewpoint;
        } else {
            return null;
        }
    }

    @Action
    async createViewpointL(payload: {
        decisionId: number;
        name: string;
    }): Promise<Changes | null> {
        const name = payload.name;
        const decision_id = payload.decisionId;
        if (name != null && decision_id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createViewpointL,
                variables: {
                    name,
                    decision_id,
                },
            })) as GraphQLResult<CreateViewpointL>;
            if (
                res.data &&
                res.data.createViewpointL != null &&
                res.data.createViewpointL.viewpoints
            ) {
                const viewpoints = res.data.createViewpointL.viewpoints;
                const viewpoint_mappings =
                    res.data.createViewpointL.viewpoint_mappings;
                this.setCreatedViewpointId(viewpoints[0].id);
                this.addViewpoint(res.data.createViewpointL.viewpoints[0]);
                if (viewpoint_mappings) {
                    this.setViewpointMappings({
                        viewpointMappings: viewpoint_mappings,
                    });
                }
                return res.data.createViewpointL;
            }
        }
        return null;
    }

    @Action
    async updateViewpoint(payload: {
        viewpointId: number;
        name: string;
        description: string;
    }): Promise<Changes | null> {
        const name = payload.name;
        const id = payload.viewpointId;
        const description = payload.description;
        if (name != null && id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateViewpoint,
                variables: {
                    name,
                    id,
                    description,
                },
            })) as GraphQLResult<UpdateViewpoint>;
            if (
                res.data &&
                res.data.updateViewpoint != null &&
                res.data.updateViewpoint.viewpoints
            ) {
                const viewpoints = res.data.updateViewpoint.viewpoints;
                this.setCreatedViewpointId(viewpoints[0].id);
                this.addViewpoint(res.data.updateViewpoint.viewpoints[0]);
            }
            return res.data?.updateViewpoint || null;
        }
        return null;
    }

    @Action
    async deleteViewpoint(id: number): Promise<Viewpoint | undefined | null> {
        if (id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteViewpoint,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteViewpoint>;
            if (
                res.data &&
                res.data.deleteViewpoint != null &&
                res.data.deleteViewpoint.viewpoints
            ) {
                const viewpoints = res.data.deleteViewpoint.viewpoints;
                this.setCreatedViewpointId(viewpoints[0].id);
                this.deleteStateViewpoint(
                    res.data.deleteViewpoint.viewpoints[0]
                );
            }
            return res.data?.deleteViewpoint?.viewpoints[0];
        }
        return null;
    }

    @Action
    selectActiveViewpoint(id: number | null): void {
        this.setActiveViewpoint(id);
        if (id != null) {
            // getModule(Scoring).fetchScores({ viewpoint_id: id });
            this.fetchViewpointMappings({ viewpoint_id: id, root_only: true });
        }
    }

    @Action
    async fetchViewpointMappings(payload: {
        viewpoint_id: number;
        root_only?: boolean;
        parent_id?: number;
    }): Promise<ViewpointMapping[] | null> {
        const viewpoint_id = payload.viewpoint_id;
        const root_only = payload.root_only ? payload.root_only : false;
        const parent_id = payload.parent_id;
        if (viewpoint_id != null) {
            const res = (await GRAPHQL_API.graphqlQueryRequest({
                query: getViewpointMappings,
                variables: {
                    viewpoint_id,
                    root_only,
                    parent_id,
                },
            })) as GraphQLResult<GetViewpointMappings>;
            const viewpointMappings = res.data?.getViewpointMappings;
            if (viewpointMappings) {
                this.setViewpointMappings({
                    viewpointMappings: viewpointMappings,
                });
                return viewpointMappings;
            }
        }
        return null;
    }

    @Action
    async createViewpointMapping(
        viewpointMapping: ViewpointMapping
    ): Promise<ViewpointMapping | null> {
        const viewpoint_id = viewpointMapping.viewpoint_id;
        const factor_id = viewpointMapping.factor_id;

        let changes = null;
        if (viewpoint_id == null || factor_id == null) {
            return null;
        }

        //If scoring attributes exist then hit lambda endpoint
        if (
            viewpointMapping.index_rule_id ||
            viewpointMapping.weight ||
            viewpointMapping.score_rule_id ||
            !viewpointMapping.use_m_index ||
            !viewpointMapping.use_m_score
        ) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createViewpointMappingL,
                variables: {
                    ...viewpointMapping,
                },
            })) as GraphQLResult<CreateViewpointMappingL>;
            changes = res.data?.createViewpointMappingL;
            // If no scoring attributes then just hit normal endpoint
        } else {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createViewpointMappingL,
                variables: {
                    ...viewpointMapping,
                },
            })) as GraphQLResult<CreateViewpointMappingL>;
            changes = res.data?.createViewpointMappingL;
        }
        if (changes && changes.viewpoint_mappings != null) {
            const viewpointMapping = changes.viewpoint_mappings[0];
            this.setViewpointMapping(viewpointMapping);
            return viewpointMapping;
        }
        return null;
    }

    @Action
    async updateViewpointMappingL(
        input: ViewpointMapping[]
    ): Promise<ViewpointMapping[] | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: batchUpdateViewpointMappingL,
            variables: {
                input,
            },
        })) as GraphQLResult<BatchUpdateViewpointMappingL>;
        const changes = res.data?.batchUpdateViewpointMappingL;
        if (changes && changes.viewpoint_mappings != null) {
            const viewpointMappings = changes.viewpoint_mappings;
            this.setViewpointMappings({ viewpointMappings: viewpointMappings });
            return viewpointMappings;
        }
        return null;
    }

    @Action
    async updateViewpointMappingWeight(
        input: ViewpointMapping[]
    ): Promise<ViewpointMapping[] | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: batchUpdateViewpointWeightL,
            variables: {
                input,
            },
        })) as GraphQLResult<BatchUpdateViewpointMappingL>;
        const changes = res.data?.batchUpdateViewpointMappingL;
        if (changes && changes.viewpoint_mappings != null) {
            const viewpointMappings = changes.viewpoint_mappings;
            this.setViewpointMappings({
                viewpointMappings: viewpointMappings,
            });
            if (changes.scores)
                getModule(Scoring).setScores({ scores: changes.scores });
            return viewpointMappings;
        }
        return null;
    }

    @Action
    async batchUpdateValuePresentation(
        input: ViewpointMappingUpdateInputL[]
    ): Promise<ViewpointMapping[] | null> {
        const res = (await API.graphql({
            query: batchUpdateValuePresentation,
            variables: {
                input,
            },
        })) as GraphQLResult<BatchUpdateValuePresentation>;
        const changes = res.data?.batchUpdateViewpointMappingL;
        if (changes && changes.viewpoint_mappings != null) {
            const viewpointMappings = changes.viewpoint_mappings;
            this.setViewpointMappings({
                viewpointMappings: viewpointMappings,
            });
            return viewpointMappings;
        }
        return null;
    }

    @Action
    async deleteViewpointMapping(payload: {
        viewpointId: number;
        factorId: number;
    }): Promise<ViewpointMapping | null> {
        const viewpoint_id = payload.viewpointId;
        const factor_id = payload.factorId;
        if (viewpoint_id != null && factor_id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteViewpointMapping,
                variables: {
                    viewpoint_id,
                    factor_id,
                },
            })) as GraphQLResult<DeleteViewpointMapping>;
            const changes = res.data?.deleteViewpointMapping;
            if (changes && changes.viewpoint_mappings != null) {
                this.removeViewpointMapping(changes.viewpoint_mappings[0]);
                return changes.viewpoint_mappings[0];
            }
        }
        return null;
    }

    @Action
    addEditViewpoint(payload: {
        viewpoint: Viewpoint;
        mutation: string | undefined;
    }): void {
        if (
            !payload.viewpoint ||
            payload.viewpoint.id === this.createdViewpointId
        ) {
            this.setCreatedViewpointId(null);
            return;
        }
        this.addViewpoint(payload.viewpoint);
        this.fetchViewpointMappings({
            viewpoint_id: payload.viewpoint.id,
            root_only: true,
        });
        if (
            this.createdViewpointId &&
            this.createdViewpointId === payload.viewpoint.id
        ) {
            this.selectActiveViewpoint(payload.viewpoint.id);
            this.setCreatedViewpointId(null);
        }
    }

    @Action
    removeViewpoint(viewpoint: Viewpoint): void {
        if (this.createdViewpointId == viewpoint.id) {
            this.setCreatedViewpointId(null);
            return;
        }
        const viewpointDeleted = this.deleteStateViewpoint(viewpoint);
        if (viewpointDeleted) {
            if (viewpoint.id === this.selectedViewpointId) {
                this.selectActiveViewpoint(null);
            }
        }
    }

    @Action
    removeViewpoints(viewpoints: Viewpoint[]): void {
        if (viewpoints == null) {
            return;
        }

        viewpoints.forEach((viewpoint) => {
            const viewpointDeleted = this.deleteStateViewpoint(viewpoint);
            if (viewpointDeleted) {
                if (viewpoint.id === this.selectedViewpointId) {
                    this.selectActiveViewpoint(null);
                }
            }
        });
    }

    @Action
    addUpdateViewpointMappings(viewpointMappings: ViewpointMapping[]): void {
        if (viewpointMappings == null) {
            return;
        }

        viewpointMappings.forEach((viewpointMapping: ViewpointMapping) => {
            if (viewpointMapping != null) {
                this.setViewpointMapping(viewpointMapping);
            }
        });
    }

    @Action
    async setInstanceAsDefault(viewpoint_id: number): Promise<boolean | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: setInstanceAsDefault,
            variables: { viewpoint_id },
        })) as GraphQLResult<SetInstanceAsDefault>;
        const defaultInstanse = res.data?.setInstanceAsDefault;
        if (defaultInstanse) {
            return defaultInstanse;
        } else {
            return null;
        }
    }
}
