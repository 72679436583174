
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Choice,
    Viewpoint,
    Locks,
    FactorOptions,
    ScoreOptions,
    ScoreClassSettings,
} from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
const decisionsModule = getModule(Decisions);

@Component({
    components: {},
})
export default class CompareOptions extends Vue {
    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @PropSync("locks")
    syncedLocks!: Locks;

    @PropSync("scoresEditable")
    syncedScoresEditable!: boolean;

    @PropSync("customScore")
    syncedCustomScore!: boolean;

    @PropSync("choiceEditable")
    syncedChoiceEditable!: boolean;

    @PropSync("factorDisplay")
    syncedFactorDisplay!: FactorOptions;

    @PropSync("weightsEditable")
    syncedWeightsEditable!: boolean;

    @PropSync("classRating")
    syncedClassRating!: boolean;

    @PropSync("scoreRule")
    syncedScoreRule!: boolean;

    @PropSync("scoreDisplay")
    syncedScoreDisplay!: ScoreOptions;

    @PropSync("selectorSettings")
    syncedClassSelector!: ScoreClassSettings;

    @PropSync("scoreRuleEditable")
    syncedScoreRuleEditable!: boolean;

    private classSelectorLabels = {
        label: "Labels",
        description: "Descriptions",
        icons: "Icons",
        colour: "Color",
    };

    get scoreDisplayLabels(): { [id: string]: string } {
        return {
            bar: "Bar",
            score: "Score",
            weights: "Weights",
            weights_icon: "Weight Icons",
            comments: "Comments",
            normalized_score: "Normalized Score",
            class_label: "Class Label",
            class_icons: "Class Icons",
            class_description: "Class Description",
            class_colour: "Heat Map",
            choice_data: `${this.choiceLabel} Data`,
        };
    }

    private factorDisplayLabels = {
        factor_description: "Factor Description",
    };

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    get editAllLocked(): boolean {
        return (
            this.syncedLocks["choiceEditable"] &&
            this.syncedLocks["scoresEditable"] &&
            this.syncedLocks["classRating"] &&
            this.syncedLocks["weightsEditable"] &&
            this.syncedLocks["scoreRuleEditable"]
        );
    }

    get scoreDisplayAllLocked(): boolean {
        return (
            this.syncedLocks["scoreDisplay"]["bar"] &&
            this.syncedLocks["scoreDisplay"]["score"] &&
            this.syncedLocks["scoreDisplay"]["weights"] &&
            this.syncedLocks["scoreDisplay"]["normalized_score"] &&
            this.syncedLocks["scoreDisplay"]["class_label"] &&
            this.syncedLocks["scoreDisplay"]["class_icons"] &&
            this.syncedLocks["scoreDisplay"]["class_description"] &&
            this.syncedLocks["scoreDisplay"]["choice_data"]
        );
    }

    get classSelectorAllLocked(): boolean {
        return (
            this.syncedLocks["classSelector"]["label"] &&
            this.syncedLocks["classSelector"]["colour"] &&
            this.syncedLocks["classSelector"]["icons"]
        );
    }

    get factorAllLocked(): boolean {
        return this.syncedLocks["factorDisplay"]["factor_description"];
    }

    get displayAllLocked(): boolean {
        return (
            this.scoreDisplayAllLocked &&
            this.classSelectorAllLocked &&
            this.factorAllLocked
        );
    }

    private lockFactor(val: boolean): void {
        Vue.set(this.syncedLocks["factorDisplay"], "factor_description", val);
    }

    /* Locks Class Selector Section ("Class Selector under "Display Options") */
    private lockClassSelector(val: boolean): void {
        Vue.set(this.syncedLocks["classSelector"], "label", val);
        Vue.set(this.syncedLocks["classSelector"], "colour", val);
        Vue.set(this.syncedLocks["classSelector"], "icons", val);
    }

    /* Locks Score Display Section (First section udner "Display Options") */
    private lockScoreDisplay(val: boolean): void {
        Vue.set(this.syncedLocks["scoreDisplay"], "bar", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "score", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "weights", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "comments", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "normalized_score", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "class_label", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "class_icons", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "class_description", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "class_colour", val);
        Vue.set(this.syncedLocks["scoreDisplay"], "choice_data", val);
    }

    /* Locks options under "Edit Options" */
    private lockEdits(val: boolean): void {
        Vue.set(this.syncedLocks, "choiceEditable", val);
        Vue.set(this.syncedLocks, "scoresEditable", val);
        Vue.set(this.syncedLocks, "classRating", val);
        Vue.set(this.syncedLocks, "weightsEditable", val);
        Vue.set(this.syncedLocks, "scoreRuleEditable", val);
    }

    private lockDisplays(val: boolean): void {
        this.lockClassSelector(val);
        this.lockScoreDisplay(val);
        this.lockFactor(val);
    }
}
