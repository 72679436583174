
import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
    components: {},
})
export default class CompareCellContent extends Vue {
    @Prop({ type: Boolean, default: false })
    blankScore!: boolean;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    get showScoreRule(): boolean {
        return this.$slots.hasOwnProperty("scoreRule");
    }

    get showWeights(): boolean {
        return this.$slots.hasOwnProperty("weights");
    }

    get showComments(): boolean {
        return this.$slots.hasOwnProperty("comments");
    }
}
