
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";

@Component({
    components: {},
})
export default class AccessDenied extends Vue {
    @Prop()
    message!: string;
}
