
import { Component, Prop, PropSync, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Factors from "@/store/modules/Factors";
import { Factor } from "@/graphql/API";
import FactorToggle from "@/components/model/FactorNav/FactorToggle.vue";

const modelModule = getModule(Factors);
@Component({
    name: "FactorToggleGroup",
    components: {
        FactorToggle,
    },
})
export default class FactorToggleGroup extends Vue {
    @Prop({ default: -1, type: Number })
    parentId!: number;

    @Prop({ default: 1, type: Number })
    depth!: number;

    @Prop({ default: "mdi-checkbox-blank-outline", type: String })
    offIcon!: string;

    @Prop({ default: "mdi-checkbox-marked", type: String })
    onIcon!: string;

    @Prop({ default: false, type: Boolean })
    invertColor!: boolean;

    @Prop({ default: false, type: Boolean })
    disabled!: boolean;

    /* If this is false then active factors need to be provided */
    @Prop({ default: true, type: Boolean })
    allAvailable!: boolean;

    /* Array contains selected factor ids */
    @PropSync("selected")
    syncedSelected!: number[];

    @PropSync("rootFactor")
    syncedRootFactor!: number | null;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    /* Array of factor ids that will appear */
    @Prop({ default: () => [], type: Array })
    activeFactors!: number[];

    @Prop({ default: () => [], type: Array })
    hiddenFactors!: number[];

    @Prop({ default: () => [], type: Array })
    lockedFactors!: number[];

    /* Enables multiple factors to be selected */
    @Prop({ default: true, type: Boolean })
    multiSelect!: boolean;

    /* Enables toggling children when clicking a group or table */
    @Prop()
    groupSelect!: boolean;

    /* If the parent is selected or not */
    @Prop({ default: true, type: Boolean })
    parentSelected!: boolean;

    /* Enables button to change the root */
    @Prop({ default: false, type: Boolean })
    rootToggle!: boolean;

    /* If a root factor is set this determines if it
    is a child of the root factor */
    @Prop({ default: true, type: Boolean })
    parentInRoot!: boolean;

    /* Show toggle all button */
    @Prop({ default: false, type: Boolean })
    showToggleAll!: boolean;

    /* Enables toggling parent if child factors are unselected */
    @Prop({ default: false, type: Boolean })
    parentChildActive!: boolean;

    @Prop({ default: true, type: Boolean })
    isTab!: boolean;

    @Prop({ default: false, type: Boolean })
    groupsOnly!: boolean;

    @Prop({ default: true, type: Boolean })
    checkActive!: boolean;

    private openedFactors: { [id: number]: boolean } = {};
    private loading = false;

    get anyActive(): boolean {
        return this.syncedSelected.length > 0;
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get factorMap(): { [id: number]: Factor } {
        return modelModule.factorMap;
    }

    get currentTreeLevel(): Factor[] {
        if (this.groupsOnly) {
            return this.currentFactorTree.filter((factor) => factor.is_group);
        } else {
            return this.currentFactorTree;
        }
    }

    get currentFactorTree(): Factor[] {
        if (this.depth == 1 && this.parentId != -1) {
            return [this.factorMap[this.parentId]];
        } else if (this.factorTree[this.parentId]) {
            if (this.hiddenFactors.length) {
                return this.factorTree[this.parentId].filter((factor) => {
                    return !this.hiddenFactors.includes(factor.id);
                });
            } else {
                return this.factorTree[this.parentId];
            }
        } else return [];
    }

    get factorIds(): number[] {
        return this.currentTreeLevel.map((factor) => factor.id);
    }

    /* Returns true if group has unselected Children */
    get hasVisibleFactors(): boolean {
        if (this.syncedSelected.length) {
            return (
                this.factorIds.filter((id) => {
                    return !this.syncedSelected.includes(id);
                }).length > 0
            );
        }
        return true;
    }

    get groupDisabled(): boolean {
        if (!this.disabled) {
            return !this.insideRoot;
        } else {
            return true;
        }
    }

    get insideRoot(): boolean {
        if (this.syncedRootFactor && this.syncedRootFactor != -1) {
            if (this.parentId == -1) {
                return false;
            } else {
                return (
                    this.parentInRoot || this.syncedRootFactor == this.parentId
                );
            }
        } else {
            return true;
        }
    }

    private openGroup(factor: Factor): void {
        if (
            this.openedFactors.hasOwnProperty(factor.id) &&
            this.openedFactors[factor.id]
        ) {
            Vue.set(this.openedFactors, factor.id, false);
        } else {
            Vue.set(this.openedFactors, factor.id, true);
        }
    }

    /* Sets the root ID */
    private toggleRoot(factor: Factor): void {
        if (this.syncedRootFactor == factor.id) {
            this.syncedRootFactor = -1;
        } else {
            this.syncedRootFactor = factor.id;
        }
    }

    private toggleFactor(factor: Factor) {
        if (factor.is_group || factor.is_table) {
            this.toggleGroup(factor, !this.syncedSelected.includes(factor.id));
        } else {
            this.toggleSingleFactor(
                factor,
                !this.syncedSelected.includes(factor.id)
            );
        }
    }

    private toggleGroup(factor: Factor, val: boolean): void {
        this.toggleSingleFactor(factor, val);
        if (this.groupSelect) {
            if (this.factorTree[factor.id]) {
                this.factorTree[factor.id].forEach((item) => {
                    if (factor.is_group || factor.is_table) {
                        this.toggleGroup(item, val);
                    } else {
                        this.toggleSingleFactor(item, val);
                    }
                });
            }
        }
    }

    private toggleParent(factor: Factor): void {
        if (this.syncedSelected.includes(factor.id)) {
            this.syncedSelected.splice(
                this.syncedSelected.indexOf(factor.id),
                1
            );
        }
        if (factor.parent_id && this.factorMap[factor.parent_id]) {
            this.toggleParent(this.factorMap[factor.parent_id]);
        }
    }

    private toggleSingleFactor(factor: Factor, val: boolean) {
        if (
            !val &&
            this.parentChildActive &&
            factor.parent_id &&
            this.factorMap[factor.parent_id]
        ) {
            /* If removing factor from hidden and parentChildActive
                is active then remove all parents */
            this.toggleParent(this.factorMap[factor.parent_id]);
        }
        if (val && !this.syncedSelected.includes(factor.id)) {
            if (this.multiSelect) {
                this.syncedSelected.push(factor.id);
            } else {
                this.syncedSelected = [factor.id];
            }
        } else if (!val && this.syncedSelected.includes(factor.id)) {
            if (this.multiSelect) {
                this.syncedSelected.splice(
                    this.syncedSelected.indexOf(factor.id),
                    1
                );
            } else {
                this.syncedSelected = [];
            }
        }
    }

    /* This toggles all factors in current group */
    private toggleChildren(val: boolean): void {
        if (val) {
            this.factorIds.map((id) => {
                if (!this.syncedSelected.includes(id)) {
                    this.syncedSelected.push(id);
                }
            });
        } else {
            this.factorIds.map((id) => {
                if (this.syncedSelected.includes(id)) {
                    this.syncedSelected.splice(
                        this.syncedSelected.indexOf(id),
                        1
                    );
                }
            });
        }
    }

    private showTooltip(factor: Factor): boolean {
        return factor.description ? factor.description.length > 0 : false;
    }

    async mounted(): Promise<void> {
        this.onParentIdChange();
        this.onParentSelectedChange();
    }

    @Watch("parentSelected")
    onParentSelectedChange(): void {
        if (this.groupSelect && this.parentId != -1) {
            //this.toggleChildren(this.parentSelected);
        }
    }

    @Watch("factorIds", { immediate: true, deep: true })
    onFactorIdsChange(): void {
        if (
            this.groupSelect &&
            this.parentId != -1 &&
            !this.isTab &&
            this.parentSelected
        ) {
            this.toggleChildren(this.parentSelected);
        }
    }

    @Watch("parentId")
    async onParentIdChange(): Promise<void> {
        if (this.parentId != -1) {
            if (
                !this.factorTree[this.parentId] ||
                this.factorTree[this.parentId].length == 0
            ) {
                this.loading = true;
                await modelModule.fetchChildFactors(this.parentId);
                this.loading = false;
            }
        }
    }
}
