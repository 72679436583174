
import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import {
    Factor,
    Viewpoint,
    Choice,
    ChartOptions,
    CompareValues,
    Value,
    Tab,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import Decisions from "@/store/modules/Decisions";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import CompareBase from "@/components/mixins/CompareBase";
import CompareSettings from "@/components/compare/CompareSettings.vue";
import CompareOptions from "@/components/compare/CompareOptions.vue";
import CompareObjects from "@/components/compare/CompareObjects.vue";
import ViewAppCreator from "@/components/apps/ViewAppCreator.vue";
import ScoreRuleDialog from "@/components/scoring/ScoreRuleDialog.vue";
import FactorNav from "@/components/model/FactorNav.vue";
import ChartDialog from "@/components/compare/ui/ChartDialog.vue";
import { FactorsIcons } from "@/IconLibrary";
import CompareBreadCrumbs from "@/components/compare/ui/CompareBreadCrumbs.vue";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import CompareRecursive from "@/components/compare/CompareRecursive.vue";
import CompareTableColumnsChoices from "@/components/compare/table/CompareTableColumnsChoices.vue";
import CompareTableColumnsViewpoints from "@/components/compare/table/CompareTableColumnsViewpoints.vue";
import CompareTableHeader from "@/components/compare/CompareTableHeader.vue";
import { saveTab } from "@/helpers/TabHelper";
import ComparePagination from "@/components/compare/ui/ComparePagination.vue";
import FactorToggleGroup from "@/components/model/FactorToggle/FactorToggleGroup.vue";
import { getScoresD } from "@/helpers/ScoreHelper";
import CompareColumnHeaders from "@/components/compare/ui/CompareColumnHeaders.vue";
import UnSavedChanges from "@/components/ui/UnSavedChanges.vue";
import { Route } from "vue-router";
import Comments from "@/store/modules/Comments";
import CommentThread from "@/components/comments/CommentThread.vue";

const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);
const viewpointsModule = getModule(Viewpoints);
const flashModule = getModule(FlashNotifications);
const decisionsModule = getModule(Decisions);
const commentsModule = getModule(Comments);

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteUpdate",
    "beforeRouteLeave",
]);
@Component({
    components: {
        ViewWrapper,
        CompareSettings,
        CompareOptions,
        CompareObjects,
        ScoreRuleDialog,
        ViewAppCreator,
        FactorNav,
        ChartDialog,
        CompareBreadCrumbs,
        FactorValueDisplay,
        CompareRecursive,
        CompareTableColumnsChoices,
        CompareTableColumnsViewpoints,
        CompareTableHeader,
        ComparePagination,
        FactorToggleGroup,
        CompareColumnHeaders,
        UnSavedChanges,
        CommentThread,
    },
})
export default class CompareView extends mixins(CompareBase) {
    private selectedFactor: Factor | null = null;
    private selectedViewpoint: Viewpoint | null = null;
    private selectedChoice: Choice | null = null;
    private modalOpen = false;
    private showRuleDialog = false;
    /* Chart Variables */
    private chartFactor: Factor | null = null;
    private chartChoice: Choice | null = null;
    private chartViewpoint: Viewpoint | null = null;
    private chartId: string | null = null;
    private chartHorizontal = false;

    private factorsLoading = false;

    private readonly Icons: Record<string, string> = FactorsIcons;

    private darkBorder = true;
    private nextRoute: Route | null = null;
    private visibleRootId = -1;
    private tableHeaderRows = 1;
    private tableRowIds: string[] = [];
    private selectedThreadId: number | undefined;
    private shareLinkOpen = false;
    private valueErrs = 0;
    beforeRouteLeave(to: any, from: any, next: any): void {
        if (this.valueErrs > 0) {
            this.unSavedRoutingWarningOpen = true;
            this.nextRoute = to;
        } else {
            next();
        }
    }

    beforeMount(): void {
        window.addEventListener("beforeunload", (event) => {
            if (this.valueErrs == 0) return;
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = "";
        });
    }

    get pageTitle(): string {
        if (this.isMainTool || !(this.tabSettings && this.tabSettings.title)) {
            return `Viewing ${this.axisOptions[this.columns].name}`;
        } else {
            return this.tabSettings.title;
        }
    }

    get factorTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    /* Compare Values can be sorted here */
    get compareChoices(): Choice[] {
        return this.choices;
    }

    get compareViewpoints(): Viewpoint[] {
        return this.viewpoints;
    }

    /* Used When factors are columns and a group or table is visible */
    get compareFactors(): Factor[] {
        if (modelModule.factorTree[this.visibleRootId]) {
            return modelModule.factorTree[this.visibleRootId].filter(
                (factor) => {
                    if (this.activeFactors && !this.activeFactors.all) {
                        return this.activeFactors.factors.includes(factor.id);
                    } else {
                        return true;
                    }
                }
            );
        } else {
            return [];
        }
    }

    /* Pagination */
    get paginationChoices(): Choice[] {
        if (this.columns == "choices" && !this.pagination.columns.all) {
            return this.compareChoices.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.compareChoices;
        }
    }

    get paginationViewpoints(): Viewpoint[] {
        if (this.columns == "viewpoints" && !this.pagination.columns.all) {
            return this.compareViewpoints.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.compareViewpoints;
        }
    }

    get paginationFactors(): Factor[] {
        if (this.columns == "factors" && !this.pagination.columns.all) {
            return this.compareFactors.slice(
                this.pagination.columns.position,
                this.pagination.columns.position + this.pagination.columns.items
            );
        } else {
            return this.compareFactors;
        }
    }

    get columnsArray(): Factor[] | Choice[] | Viewpoint[] {
        if (this.columns == "choices") {
            return this.compareChoices;
        } else if (this.columns == "viewpoints") {
            return this.compareViewpoints;
        } else {
            return this.compareFactors;
        }
    }

    get columnPages(): number {
        if (!this.pagination.columns.all && this.pagination.columns.items > 0) {
            return this.columnsArray.length - this.pagination.columns.items;
        } else {
            return 0;
        }
    }
    /* End of Pagination */

    get screenChoices(): Choice[] {
        if (this.data == "choices") {
            if (this.dataItem && choiceModule.choiceList[this.dataItem.id]) {
                return [choiceModule.choiceList[this.dataItem.id]];
            } else {
                return [];
            }
        } else {
            return this.paginationChoices;
        }
    }

    get screenViewpoints(): Viewpoint[] {
        if (this.data == "viewpoints") {
            if (
                this.dataItem &&
                viewpointsModule.viewpointList[this.dataItem.id]
            ) {
                return [viewpointsModule.viewpointList[this.dataItem.id]];
            } else {
                return [];
            }
        } else {
            return this.paginationViewpoints;
        }
    }

    get screenFactors(): Factor[] {
        if (this.data == "factors") {
            if (this.dataItem && modelModule.factorMap[this.dataItem.id]) {
                return [modelModule.factorMap[this.dataItem.id]];
            } else {
                return [];
            }
        } else {
            return this.paginationFactors;
        }
    }

    get valueList(): CompareValues {
        return {
            factors: [],
            choices: this.compareChoices,
            viewpoints: this.compareViewpoints,
        };
    }

    get dataValues(): CompareValues {
        return {
            factors: this.screenFactors,
            choices: this.screenChoices,
            viewpoints: this.screenViewpoints,
        };
    }

    get rootFactor(): Factor | null {
        if (this.visibleRootId != -1) {
            return modelModule.factorMap[this.visibleRootId];
        } else {
            return null;
        }
    }

    get rootGroupIds(): number[] {
        if (this.visibleRootId != -1) {
            return [this.visibleRootId];
        } else if (modelModule.factorTree[this.visibleRootId]) {
            return modelModule.factorTree[this.visibleRootId]
                .filter((factor) => factor.is_group || factor.is_table)
                .map((factor) => factor.id);
        } else {
            return [];
        }
    }

    get factorParentId(): number | null {
        if (this.rootFactor && this.rootFactor.parent_id) {
            return this.rootFactor.parent_id;
        }
        return null;
    }

    get axisOptions(): {
        [index: string]: {
            name: string;
            singular: string;
            value: string;
            dropDown: string;
        };
    } {
        return {
            factors: {
                name: "Factors",
                value: "factors",
                singular: "Factor",
                dropDown: "Factors",
            },
            choices: {
                name: this.choicesLabel,
                value: "choices",
                singular: this.choiceLabel,
                dropDown: `${this.choicesLabel} ${
                    !this.hasChoices ? "(None)" : ""
                }`,
            },
            viewpoints: {
                name: "Viewpoints",
                value: "viewpoints",
                singular: "Viewpoint",
                dropDown: `Viewpoints ${!this.hasViewpoints ? "(None)" : ""}`,
            },
        };
    }

    get dropdownOptions(): { name: string; singular: string; value: string }[] {
        return Object.values(this.axisOptions);
    }

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    get hasChoices(): boolean {
        return this.compareChoices && this.compareChoices.length > 0;
    }

    get hasViewpoints(): boolean {
        return this.compareViewpoints && this.compareViewpoints.length > 0;
    }

    get currentSettings(): Tab {
        if (this.tabSettings) {
            return {
                ...this.tabSettings,
                json: this.currentUiOptions,
                edit_flags: this.currentEditFlags,
                display_flags: this.currentDisplayFlags,
                filter_type: this.indexIds[this.data],
                row_type: this.indexIds[this.rows],
                column_type: this.indexIds[this.columns],
                default_filter_id: this.dataItem ? this.dataItem.id : null,
            };
        } else {
            return {
                id: -1,
                decision_id: this.decisionId ? this.decisionId : -1,
                title: "Compare Screen",
                json: this.currentUiOptions,
                edit_flags: this.currentEditFlags,
                display_flags: this.currentDisplayFlags,
                filter_type: this.indexIds[this.data],
                row_type: this.indexIds[this.rows],
                column_type: this.indexIds[this.columns],
                default_filter_id: this.dataItem ? this.dataItem.id : null,
                type: "CompareView",
            };
        }
    }

    get currentSettingsString(): string {
        return JSON.stringify(this.currentSettings);
    }

    /* Properties to determine which buttons should be visible */
    get columnChoiceData(): boolean {
        if (
            this.columns == "factors" &&
            this.rows == "viewpoints" &&
            this.dataItem
        ) {
            return true;
        }
        return false;
    }

    get showScoreRuleBtn(): boolean {
        return (
            this.scoreRuleEditable &&
            this.scoresEditable &&
            this.columns == "factors" &&
            this.data == "viewpoints"
        );
    }

    get choiceData(): { [choice_id: number]: { [id: number]: Value } } {
        return choiceModule.choiceValues;
    }

    /* Data For Tables */
    get tableFactors(): Factor[][] {
        if (this.rootFactor && this.rootFactor.is_table && this.factorTree) {
            return this.compareFactors.map((item) => {
                return modelModule.factorTree[item.id];
            });
        } else {
            return [];
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get tableData(): any[] {
        if (this.tableFactors && this.tableFactors[0]) {
            return this.tableFactors[0].map((item, index) => {
                if (this.tableFactors) {
                    return this.tableFactors.map((list) => {
                        if (list && list[index]) {
                            return list[index];
                        } else {
                            return null;
                        }
                    });
                }
            });
        } else {
            return [];
        }
    }

    get tableRow(): Factor[] {
        if (this.tableData) {
            return this.tableData.reduce((acc, curr) => {
                return acc.concat(curr);
            }, []);
        } else {
            return [];
        }
    }

    /* Returns true if current tab settings are different than current configs */
    get settingsChanged(): boolean {
        if (this.tabSettingsString && this.isEdit) {
            return (
                this.tabSettingsString.localeCompare(
                    this.currentSettingsString
                ) != 0
            );
        } else {
            return false;
        }
    }

    get totalChanged(): boolean {
        return (
            this.settingsChanged ||
            this.choicesChanged ||
            this.viewpointsChanged ||
            this.factorsChanged
        );
    }

    /* Controls the axises of the table */
    private rowsChange(value: "viewpoints" | "factors" | "choices") {
        if (value == this.columns) {
            this.columns = this.rows;
        } else if (value == this.data) {
            this.data = this.rows;
        }
        this.rows = value;
    }

    private columnsChange(value: "viewpoints" | "factors" | "choices") {
        if (value == this.rows) {
            this.rows = this.columns;
        } else if (value == this.data) {
            this.data = this.columns;
        }

        this.columns = value;
    }

    private swapColumnRows() {
        const rows = this.rows;
        this.rows = this.columns;
        this.columns = rows;
    }

    private updateAxis(
        rows: "viewpoints" | "factors" | "choices",
        columns: "viewpoints" | "factors" | "choices",
        data: "viewpoints" | "factors" | "choices"
    ) {
        this.rows = rows;
        this.columns = columns;
        this.data = data;
    }
    /* End of Axis control */

    /* Score Rule Dialog */
    private toggleFactor(payload: { factor: Factor; viewpoint: Viewpoint }) {
        if (payload.factor && payload.viewpoint) {
            this.selectedFactor = payload.factor;
            this.selectedViewpoint = payload.viewpoint;
            this.modalOpen = true;
            this.showRuleDialog = true;
        }
    }

    private toggleComments(payload: { factor: Factor; viewpoint: Viewpoint, choice: Choice }) {
        console.log("COMMENT", payload)
        if (payload.factor && payload.choice) {
            if (this.sidebarIndex != "comment-view")
                this.openSidebar("comment-view")
            this.selectedFactor = payload.factor;
            this.selectedChoice = payload.choice;
            this.selectedViewpoint = payload.viewpoint ?? -1;
            this.settingsMode = "comment-view"
        }
    }

    private closeDialog(): void {
        this.selectedFactor = null;
        this.selectedViewpoint = null;
        this.showRuleDialog = false;
    }
    /* End of Score Rule Dialog */

    private openSaveWarning(): void {
        this.unSavedWarningOpen = true;
    }

    private async dialogSave(): Promise<void> {
        this.unSavedWarningOpen = false;
        await this.saveSettings();
        if (this.tabLink) {
            window.open(this.tabLink, "_self");
        }
    }

    private async copyShareLink(): Promise<void> {
        if (!this.shareLinkOpen) {
            this.shareLinkOpen = true;
            if (this.shareLink.length > 0) {
                await navigator.clipboard.writeText(this.shareLink);
                flashModule.success({
                    message: "Link Copied",
                    duration: 3000,
                });
            }
        }
    }

    /* Chart Dialog */
    private toggleChart(payload: ChartOptions) {
        if (payload) {
            this.chartId = payload.id ? payload.id : null;
            this.chartFactor = payload.factor ? payload.factor : null;
            this.chartChoice = payload.choice ? payload.choice : null;
            this.chartViewpoint = payload.viewpoint ? payload.viewpoint : null;
            this.chartHorizontal =
                payload.horizontal != undefined ? payload.horizontal : true;
        }
    }

    private closeCharts(): void {
        this.chartId = null;
        this.chartFactor = null;
        this.chartChoice = null;
        this.chartViewpoint = null;
        this.chartHorizontal = false;
    }
    /* End of Chart Dialog */

    private async factorSelected(factor: Factor): Promise<void> {
        if (factor) {
            console.log(`Factor Toggled: ${factor.name}`);
            console.log(factor);
        }
    }

    private async saveSettings(): Promise<void> {
        if (this.tabSettings && this.isEdit) {
            this.loading = true;
            await saveTab({
                tab: {
                    ...this.currentSettings,
                },
                new: false,
                factors: this.deactiveFactors,
                choices: this.selectedChoices,
                viewpoints: this.selectedViewpoints,
            });
            this.loading = false;
        } else {
            this.appCreateOpen = true;
        }
    }

    private async loadChoiceValues(
        choices: Choice[],
        rootId: number | null
    ): Promise<void> {
        if (choices.length && rootId != null) {
            await Promise.all(
                choices.map(async (choice) => {
                    await choiceModule.getChoiceValues({
                        choice_id: choice.id,
                        root_only: rootId == -1 ? true : undefined,
                        parent_id: rootId != -1 ? rootId : undefined,
                    });
                })
            );
        }
    }

    private async loadVpMappings(
        viewpoints: Viewpoint[],
        rootId: number | null
    ): Promise<void> {
        if (viewpoints.length && rootId != null) {
            await Promise.all(
                viewpoints.map(async (vp) => {
                    if (vp && rootId != null) {
                        await viewpointsModule.fetchViewpointMappings({
                            viewpoint_id: vp.id,
                            parent_id: rootId,
                        });
                    }
                })
            );
        }
    }

    async mounted(): Promise<void> {
        this.onRootParentChange();
    }

    @Watch("data")
    onDataChange(): void {
        if (!this.loadingTabSettings) {
            this.dataItem = null;
            if (this.data != "factors") {
                this.visibleRootId = this.rootFactorId;
            }
        }
    }

    @Watch("rootFactorId")
    onRootFactorChange(): void {
        if (
            this.rootFactorId != -1 &&
            this.visibleRootId != this.rootFactorId
        ) {
            if (!this.isFactorInRoot(this.visibleRootId)) {
                this.visibleRootId = this.rootFactorId;
            }
        }
        if (
            this.data == "factors" &&
            this.dataItem &&
            this.rootFactorId != -1 &&
            this.dataItem.id != this.rootFactorId
        ) {
            if (!this.isFactorInRoot(this.dataItem.id)) {
                this.dataItem = null;
                this.chosenFactor = [];
            }
        }
    }

    @Watch("factorParentId")
    async onRootParentChange(): Promise<void> {
        this.loadVpMappings(this.screenViewpoints, this.factorParentId);
        if (this.decisionId) {
            getScoresD({
                d_id: this.decisionId,
                v_ids:
                    this.dataValues.viewpoints &&
                    this.dataValues.viewpoints.length
                        ? this.dataValues.viewpoints.map((v) => v.id)
                        : [],
                c_ids:
                    this.dataValues.choices && this.dataValues.choices.length
                        ? this.dataValues.choices.map((c) => c.id)
                        : [],
                fp_ids:
                    this.rootFactor && this.rootFactor.parent_id
                        ? [this.rootFactor.parent_id]
                        : [],
                root: true,
            });
        }
    }

    @Watch("dataValues")
    async onDataValuesChange(): Promise<void> {
        this.loadVpMappings(this.screenViewpoints, this.factorParentId);
        if (this.decisionId)
            try {
                getScoresD({
                    d_id: this.decisionId,
                    v_ids:
                        this.dataValues.viewpoints &&
                        this.dataValues.viewpoints.length
                            ? this.dataValues.viewpoints.map((v) => v.id)
                            : [],
                    c_ids:
                        this.dataValues.choices &&
                        this.dataValues.choices.length
                            ? this.dataValues.choices.map((c) => c.id)
                            : [],
                    // GetScoresD fetches children of a specific f_id so use parent_id instead
                    // Concat rootFactor parent so that rootFactor score is always fetched in case it's nested
                    fp_ids:
                        this.dataValues.factors &&
                        this.dataValues.factors.length
                            ? this.dataValues.factors
                                  .map((f) =>
                                      f.parent_id ? f.parent_id : f.id
                                  )
                                  .concat(
                                      this.rootFactor &&
                                          this.rootFactor.parent_id
                                          ? [this.rootFactor.parent_id]
                                          : []
                                  )
                            : [],
                    root: true,
                });
            } catch (e) {
                flashModule.error({
                    message:
                        "Error fetching scores. Please refresh and try again.",
                    duration: 3000,
                });
            }
    }

    @Watch("visibleRootId")
    async onVisibleRootIdChange(): Promise<void> {
        if (this.columns == "factors") {
            this.pagination.columns.position = 0;
        }
        if (!this.factorTree[this.visibleRootId]) {
            this.factorsLoading = true;
            await modelModule.fetchChildFactors(this.visibleRootId);
            this.factorsLoading = false;
        }
        if (
            !modelModule.factorMap[this.visibleRootId] &&
            this.visibleRootId != -1
        ) {
            await modelModule.fetchFactor(this.visibleRootId);
        }
    }

    @Watch("activeFactors", { deep: true })
    async onActiveFactorsChange(): Promise<void> {
        if (this.data == "factors" && this.dataItem) {
            if (!this.activeFactors.factors.includes(this.dataItem.id)) {
                this.dataItem = null;
                this.chosenFactor = [];
            }
        }
    }

    /* Loads Viewpoints and Choices if factor selector is in top left */
    private async loadForFactor(factor: Factor): Promise<void> {
        if (factor) {
            this.loadVpMappings(
                this.screenViewpoints,
                factor.parent_id ? factor.parent_id : -1
            );

            if (!(factor.is_group || factor.is_table)) {
                this.loadChoiceValues(
                    this.screenChoices,
                    factor.parent_id ? factor.parent_id : -1
                );
            }
        }
    }

    @Watch("chosenFactor", { deep: true })
    onChosenFactorChange(): void {
        if (!this.selectedFactorLoading) {
            this.loadedDataItemId = null;
            if (this.chosenFactor.length) {
                this.dataItem = modelModule.factorMap[this.chosenFactor[0]];
                this.loadForFactor(this.dataItem);
            } else {
                this.dataItem = null;
            }
            this.factorSelectOpen = false;
        }
    }
    private goToNextRoute(): void {
        this.unSavedRoutingWarningOpen = false;
        this.valueErrs = 0;
        if (this.nextRoute) this.$router.push(this.nextRoute.path);
    }
}
