var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-rank__column",class:{
        'vp-rank__column--loading': _vm.loading,
        'vp-rank__column--drag': _vm.drag,
    },style:(_vm.full ? `flex-grow: 1;` : `width: ${_vm.columnWidth}px`)},[(_vm.factor)?_c('draggable',{staticClass:"vp-rank__column",attrs:{"list":_vm.dragList,"disabled":!_vm.dragEnabled,"handle":".vp-rank__item__drag","ghost-class":"vp-rank__item__ghost"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":function($event){return _vm.dragChange($event)}}},_vm._l((_vm.dragList),function(item,index){return _c('div',{key:index,staticClass:"vp-rank__item",class:{
                'vp-border-x': _vm.borderX && !_vm.full,
                'vp-border-y': _vm.borderY,
                'vp-rank__item--hover': _vm.hoverId == item.choice.id,
            },attrs:{"id":`vp-rank__item-${item.choice.id}`},on:{"mouseover":function($event){return _vm.$emit('hover-target', item.choice.id)},"click":function($event){return _vm.toggleChoice(item.choice)}}},[(_vm.loading)?[_c('div',{staticClass:"vp-rank__item--loading",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[_c('div',{staticClass:"vp-rank__item__skeleton"},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)])]:[(_vm.dragEnabled)?_c('div',{staticClass:"vp-rank__item__drag"},[_c('v-icon',[_vm._v("mdi-drag")])],1):_vm._e(),(_vm.factor)?_c('CompareCell',{key:item.choice.id,attrs:{"indexes":_vm.indexes,"column-item":_vm.viewpoint,"row-item":item.choice,"data-item":_vm.factor,"header":item.choice.name,"choice-editable":_vm.choiceEditable,"factor-display":_vm.factorDisplay,"scores-editable":_vm.scoresEditable,"score-rule":false,"custom-score":_vm.customScore,"weights-editable":false,"class-rating":_vm.classRating,"score-display":_vm.scoreDisplay,"selector-settings":_vm.selectorSettings,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"load-score":false},on:{"factor-select":function($event){return _vm.$emit('factor-select', $event)},"comment-select":function($event){return _vm.$emit('comment-select', $event)}}}):_c('div',{staticClass:"text-center",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[_vm._v(" "+_vm._s(item.choice ? item.choice.name : "")+" ")])]],2)}),0):_c('div',_vm._l((_vm.choices),function(choice,index){return _c('div',{key:index,staticClass:"vp-rank__item",class:{
                'vp-border-x': _vm.borderX && !_vm.full,
                'vp-border-y': _vm.borderY,
            },on:{"click":function($event){return _vm.toggleChoice(choice)}}},[_c('div',{staticClass:"text-center",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY}`},[(choice.description)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top' }),expression:"{ placement: 'top' }"}],attrs:{"content":choice.description}},[_vm._v(" "+_vm._s(choice ? choice.name : "")+" ")]):_c('span',[_vm._v(_vm._s(choice ? choice.name : ""))])])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }