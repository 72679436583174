import {
    Factor,
    ViewpointMapping,
    ViewpointMappingUpdateInputL,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Viewpoints from "@/store/modules/Viewpoints";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { getModule } from "vuex-module-decorators";
import { GraphQLResult } from "@aws-amplify/api-graphql";

const lambdaProperties = [
    "score_rule_id",
    "index_rule_id",
    "set_id",
    "weight",
    "use_m_score",
    "use_m_index",
];

export async function createViewpointMappingHelper(payload: {
    viewpoint_id: number;
    factor_id: number;
    set_id?: number;
    score_rule_id?: number;
    order_str?: string;
    json?: string;
    index_rule_id?: number;
    unit_id?: number;
    weight: number;
    use_m_score: boolean;
    use_m_index: boolean;
}): Promise<ViewpointMapping | null> {
    const newViewpointMapping = { ...payload };
    try {
        const res = await getModule(Viewpoints).createViewpointMapping(
            newViewpointMapping
        );
        getModule(FlashNotifications).success({
            message: "Saved",
            duration: 5000,
        });
        return res;
    } catch (e) {
        const err = e as GraphQLResult<any>;
        const message = err?.errors
            ? err.errors[0].message
            : "Something went wrong.";
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(e);
        getModule(FlashNotifications).error({
            message: message,
            duration: 3000,
        });
        return null;
    }
}

export async function updateViewpointMappingHelper(payload: {
    viewpoint_id: number;
    factor_id: number;
    set_id?: number;
    score_rule_id?: number;
    order_str?: string;
    json?: string;
    index_rule_id?: number;
    unit_id?: number;
    weight?: number;
    use_m_score?: boolean;
    use_m_index?: boolean;
}): Promise<ViewpointMapping[] | null> {
    const newViewpointMapping = { ...payload } as ViewpointMapping;
    const viewpoint_id = newViewpointMapping.viewpoint_id;
    const factor_id = newViewpointMapping.factor_id;
    if (
        newViewpointMapping.viewpoint_id == null ||
        newViewpointMapping.factor_id == null
    ) {
        return null;
    }
    const viewpointMappings = getModule(Viewpoints).viewpointMappings;
    const existingViewpointMapping = viewpointMappings[viewpoint_id][factor_id];
    let res = null;
    console.log(existingViewpointMapping, newViewpointMapping);
    // Code block used to check if lambda change - always use lambda now
    // let isLambdaChange = false;
    // //Check for change to scoring attributes - if yes then target lambda endpoint
    // for (const key of Object.keys(newViewpointMapping)) {
    //     if (lambdaProperties.includes(key)) {
    //         isLambdaChange =
    //             newViewpointMapping[key as keyof ViewpointMapping] !=
    //             existingViewpointMapping[key as keyof ViewpointMapping];
    //     }
    //     if (isLambdaChange) {
    //         break;
    //     }
    // }

    try {
        res = await getModule(Viewpoints).updateViewpointMappingL(
            [newViewpointMapping]
        );
        getModule(FlashNotifications).success({
            message: "Saved",
            duration: 5000,
        });
        return res;
    } catch (e) {
        const err = e as GraphQLResult<any>;
        const message = err?.errors
            ? err.errors[0].message
            : "Something went wrong.";
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(e);
        getModule(FlashNotifications).error({
            message: message,
            duration: 3000,
        });
        return null;
    }
}

export async function updateViewpointMappingWeight(payload: {
    viewpoint_id: number;
    factor_id: number;
    set_id?: number;
    score_rule_id?: number;
    order_str?: string;
    json?: string;
    index_rule_id?: number;
    unit_id?: number;
    weight?: number;
    use_m_score?: boolean;
    use_m_index?: boolean;
}): Promise<ViewpointMapping[] | null> {
    const newViewpointMapping = { ...payload } as ViewpointMapping;
    const viewpoint_id = newViewpointMapping.viewpoint_id;
    const factor_id = newViewpointMapping.factor_id;
    if (
        newViewpointMapping.viewpoint_id == null ||
        newViewpointMapping.factor_id == null
    ) {
        return null;
    }
    const viewpointMappings = getModule(Viewpoints).viewpointMappings;
    const existingViewpointMapping = viewpointMappings[viewpoint_id][factor_id];
    let res = null;
    console.log(existingViewpointMapping, newViewpointMapping);

    try {
        res = await getModule(Viewpoints).updateViewpointMappingWeight(
            [newViewpointMapping]
        );
        getModule(FlashNotifications).success({
            message: "Saved",
            duration: 5000,
        });
        return res;
    } catch (e) {
        const err = e as GraphQLResult<any>;
        const message = err?.errors
            ? err.errors[0].message
            : "Something went wrong.";
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(e);
        getModule(FlashNotifications).error({
            message: message,
            duration: 3000,
        });
        return null;
    }
}

export async function updateFactorPresentationHelper(
    updateValuePresentationInputList: ViewpointMappingUpdateInputL[]
): Promise<ViewpointMapping[] | null> {
    let res = null;

    try {
        res = await getModule(Viewpoints).batchUpdateValuePresentation(
            updateValuePresentationInputList
        );
        getModule(FlashNotifications).success({
            message: "Saved",
            duration: 5000,
        });
        return res;
    } catch (e) {
        const err = e as GraphQLResult<any>;
        const message = err?.errors
            ? err.errors[0].message
            : "Something went wrong.";
        console.log("%cError:", "color: red; font-weight: bold;");
        console.log(e);
        getModule(FlashNotifications).error({
            message: message,
            duration: 3000,
        });
        return null;
    }
}
