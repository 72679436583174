var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.depth == 1 && _vm.showToggleAll)?[_c('div',{staticClass:"vp-factor-toggle__factor"},[_c('v-btn',{staticClass:"btn-plain btn-plain btn-factor-toggle",attrs:{"plain":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.anyActive ? _vm.onIcon : _vm.offIcon)+" ")]),_c('span',{staticClass:"btn-factor-toggle__label"},[_vm._v("All "+_vm._s(_vm.anyActive))])],1)],1)]:_vm._e(),(_vm.currentTreeLevel && _vm.currentTreeLevel.length)?_c('div',{staticClass:"vp-factor-toggle__factor__group"},_vm._l((_vm.currentTreeLevel),function(factor,index){return _c('div',{key:index,staticClass:"vp-toggle-factor"},[(factor.is_table || factor.is_group)?[_c('FactorToggle',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                        placement: 'right',
                        delay: '300',
                        followCursor: 'initial',
                    }),expression:"{\n                        placement: 'right',\n                        delay: '300',\n                        followCursor: 'initial',\n                    }"}],attrs:{"content":factor.description,"factor":factor,"depth":_vm.depth,"active":_vm.syncedSelected.includes(factor.id),"locked":_vm.lockedFactors.includes(factor.id),"first":_vm.depth == 1 && index === 0,"last":index == _vm.currentTreeLevel.length - 1,"on-icon":_vm.onIcon,"off-icon":_vm.offIcon,"disabled":_vm.groupDisabled,"invert-color":_vm.invertColor,"show-root-btn":_vm.rootToggle,"root-id":_vm.syncedRootFactor,"check-active":_vm.checkActive,"root-active":_vm.syncedRootFactor && _vm.syncedRootFactor == factor.id},on:{"show":function($event){return _vm.showTooltip(factor)},"factor-toggle":function($event){return _vm.toggleFactor($event)},"root-toggle":function($event){return _vm.toggleRoot($event)}}},[_c('v-btn',{staticClass:"vp-factor-toggle-btn",class:{
                            'vp-factor-toggle-btn--active':
                                _vm.openedFactors[factor.id],
                        },attrs:{"icon":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openGroup(factor)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),(_vm.openedFactors[factor.id])?[_c('FactorToggleGroup',{attrs:{"selected":_vm.syncedSelected,"root-factor":_vm.syncedRootFactor,"depth":_vm.depth + 1,"parent-id":factor.id,"disabled":_vm.disabled,"hidden-factors":_vm.hiddenFactors,"all-available":_vm.allAvailable,"locked-factors":_vm.lockedFactors,"multi-select":_vm.multiSelect,"group-select":_vm.groupSelect,"on-icon":_vm.onIcon,"off-icon":_vm.offIcon,"invert-color":_vm.invertColor,"root-toggle":_vm.rootToggle,"parent-in-root":_vm.insideRoot,"parent-child-active":_vm.parentChildActive,"parent-selected":_vm.syncedSelected.includes(factor.id),"check-active":_vm.checkActive,"groups-only":_vm.groupsOnly,"last":index == _vm.currentTreeLevel.length - 1,"is-tab":_vm.isTab},on:{"update:selected":function($event){_vm.syncedSelected=$event},"update:rootFactor":function($event){_vm.syncedRootFactor=$event},"update:root-factor":function($event){_vm.syncedRootFactor=$event}}})]:_vm._e()]:[_c('FactorToggle',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                        placement: 'right',
                        delay: '300',
                        followCursor: 'initial',
                    }),expression:"{\n                        placement: 'right',\n                        delay: '300',\n                        followCursor: 'initial',\n                    }"}],attrs:{"content":factor.description,"factor":factor,"depth":_vm.depth,"active":_vm.syncedSelected.includes(factor.id),"locked":_vm.lockedFactors.includes(factor.id),"disabled":_vm.groupDisabled,"on-icon":_vm.onIcon,"off-icon":_vm.offIcon,"invert-color":_vm.invertColor,"check-active":_vm.checkActive,"root-id":_vm.syncedRootFactor,"first":_vm.depth == 1 && index === 0,"last":index == _vm.currentTreeLevel.length - 1},on:{"show":function($event){return _vm.showTooltip(factor)},"factor-toggle":function($event){return _vm.toggleFactor($event)}}})]],2)}),0):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }