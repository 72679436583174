
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Workspace, Decision, App } from "@/graphql/API";
import { mixins } from "vue-class-component";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import Apps from "@/store/modules/Apps";
import { Urls } from "@/Urls";
import VpDialog from "@/components/ui/VpDialog.vue";
import PermissionCheck from "@/components/mixins/PermissionCheck";

const workspacesModule = getModule(Workspaces);
const decisionsModule = getModule(Decisions);
const appsModule = getModule(Apps);

@Component({
    components: { VpDialog },
})
export default class Navigation extends mixins(PermissionCheck) {
    private readonly URLS = Urls;
    private active = true;
    private openModal = false;
    private v2DecisionId = "";

    get showLowerMenu(): boolean {
        if (this.selectedWorkspace) {
            return this.selectedDecision == null || this.adminAccess;
        } else {
            return false;
        }
    }

    get selectedWorkspace(): Workspace | null {
        return workspacesModule.selectedWorkspace;
    }

    get selectedDecision(): Decision | null {
        return decisionsModule.selectedDecision;
    }

    get appsGroup(): boolean | string {
        return this.$route.path.includes(this.URLS.APPSLISTEDITOR) ||
            this.$route.path.includes(this.URLS.TABEDITOR)
            ? true
            : false;
    }

    get factorsGroup(): boolean | string {
        return this.$route.path.includes("/model") ? true : false;
    }
    get choicesGroup(): boolean | string {
        return this.$route.path.includes("/choices") ? true : false;
    }
    get viewpointsGroup(): boolean | string {
        return this.$route.path.includes("/compare") ||
            this.$route.path.includes("/rank") ||
            this.$route.path.includes("/viewpoints")
            ? true
            : false;
    }

    get apps(): App[] {
        return appsModule.appsList.sort((a, b) => {
            return a.title.localeCompare(b.title);
        });
    }

    get selectedWorkspaceId(): number | null {
        return workspacesModule.selectedWorkspaceId;
    }

    private goToUrl(url: string) {
        if (this.$route.path !== url) this.$router.push({ path: url });
    }

    get appLink(): string {
        return `${window.location.origin}${Urls.APPS}`;
    }

    /* Labels */
    get choicePlural(): string {
        return decisionsModule.choiceLabelPlural;
    }

    private launchApp(app: App): void {
        if (app) {
            let link = this.$router.resolve({
                path: `${Urls.APPS}/${app.id}`,
            });
            window.open(link.href, "_blank");
        }
    }

    private async portV2ToV3(): Promise<void> {
        let task = await decisionsModule.portV2ToV3({
            v2_decision_id: this.v2DecisionId,
            workspace_id: this.selectedWorkspaceId,
        });

        if (task) {
            setTimeout(
                function (task) {
                    decisionsModule.getPdfResult(task.id);
                },
                10000,
                task
            );
        }
    }
}
