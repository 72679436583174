var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-compare__row__item vp-compare__row__item--main",class:{
        'vp-compare__row__item--border-x': _vm.borderX,
    },style:(`width: ${_vm.mainWidth}px;`)},[_c('div',{staticClass:"vp-compare__row__item--main__wrapper"},[(_vm.depth > 0)?_c('div',{staticClass:"vp-compare__row__item--main__offset"},_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"vp-compare__row__item__offset__block",class:{
                    'vp-compare__row__item__offset__block--line--last':
                        _vm.last,
                    'vp-compare__row__item__offset__block--line':
                        index == _vm.depth,
                    'vp-compare__row__item__offset__block--hide': _vm.lines.includes(index) || _vm.hideLines,
                }})}),0):_vm._e(),_c('div',{staticClass:"vp-compare__row__item--main__content",class:`vp-padding-y-${_vm.paddingY} ${_vm.paddingClass} ${_vm.borderClass}`},[_c('div',{staticClass:"vp-compare__row__item--main__content__slots"},[_c('div',{staticClass:"vp-compare__row__item__label"},[_vm._t("default"),(_vm.showBtns)?_c('div',{staticClass:"vp-compare__row__item--main__content__btns",class:{
                            'vp-compare__row__item--main__content__btns--offset':
                                _vm.group,
                        }},[_vm._t("buttons")],2):_vm._e()],2),(_vm.showContent && _vm.$slots.content)?_c('div',{class:`vp-padding-top-${_vm.paddingX}`},[_vm._t("content")],2):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }