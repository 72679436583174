
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getS3FileUrl } from "@/helpers/MediaUploadHelper";
import FilePreview from "@/components/common/media/FilePreview.vue";

@Component({
    components: {
        FilePreview,
    },
})
export default class MediaValueItem extends Vue {
    @Prop({ type: String, default: "image" })
    fileType!: string;

    /* Name of file */
    @Prop({ type: String, default: "" })
    srcFile!: string;

    /* Path to file within Viewwpoint Folder system */
    @Prop({ type: String, default: "" })
    path!: string;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: 0, type: Number })
    index!: number;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    /* Preview File is path to file that has been uploaded to choice */
    private previewFile = "";
    private loading = true;
    private deletePath: null | string = null;

    get deleteInProgress(): boolean {
        if (this.deletePath && this.path) {
            return this.deletePath.localeCompare(this.path) == 0;
        } else {
            return false;
        }
    }

    get loadingIcon(): boolean {
        return this.loading || this.deleteInProgress;
    }

    private deleteItem(): void {
        this.deletePath = this.path;
        this.$emit("delete-item", this.path);
    }

    mounted(): void {
        this.onPathChange(this.path);
    }

    @Watch("path")
    async onPathChange(val: string): Promise<void> {
        this.loading = true;
        this.previewFile = await getS3FileUrl({
            fileName: val,
            type: this.fileType,
        });
        this.loading = false;
    }
}
