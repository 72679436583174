
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import { Factor, Viewpoint, ViewpointMapping } from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Scoring from "@/store/modules/Scoring";
import VpDialog from "@/components/ui/VpDialog.vue";
import FactorSelector from "@/components/ui/FactorSelector.vue";
import FactorToggle from "@/components/model/FactorToggle.vue";
import ViewpointMappingFactor from "@/components/viewpoints/viewpointMapping/ViewpointMappingFactor.vue";
import PermissionCheck from "@/components/mixins/PermissionCheck";

const modelModule = getModule(Factors);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        VpDialog,
        FactorSelector,
        FactorToggle,
        ViewpointMappingFactor,
    },
})
export default class ScoreRuleDialog extends mixins(PermissionCheck) {
    @Prop()
    selectedFactor!: Factor;

    @Prop()
    selectedViewpoint!: Viewpoint;

    @Prop()
    showRuleDialog!: boolean;

    private modalOpen = false;
    private selectedCol: Factor | null = null;
    private dialogLoading = false;

    private newTab = "";

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private selected: any[] = [];

    private groupSelect = false;

    get selectedFactorId(): number | null {
        return this.selectedFactor ? this.selectedFactor.id : null;
    }

    get backButton(): boolean | string {
        if (this.selectedFactor && this.selectedFactor.is_table) {
            return this.selectedCol != null ? "Back to table" : false;
        } else {
            return false;
        }
    }

    get tableFactors(): Factor[] {
        if (
            this.selectedFactor &&
            this.selectedFactor.is_table &&
            modelModule.factorTree[this.selectedFactor.id]
        ) {
            return modelModule.factorTree[this.selectedFactor.id];
        } else {
            return [];
        }
    }

    get selectedFactors(): Factor[] {
        return this.selected.map((item) => modelModule.factorMap[item]);
    }

    get preventDialogClosing(): boolean {
        return scoringModule.unsavedRuleChanges;
    }

    private selectCol(factor: Factor) {
        this.selectedCol = factor;
    }

    private closeDialog(): void {
        this.newTab = "";
        this.modalOpen = false;
        this.$emit("dialog-closed");
    }

    private async loadTableColumns(): Promise<void> {
        if (this.selectedFactor) {
            if (!modelModule.factorTree[this.selectedFactor.id]) {
                this.dialogLoading = true;
                await modelModule.fetchChildFactors(this.selectedFactor.id);
                this.dialogLoading = false;
            }
        }
    }

    mounted(): void {
        this.onSelectedChange();
    }

    @Watch("selectedViewpoint", { immediate: true, deep: true })
    onViewpointChange(): void {
        this.onSelectedChange();
    }

    @Watch("selectedFactorId")
    onSelectedChange(): void {
        this.newTab = "";

        if (this.selectedFactorId && this.selectedViewpoint && this.showRuleDialog) {
            this.modalOpen = true;
            this.selected = [this.selectedFactorId];

            if (this.selectedFactor.is_table) {
                this.loadTableColumns();
            }
        } else {
            this.selected = [];
            this.modalOpen = false;
        }
    }
}
