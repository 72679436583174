var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-comment-list"},[(_vm.thread_id && _vm.comments)?_c('div',[(_vm.changeLoading)?[_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}})]:[_vm._l((_vm.comments.filter(
                    (com) => com.reply_to == null
                )),function(comment,index){return _c('div',{key:comment.id,staticClass:"vp-comment-outline"},[_c('CommentDisplay',{attrs:{"comment":comment,"activeCommentEdit":_vm.commentEditId},on:{"update-comment":_vm.updateComment,"comment-edit-active":_vm.updateEditCommentId,"reply-to":function($event){return _vm.activateReplyTo($event, index)}}}),(
                        (_vm.replyToActive && _vm.replyToActive == comment.id) ||
                        comment.num_replies > 0
                    )?_c('div',{staticClass:"pl-6 mb-6"},[_c('v-expansion-panels',{staticClass:"vp-accordion vp-accordion--plain",attrs:{"value":_vm.abc,"flat":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(comment.num_replies ? `Replies (${comment.num_replies})` : "Reply"))]),_c('v-expansion-panel-content',[_vm._l((_vm.comments.filter(
                                        (com) => com.reply_to === comment.id
                                    )),function(reply){return _c('div',{key:reply.id,staticClass:"mt-2"},[_c('CommentDisplay',{attrs:{"comment":reply,"replyTo":reply.reply_to,"activeCommentEdit":_vm.commentEditId},on:{"update-comment":_vm.updateComment,"comment-edit-active":_vm.updateEditCommentId}})],1)}),_c('RichTextEditor',{attrs:{"value":_vm.newCommentMessage,"showCommentBtn":true,"showCancelButton":_vm.replyToActive != null},on:{"save-comment":function($event){return _vm.saveComment($event, comment.id)},"cancel-comment":function($event){return _vm.cancelReplyTo()}}})],2)],1)],1)],1):_vm._e()],1)}),_c('div',{staticClass:"mt-2"},[_c('RichTextEditor',{attrs:{"value":_vm.newCommentMessage,"showCommentBtn":true},on:{"save-comment":_vm.saveComment}})],1)]],2):_vm._e(),(_vm.otherComments.length > 0)?[_c('div',{staticClass:"vp-other-comments"},[_c('div',{staticClass:"font-weight-bold mb-2"},[_vm._v("Other Comments")]),_vm._l((_vm.otherComments.filter(
                    (com) => com.reply_to == null
                )),function(comment,index){return _c('div',{key:comment.id,staticClass:"vp-comment-outline"},[_c('CommentDisplay',{attrs:{"comment":comment,"activeCommentEdit":_vm.commentEditId,"is-other-comment":true},on:{"update-comment":_vm.updateComment,"comment-edit-active":_vm.updateEditCommentId,"reply-to":function($event){return _vm.activateReplyTo($event, index)}}}),(
                        (_vm.replyToActive && _vm.replyToActive == comment.id) ||
                        comment.num_replies > 0
                    )?_c('div',{staticClass:"pl-6 mb-6"},[_c('v-expansion-panels',{staticClass:"vp-accordion vp-accordion--plain",attrs:{"value":_vm.abc,"flat":""}},[_c('v-expansion-panel',{staticStyle:{"background-color":"#d8d8d8"}},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(comment.num_replies ? `Replies (${comment.num_replies})` : "Reply"))]),_c('v-expansion-panel-content',_vm._l((_vm.otherComments.filter(
                                        (com) => com.reply_to === comment.id
                                    )),function(reply){return _c('div',{key:reply.id,staticClass:"mt-2"},[_c('CommentDisplay',{attrs:{"comment":reply,"replyTo":reply.reply_to,"activeCommentEdit":_vm.commentEditId,"is-other-comment":true},on:{"update-comment":_vm.updateComment,"comment-edit-active":_vm.updateEditCommentId}})],1)}),0)],1)],1)],1):_vm._e()],1)})],2)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }