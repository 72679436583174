
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import MediaFileItem from "@/components/choices/values/MediaFileItem.vue";
import FileUpload from "@/components/common/media/FileUpload.vue";
import { fileValue } from "@/graphql/API";

@Component({
    components: {
        MediaFileItem,
        FileUpload,
    },
})
export default class MediaValueDisplay extends Vue {
    /* Props copied from FileUpload component */
    @Prop({ type: String, default: "mdi-image-plus" })
    iconSave!: string;

    @Prop({ type: String, default: "image" })
    fileType!: string;

    @Prop({ type: String, default: "mdi-image-edit" })
    iconEdit!: string;

    @Prop({ type: String, default: "mdi-trash-can-outline" })
    iconDelete!: string;

    @Prop({ type: String, default: "" })
    path!: string;

    @Prop({ type: String, default: "140px" })
    maxWidth!: string;

    @Prop({ type: String, default: "140px" })
    maxHeight!: string;

    @Prop({ type: Boolean, default: false })
    autosave!: boolean;

    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: Boolean, default: false })
    showPreviewModal!: boolean;

    @Prop({ type: Boolean, default: false })
    isFactorTable!: boolean;

    @Prop({ type: Boolean, default: false })
    scoringTab!: boolean;

    @Prop({ default: false, type: Boolean })
    isThumbnailDisplay!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: true, type: Boolean })
    condensed!: boolean;

    @Prop({ default: () => null, type: Object })
    valueJson!: any;

    @Prop({ type: Boolean, default: false })
    deleting!: boolean;

    @Prop({ type: String, default: "" })
    folderKey!: string;

    private currIndex = 0;

    get sliderPresentation(): boolean {
        if (this.listLength > 1) {
            return ["image", "video"].includes(this.fileType);
        } else {
            return false;
        }
    }

    get prevDisabled(): boolean {
        return this.currIndex == 0;
    }

    get nextDisabled(): boolean {
        return this.currIndex == this.listLength - 1;
    }

    get valueList(): fileValue[] {
        if (this.value) {
            try {
                return JSON.parse(this.value);
            } catch {
                return [];
            }
        } else {
            return [];
        }
    }

    get listLength(): number {
        return this.valueList.length;
    }

    private async deleteFile(path: string): Promise<void> {
        if (path) {
            this.$emit(
                "files-change",
                this.valueList.filter((item) => item.fileName != path)
            );
        }
        console.log(`Delete: ${path}`);
    }

    private async addFile(fileData: {
        fileName: string;
        srcFile: string;
    }): Promise<void> {
        if (fileData) {
            this.$emit("files-change", [fileData, ...this.valueList]);
            this.currIndex = 0;
        }
    }

    @Watch("listLength")
    onListLengthChange(val: number): void {
        if (this.currIndex > val - 1) {
            this.currIndex = val - 1;
        }
    }
}
