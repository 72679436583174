
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Viewpoint,
    ViewpointMapping,
    ScoreClass,
    Rule,
    ClassSet,
    CategoryClass,
    RuleRange,
    ViewpointMappingUpdateInputL,
} from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Viewpoints from "@/store/modules/Viewpoints";
import Factors from "@/store/modules/Factors";
import Scoring from "@/store/modules/Scoring";
import Decisions from "@/store/modules/Decisions";
import FlashNotifications from "@/store/modules/FlashNotifications";
import {
    updateViewpointMappingHelper,
    createViewpointMappingHelper,
    updateFactorPresentationHelper,
} from "@/helpers/ViewpointHelper";
import { saveRangeRules, saveMatchRules } from "@/helpers/ScoreRuleHelper";
import NumberRule from "@/components/viewpoints/viewpointMapping/scoreRule/NumberRule.vue";
import DateRule from "@/components/viewpoints/viewpointMapping/scoreRule/DateRule.vue";
import OptionRule from "@/components/viewpoints/viewpointMapping/scoreRule/OptionRule.vue";
import AggregateRule from "@/components/viewpoints/viewpointMapping/scoreRule/AggregateRule.vue";
import ScoreClassCreator from "@/components/scoring/scoreClass/ScoreClassCreator.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import RuleMinMax from "@/components/viewpoints/viewpointMapping/scoreRule/RuleMinMax.vue";
import Workspaces from "@/store/modules/Workspaces";

const viewpointsModule = getModule(Viewpoints);
const modelModule = getModule(Factors);
const decisionsModule = getModule(Decisions);
const scoringModule = getModule(Scoring);
const flashModule = getModule(FlashNotifications);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        NumberRule,
        DateRule,
        OptionRule,
        AggregateRule,
        ScoreClassCreator,
        VpDialog,
        RuleMinMax,
    },
})
export default class ViewpointMappingFactor extends Vue {
    @Prop()
    factor!: Factor;

    @Prop({ default: null, type: Number })
    viewpointId!: number;

    @Prop()
    viewpoint!: Viewpoint;

    @Prop()
    factors!: Factor[];

    @Prop({ default: false, type: Boolean })
    dialogLoading!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    private selectedScoreClass: ScoreClass | null = null;
    private editModal = false;
    private showManualScore = false;
    private saving = false;

    private manualToggleLoading = false;
    private syncedFactors: number[] = [];

    private scoreRule: Rule | null = null;
    private classRule: Rule | null = null;
    private cluster: ClassSet | null = null;

    private scoreRuleLoading = false;
    private classRuleLoading = false;
    private clusterLoading = false;
    private aggregateRule: string | undefined = undefined;
    private agFunctionChanged = false;
    private presentation = [
        {
            name: "Percentage",
            value: "percent",
        },
        {
            name: "Ratio",
            value: "ratio",
        },
        {
            name: "Floating Point",
            value: "float",
        },
        {
            name: "Integer",
            value: "int",
        },
    ];

    private internalMin = 0;
    private internalMax = 1;

    private selectedPresentation: string | null = "percent";

    get rulesLoading(): boolean {
        return (
            this.scoreRuleLoading ||
            this.classRuleLoading ||
            this.clusterLoading
        );
    }

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get factorId(): number {
        return this.factor ? this.factor.id : -1;
    }

    get factorsSynced(): boolean {
        if (this.selectedFactors.length > 1) {
            return (
                JSON.stringify(this.selectedFactors) ==
                JSON.stringify(this.syncedFactors)
            );
        } else {
            return true;
        }
    }

    get selectedFactors(): number[] {
        if (this.factors) {
            return this.factors.map((factor) => factor.id);
        } else {
            return [];
        }
    }

    get classSets(): { [id: number]: ClassSet } {
        return scoringModule.classClusters;
    }

    get rules(): { [id: number]: Rule } {
        return scoringModule.scoreRules;
    }

    get isDefaultScoreRule(): boolean {
        if (this.scoreRule && this.factor && this.factor.score_rule_id) {
            return this.scoreRule.id == this.factor.score_rule_id;
        } else {
            return false;
        }
    }

    get isDefaultClassRule(): boolean {
        if (this.classRule && this.factor && this.factor.index_rule_id) {
            return this.classRule.id == this.factor.index_rule_id;
        } else {
            return false;
        }
    }

    get isDefaultCluster(): boolean {
        if (this.cluster && this.factor && this.factor.set_id) {
            return this.cluster.id == this.factor.set_id;
        } else {
            return false;
        }
    }

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get currentViewpointMapping(): ViewpointMapping | null {
        if (
            this.viewpointId in this.viewpointMappings &&
            this.factor.id in this.viewpointMappings[this.viewpointId]
        ) {
            return this.viewpointMappings[this.viewpointId][this.factor.id];
        } else {
            return null;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get viewpointMappingOptions(): any {
        if (this.currentViewpointMapping && this.currentViewpointMapping.json) {
            return JSON.parse(this.currentViewpointMapping.json);
        } else {
            return {};
        }
    }

    get viewpointMappingPres(): string {
        if (
            this.viewpointMappingOptions &&
            this.viewpointMappingOptions.presentation
        ) {
            return this.viewpointMappingOptions.presentation;
        } else {
            return "percent";
        }
    }

    get scoreRuleId(): number | null {
        if (
            this.currentViewpointMapping &&
            this.currentViewpointMapping.score_rule_id != undefined
        ) {
            return this.currentViewpointMapping.score_rule_id;
        } else {
            return null;
        }
    }

    get classRuleId(): number | null {
        if (
            this.currentViewpointMapping &&
            this.currentViewpointMapping.index_rule_id != undefined
        ) {
            return this.currentViewpointMapping.index_rule_id;
        } else {
            return null;
        }
    }

    get clusterId(): number | null {
        if (
            this.currentViewpointMapping &&
            this.currentViewpointMapping.set_id != undefined
        ) {
            return this.currentViewpointMapping.set_id;
        } else {
            return null;
        }
    }

    get manualLock(): boolean {
        if (this.factor) {
            return !(
                modelModule.autoFactors.includes(this.factor.value_type) ||
                this.factor.is_group ||
                this.factor.is_table
            );
        }
        return true;
    }

    get manualScore(): boolean {
        if (
            this.currentViewpointMapping &&
            this.currentViewpointMapping.use_m_score != undefined
        ) {
            return this.currentViewpointMapping.use_m_score;
        }
        return true;
    }

    get showSelectors(): boolean {
        if (this.factor) {
            return !this.factor.is_column && !this.manualLock;
        }
        return false;
    }

    get factorType(): string {
        if (this.factor) {
            return this.factor.value_type;
        }
        return "";
    }

    get isFactorGroupOrTable(): boolean {
        if (this.factor) {
            return this.factor.is_group || this.factor.is_table;
        }
        return false;
    }

    get isAggregateRuleChanged(): boolean {
        if (this.isFactorGroupOrTable && this.scoreRule) {
            if (
                this.scoreRule.aggregate_function !=
                this.rules[this.scoreRule.id].aggregate_function
            ) {
                return true;
            }
        }
        return false;
    }

    get isMinMaxChanged(): boolean {
        if (this.isFactorGroupOrTable && this.scoreRule) {
            if (
                this.internalMin != this.scoreRule.min ||
                this.internalMax != this.scoreRule.max
            ) {
                return true;
            }
        }
        return false;
    }

    private async getVpMapOptions(vpMap: ViewpointMapping): Promise<any> {
        if (vpMap && vpMap.json) {
            return JSON.parse(vpMap.json);
        } else {
            return {};
        }
    }

    private async updateFactorPresentation(
        val: string,
        factor: Factor
    ): Promise<void> {
        if (
            factor &&
            this.viewpoint &&
            this.viewpointMappings[this.viewpointId]
        ) {
            const cloneViewpointMapping = {
                ...this.viewpointMappings[this.viewpointId][factor.id],
            };
            const options = await this.getVpMapOptions(cloneViewpointMapping);

            updateViewpointMappingHelper({
                ...cloneViewpointMapping,
                json: JSON.stringify({
                    ...options,
                    presentation: val,
                }),
            });
        }
    }

    private async batchUpdateFactorPresentation(): Promise<void> {
        if (
            this.factors.length &&
            this.viewpoint &&
            this.viewpointMappings[this.viewpointId]
        ) {
            let updateValuePresentationInputList: ViewpointMappingUpdateInputL[] =
                [];
            await Promise.all(
                this.factors.map(async (factor) => {
                    const cloneViewpointMapping = {
                        ...this.viewpointMappings[this.viewpointId][factor.id],
                    };
                    const options = await this.getVpMapOptions(
                        cloneViewpointMapping
                    );

                    let updateValuePresentationInput: ViewpointMappingUpdateInputL =
                        {
                            viewpoint_id: this.viewpointId,
                            factor_id: factor.id,
                            json: JSON.stringify({
                                ...options,
                                presentation: this.selectedPresentation,
                            }),
                        };
                    updateValuePresentationInputList.push(
                        updateValuePresentationInput
                    );
                })
            );
            if (updateValuePresentationInputList.length) {
                updateFactorPresentationHelper(
                    updateValuePresentationInputList
                );
            }
        }
    }

    private async updatePresentation(val: string): Promise<void> {
        this.$emit("load-start");
        this.saving = true;
        if (this.factors && this.factors.length) {
            await Promise.all(
                this.factors.map(async (factor) => {
                    await this.updateFactorPresentation(val, factor);
                })
            );
        }
        this.saving = false;
        this.$emit("load-end");
    }

    private async toggleClass(id: number): Promise<void> {
        if (id && scoringModule.scoreClasses[id]) {
            this.selectedScoreClass = scoringModule.scoreClasses[id];
            this.editModal = true;
        } else if (id == -1) {
            this.editModal = true;
        }
    }

    private async updateManualScore(
        val: boolean,
        factor: Factor
    ): Promise<void> {
        if (
            factor &&
            this.viewpoint &&
            this.viewpointMappings[this.viewpointId]
        ) {
            if (this.viewpointMappings[this.viewpointId][factor.id]) {
                const cloneViewpointMapping = {
                    ...this.viewpointMappings[this.viewpointId][factor.id],
                };
                cloneViewpointMapping.use_m_score = val;

                updateViewpointMappingHelper({
                    ...cloneViewpointMapping,
                });
            } else {
                //TODO: CHeck if this can be deleted - don't create viewpointmappings anymore
                // createViewpointMappingHelper({
                //     viewpoint_id: this.viewpointId,
                //     factor_id: factor.id,
                //     use_m_score: val,
                //     use_m_index: false,
                // });
            }
        }
    }

    private async changeManualScore(val: boolean): Promise<void> {
        this.$emit("load-start");
        this.saving = true;
        this.manualToggleLoading = true;

        this.showManualScore = val;

        if (this.factors && this.factors.length) {
            await Promise.all(
                this.factors.map(async (factor) => {
                    if (
                        modelModule.autoFactors.includes(factor.value_type) ||
                        factor.is_group ||
                        factor.is_table
                    ) {
                        await this.updateManualScore(val, factor);
                    }
                })
            );
        }
        this.manualToggleLoading = false;
        this.saving = false;
        this.$emit("load-end");
    }

    // /* Saving Functions */
    private async saveRangeRule(
        rule: {
            ranges: {
                at_least: number;
                score: number;
                class_id: number;
                id?: number;
                class_range_id?: number;
                score_range_id: number;
            }[];
            min: number;
            minClass: number | null;
            deletedRanges: RuleRange[];
        },
        manual: boolean
    ): Promise<void> {
        this.$emit("load-start");
        this.saving = true;
        // if (this.isAggregateRuleChanged || this.isMinMaxChanged) {
        //     if (this.scoreRule)
        //         this.saveAggregateRule(this.scoreRule?.aggregate_function);
        // }
        if (this.factors && this.factors.length > 0) {
            this.batchUpdateFactorPresentation();
        }
        await this.saveRangeRuleFactor(rule, manual, this.factors);
        this.syncedFactors = [...this.selectedFactors];
        this.saving = false;
        this.$emit("load-end");
    }

    private async saveRangeRuleFactorMultiple(
        rule: {
            ranges: {
                at_least: number;
                score: number;
                class_id: number;
                id?: number;
                class_range_id?: number;
                score_range_id: number;
            }[];
            min: number;
        },
        manual: boolean,
        factors: Factor[]
    ): Promise<void> {
        console.log(rule, manual, factors);
    }

    private async saveRangeRuleFactor(
        rule: {
            ranges: {
                at_least: number;
                score: number;
                class_id: number;
                id?: number;
                class_range_id?: number;
                score_range_id: number;
            }[];
            min: number;
            minClass: number | null;
            deletedRanges: RuleRange[];
        },
        manual: boolean,
        factors: Factor[]
    ): Promise<void> {
        console.log(rule, manual, factors);
        this.viewpointId;
        if (this.decisionId && this.workspaceId)
            saveRangeRules({
                rule: rule,
                decision_id: this.decisionId,
                manual: manual,
                factors: factors,
                viewpoint_id: this.viewpointId,
                agFunction: this.aggregateRule,
                workspace_id: this.workspaceId,
                factorType: this.factor.value_type,
            });
    }

    private async saveMatchRule(
        rule: {
            classList: { [index: number]: number };
            items: CategoryClass[];
            matches: {
                match: string;
                score: number;
                index: number;
                label: string;
            }[];
        },
        manual: boolean
    ): Promise<void> {
        this.$emit("load-start");
        this.saving = true;
        console.log(rule);
        if (this.factors && this.factors.length > 0) {
            this.batchUpdateFactorPresentation();
        }
        await this.saveMatchRuleFactor(rule, manual);
        this.syncedFactors = [...this.selectedFactors];
        this.saving = false;
        this.$emit("load-end");
    }

    private async saveMatchRuleFactor(
        rule: {
            classList: { [index: number]: number };
            items: CategoryClass[];
            matches: {
                match: string;
                score: number;
                index: number;
                label: string;
            }[];
        },
        manual: boolean
    ): Promise<void> {
        // const viewpointMapping = await this.getFactorViewpointMapping(factor);

        /* Checks if factor is using default rules/clusters */
        let currentScoreRule = this.scoreRule;
        // viewpointMapping &&
        // viewpointMapping.score_rule_id &&
        // viewpointMapping.score_rule_id != factor.score_rule_id
        //     ? scoringModule.scoreRules[viewpointMapping.score_rule_id]
        //     : null;

        let currentClassRule = this.classRule;
        // viewpointMapping &&
        // viewpointMapping.index_rule_id &&
        // viewpointMapping.index_rule_id != factor.index_rule_id
        //     ? scoringModule.scoreRules[viewpointMapping.index_rule_id]
        //     : null;

        let currentCluster = this.cluster;
        // viewpointMapping &&
        // viewpointMapping.set_id &&
        // (factor.set_id ? viewpointMapping.set_id != factor.set_id : true)
        //     ? scoringModule.classClusters[viewpointMapping.set_id]
        //     : null;

        if (this.decisionId && this.workspaceId) {
            await saveMatchRules({
                rule: rule,
                decision_id: this.decisionId,
                manual: manual,
                factors: this.factors,
                viewpoint_id: this.viewpointId,
                workspace_id: this.workspaceId,
                factorType: this.factor.value_type,
            });
        }
    }

    private async saveAggregateRule(agOption: string): Promise<void> {
        this.aggregateRule = agOption;
        this.agFunctionChanged = true;
        // this.$emit("load-start");
        // this.saving = true;
        // if (this.factors && this.factors.length) {
        //     await Promise.all(
        //         this.factors.map(async (factor) => {
        //             if (factor.is_group || factor.is_table) {
        //                 if (agOption === "none") {
        //                     console.log("none");
        //                     await this.applyNoneGroupFunction(factor);
        //                 } else {
        //                     await this.saveFactorAggregate(factor, agOption);
        //                 }
        //             }
        //         })
        //     );
        // }
        // this.saving = false;
        // this.$emit("load-end");
    }

    private async saveFactorAggregate(
        factor: Factor,
        agOption: string
    ): Promise<void> {
        let viewpointMapping = {
            ...(await this.getFactorViewpointMapping(factor)),
        };

        /* Checks if factor is using default rule */
        let currentScoreRule: Rule | null =
            viewpointMapping &&
            viewpointMapping.score_rule_id &&
            viewpointMapping.score_rule_id != factor.score_rule_id
                ? scoringModule.scoreRules[viewpointMapping.score_rule_id]
                : null;

        if (currentScoreRule && this.scoreRule && !this.scoreRule.is_match) {
            try {
                await scoringModule.updateScoreRuleAggregate({
                    name: currentScoreRule.name,
                    score_rule_id: currentScoreRule.id,
                    aggregate_function: agOption,
                    null_value_score: currentScoreRule.null_value_score,
                    min: this.internalMin,
                    max: this.internalMax,
                });
                flashModule.success({
                    message: "Aggregate Updated",
                    duration: 3000,
                });
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                getModule(FlashNotifications).error({
                    message: message,
                    duration: 3000,
                });
            }
        } else {
            currentScoreRule = await scoringModule.createScoreRuleAggregate({
                name: "New Score Rule (Aggregate)",
                null_value_score: 0,
                aggregate_function: agOption,
                decision_id: this.decisionId,
                min: this.internalMin,
                max: this.internalMax,
            });
            if (currentScoreRule) {
                viewpointMapping.score_rule_id = currentScoreRule.id;
                await updateViewpointMappingHelper({
                    ...viewpointMapping,
                });
            }
        }
    }

    private async applyNoneGroupFunction(factor: Factor): Promise<void> {
        let viewpointMapping = {
            ...(await this.getFactorViewpointMapping(factor)),
        };
        await updateViewpointMappingHelper({
            viewpoint_id: viewpointMapping.viewpoint_id,
            factor_id: viewpointMapping.factor_id,
            score_rule_id: scoringModule.globalNoneSummaryRuleId,
        });
    }

    private saveMinValue(val: number): void {
        this.internalMin = val;
    }

    private saveMaxValue(val: number): void {
        this.internalMax = val;
    }

    private async getFactorViewpointMapping(
        factor: Factor
    ): Promise<ViewpointMapping> {
        return this.viewpointMappings[this.viewpointId][factor.id];
    }

    mounted(): void {
        this.onManualScoreChange();
        this.onFactorChange();
        this.onScoreRuleId();
        this.onClasseRuleId();
        this.onClusterId();
        this.onPresentationChange();
    }

    @Watch("factorId")
    onFactorChange(): void {
        this.syncedFactors = [];
    }

    @Watch("manualScore")
    onManualScoreChange(): void {
        this.showManualScore = this.manualScore;
    }

    @Watch("rulesLoading")
    onRulesLoading(): void {
        if (this.rulesLoading) {
            this.$emit("load-start");
        } else {
            this.$emit("load-end");
        }
    }

    @Watch("scoreRuleId")
    async onScoreRuleId(): Promise<void> {
        this.scoreRuleLoading = true;
        if (this.scoreRuleId) {
            if (!this.rules[this.scoreRuleId]) {
                this.scoreRule = await scoringModule.getRule(this.scoreRuleId);
            } else {
                this.scoreRule = this.rules[this.scoreRuleId];
            }
        } else {
            this.scoreRule = null;
        }
        if (this.scoreRule && this.scoreRule.max) {
            this.internalMax = this.scoreRule.max;
        } else {
            this.internalMax = 1;
        }

        if (this.scoreRule && this.scoreRule.min) {
            this.internalMin = this.scoreRule.min;
        } else {
            this.internalMin = 0;
        }
        if (this.scoreRule && this.scoreRule.is_aggregate)
            this.aggregateRule = this.scoreRule?.aggregate_function;
        this.scoreRuleLoading = false;
    }

    @Watch("classRuleId")
    async onClasseRuleId(): Promise<void> {
        this.classRuleLoading = true;
        if (this.classRuleId) {
            if (!this.rules[this.classRuleId]) {
                this.classRule = await scoringModule.getRule(this.classRuleId);
            } else {
                this.classRule = this.rules[this.classRuleId];
            }
        } else {
            this.classRule = null;
        }
        this.classRuleLoading = false;
    }

    @Watch("clusterId")
    async onClusterId(): Promise<void> {
        this.clusterLoading = true;
        if (this.clusterId) {
            if (!this.classSets[this.clusterId]) {
                this.cluster = await scoringModule.fetchClassCluster(
                    this.clusterId
                );
            } else {
                this.cluster = this.classSets[this.clusterId];
            }
        } else {
            this.cluster = null;
        }
        this.clusterLoading = false;
    }

    @Watch("viewpointMappingPres")
    onPresentationChange(): void {
        this.selectedPresentation = this.viewpointMappingPres;
    }
}
