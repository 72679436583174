import { Component, Vue, Prop, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Choices from "@/store/modules/Choices";
import Viewpoints from "@/store/modules/Viewpoints";
import { mixins } from "vue-class-component";
import TabManagment from "@/components/mixins/TabManagment";
import { Viewpoint, Choice } from "@/graphql/API";

const choiceModule = getModule(Choices);
const viewpointsModule = getModule(Viewpoints);

@Component
export default class CompareItems extends mixins(TabManagment) {
    /* 
        Properties for ordering 
        - sortDef controls if list is default sort
            or if it is the order the user can rearrange
        - sortDesc controls if default sort should be 
            alphabetical or reverse alphabetical    
    */
    choiceSortDef = true;
    choiceSortDesc = true;
    viewpointSortDef = true;
    viewpointSortDesc = true;

    /* These are ids of all choices/viewpoints. Can be reordered by user */
    viewpointIdList: number[] = [];
    choiceIdList: number[] = [];

    /* List of choices/viewpoints that are active in tab */
    selectedViewpoints: number[] = [];
    selectedChoices: number[] = [];

    /* Start of User created Objects */
    get userCreatedChoicesToggled(): boolean {
        if (this.embed && this.appOptions.userChoice) {
            return true;
        } else {
            return (
                this.embed &&
                (!this.locks.newChoices || this.userChoicesEnabled)
            );
        }
    }

    get userCreatedViewpointsToggled(): boolean {
        if (this.embed && this.appOptions.userViewpoint) {
            return true;
        } else {
            return (
                this.embed &&
                (!this.locks.newViewpoints || this.userViewpointsEnabled)
            );
        }
    }

    get autoToggleChoices(): boolean {
        if (this.embed && this.appOptions.userChoice) {
            return true;
        } else {
            return this.selectUserChoices;
        }
    }

    get autoToggleViewpoints(): boolean {
        if (this.embed && this.appOptions.userViewpoint) {
            return true;
        } else {
            return this.selectUserViewpoints;
        }
    }

    /* Objects created by current User */
    get userChoices(): Choice[] {
        return choiceModule.userChoices;
    }

    get userViewpoints(): Viewpoint[] {
        return viewpointsModule.userViewpoints;
    }

    /* User created object IDS */
    get userChoiceIds(): number[] {
        if (this.userCreatedChoicesToggled) {
            return this.userChoices.map((choice) => choice.id);
        } else {
            return [];
        }
    }

    get userViewpointIds(): number[] {
        if (this.userCreatedViewpointsToggled) {
            return this.userViewpoints.map((viewpoint) => viewpoint.id);
        } else {
            return [];
        }
    }

    /* User Choice Ids that aren't included tab selected ones */
    get uniqueUserChoiceIds(): number[] {
        return this.userChoiceIds.filter(
            (id) => !this.tabChoicesIds.includes(id)
        );
    }

    get uniqueUserViewpointIds(): number[] {
        return this.userViewpointIds.filter(
            (id) => !this.tabViewpointsIds.includes(id)
        );
    }

    /* End of User created Objects */

    /* List of Objects that are available to user to toggle on/off */
    get availableChoiceIds(): number[] {
        if (this.embed && this.hideChoices) {
            return [];
        } else if (!this.embed || this.showAllChoicesTab) {
            return this.frameworkChoices;
        } else if (this.userCreatedChoicesToggled) {
            return [...this.tabChoicesIds, ...this.uniqueUserChoiceIds];
        } else {
            return this.tabChoicesIds;
        }
    }

    get availableViewpointIds(): number[] {
        if (this.embed && this.hideViewpoints) {
            return [];
        } else if (!this.embed || this.showAllViewpointsTab) {
            return this.frameworkViewpoints;
        } else if (this.userCreatedViewpointsToggled) {
            return [...this.tabViewpointsIds, ...this.uniqueUserViewpointIds];
        } else {
            return this.tabViewpointsIds;
        }
    }

    get autoSelectedChoiceIds(): number[] {
        if (this.embed && this.hideChoices) {
            return [];
        } else if (this.isMainTool && this.querySettings) {
            return this.tabChoicesIds;
        } else if (this.isMainTool || this.showAllChoicesTab) {
            return this.frameworkChoices;
        } else if (
            this.embed &&
            this.userCreatedChoicesToggled &&
            this.autoToggleChoices
        ) {
            return [...this.tabChoicesIds, ...this.uniqueUserChoiceIds];
        } else {
            return this.tabChoicesIds;
        }
    }

    get autoSelectedViewpointIds(): number[] {
        if (this.embed && this.hideViewpoints) {
            return [];
        } else if (this.isMainTool && this.querySettings) {
            return this.tabViewpointsIds;
        } else if (this.isMainTool || this.showAllViewpointsTab) {
            return this.frameworkViewpoints;
        } else if (
            this.embed &&
            this.userCreatedViewpointsToggled &&
            this.autoToggleViewpoints
        ) {
            return [...this.tabViewpointsIds, ...this.uniqueUserViewpointIds];
        } else {
            return this.tabViewpointsIds;
        }
    }

    /* Returns array of choices to be displayed in the tab
    - Filtered by which ids are selected */
    get choices(): Choice[] {
        return this.choiceIdList
            .filter(
                (id) =>
                    this.selectedChoices.includes(id) &&
                    choiceModule.choiceList[id]
            )
            .map((id) => choiceModule.choiceList[id]);
    }

    /* Returns array of Viewpoints to be displayed in the tab
        - Filtered by which ids are selected */
    get viewpoints(): Viewpoint[] {
        return this.viewpointIdList
            .filter(
                (id) =>
                    this.selectedViewpoints.includes(id) &&
                    viewpointsModule.viewpointList[id]
            )
            .map((id) => viewpointsModule.viewpointList[id]);
    }

    /* Returns true if all available choices are selected */
    get allChoicesActive(): boolean {
        return (
            this.availableChoiceIds.filter(
                (id) => !this.selectedChoices.includes(id)
            ).length == 0
        );
    }

    /* Returns true if all available Viewpoints are selected */
    get allViewpointsActive(): boolean {
        return (
            this.availableViewpointIds.filter(
                (id) => !this.selectedViewpoints.includes(id)
            ).length == 0
        );
    }

    /* 
        Returns array of ids for viewpoints sorted in alphabetical order
        - viewpointSortDesc controls if alphabetical or reverse alphabetical
    */
    get sortedViewpointIds(): number[] {
        if (this.availableViewpointIds.length) {
            return this.availableViewpointIds.sort((a, b) => {
                if (
                    a &&
                    b &&
                    viewpointsModule.viewpointList[a] &&
                    viewpointsModule.viewpointList[b]
                ) {
                    return (
                        viewpointsModule.viewpointList[a].name.localeCompare(
                            viewpointsModule.viewpointList[b].name,
                            undefined,
                            {
                                numeric: true,
                                sensitivity: "base",
                            }
                        ) * (this.viewpointSortDesc ? 1 : -1)
                    );
                } else {
                    return 1;
                }
            });
        } else {
            return [];
        }
    }

    /* 
        Returns array of ids for choices sorted in alphabetical order
        - choiceSortDesc controls if alphabetical or reverse alphabetical
    */
    get sortedChoiceIds(): number[] {
        if (this.availableChoiceIds.length) {
            return this.availableChoiceIds.sort((a, b) => {
                if (
                    a &&
                    b &&
                    choiceModule.choiceList[a] &&
                    choiceModule.choiceList[b]
                ) {
                    return (
                        choiceModule.choiceList[a].name.localeCompare(
                            choiceModule.choiceList[b].name,
                            undefined,
                            {
                                numeric: true,
                                sensitivity: "base",
                            }
                        ) * (this.choiceSortDesc ? 1 : -1)
                    );
                } else {
                    return 1;
                }
            });
        } else {
            return [];
        }
    }

    /* Detects Change */

    get choicesChanged(): boolean {
        if (this.tabChoicesIds.length == this.selectedChoices.length) {
            if (
                this.selectedChoices.every((val: number) =>
                    this.tabChoicesIds.includes(val)
                ) &&
                this.tabChoicesIds.every((val: number) =>
                    this.selectedChoices.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    get viewpointsChanged(): boolean {
        if (this.tabViewpointsIds.length == this.selectedViewpoints.length) {
            if (
                this.selectedViewpoints.every((val: number) =>
                    this.tabViewpointsIds.includes(val)
                ) &&
                this.tabViewpointsIds.every((val: number) =>
                    this.selectedViewpoints.includes(val)
                )
            ) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    /*
        - When the sorted list of Viewpoints/Choices is changed they get added to the 
            list of available IDS. 
        - If default order is enabled then the customizable list is set to the
            same as the ordered list
        - Else the user is re-ordering the list so the new items are added to the
            end of the list
        - If an item is removed then it gets removed from available items
    */
    @Watch("sortedViewpointIds", { immediate: true, deep: true })
    onSortedViewpointIdChange(newVal: number[], oldVal: number[] = []): void {
        if (this.viewpointSortDef) {
            this.viewpointIdList = [...this.sortedViewpointIds];
        } else {
            this.addIdList(
                newVal.filter((x) => !oldVal.includes(x)),
                this.viewpointIdList
            );
            this.removeIdList(
                oldVal.filter((x) => !newVal.includes(x)),
                this.viewpointIdList
            );
        }
    }

    @Watch("sortedChoiceIds", { immediate: true, deep: true })
    onSortedChoiceIdChange(newVal: number[], oldVal: number[] = []): void {
        if (this.choiceSortDef) {
            this.choiceIdList = [...this.sortedChoiceIds];
        } else {
            this.addIdList(
                newVal.filter((x) => !oldVal.includes(x)),
                this.choiceIdList
            );
            this.removeIdList(
                oldVal.filter((x) => !newVal.includes(x)),
                this.choiceIdList
            );
        }
    }

    @Watch("autoSelectedChoiceIds", { immediate: true, deep: true })
    onAutoSelectedChoiceIdsChange(
        newVal: number[],
        oldVal: number[] = []
    ): void {
        this.addIdList(
            newVal.filter((x) => !oldVal.includes(x)),
            this.selectedChoices
        );
        this.removeIdList(
            oldVal.filter((x) => !newVal.includes(x)),
            this.selectedChoices
        );
    }

    @Watch("autoSelectedViewpointIds", { immediate: true, deep: true })
    onAutoSelectedViewpointIdsChange(
        newVal: number[],
        oldVal: number[] = []
    ): void {
        this.addIdList(
            newVal.filter((x) => !oldVal.includes(x)),
            this.selectedViewpoints
        );
        this.removeIdList(
            oldVal.filter((x) => !newVal.includes(x)),
            this.selectedViewpoints
        );
    }

    /* 
        - This boolean is toggled from the selection components in the sidebar 
        - If default order is renabled then list is set to sorted list
    */
    @Watch("viewpointSortDef")
    onViewpointSortAlphaChange(val: boolean): void {
        if (val) {
            this.viewpointIdList = [...this.sortedViewpointIds];
        }
    }

    @Watch("choiceSortDef")
    onChoiceSortAlphaChange(val: boolean): void {
        if (val) {
            this.choiceIdList = [...this.sortedChoiceIds];
        }
    }
}
