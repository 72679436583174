import { render, staticRenderFns } from "./AccessDenied.vue?vue&type=template&id=d6f2832a&"
import script from "./AccessDenied.vue?vue&type=script&lang=ts&"
export * from "./AccessDenied.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports