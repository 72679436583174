
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FactorCreator from "@/components/model/FactorCreator.vue";

@Component({
    components: {
        FactorCreator,
    },
})
export default class DateTimePicker extends Vue {
    @Prop({ default: 0, type: Number })
    value!: number;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: "", type: String })
    label!: string;

    @Prop()
    choiceId!: number;

    private open = false;
    private internalValue = 0;
    private loading = false;
    private backupValue = 0;

    private fieldRules = [
        (v: string) => {
            if (!this.validDate(v)) {
                return "invalid date";
            } else {
                return true;
            }
        },
    ];

    get formattedDate(): string {
        if (this.internalValue == null || this.internalValue <= 100) {
            return "";
        } else {
            return this.parseDateIntoFormat(
                this.unixToDate(this.internalValue)
            );
        }
    }

    get formattedDatePicker(): string {
        if (this.internalValue == null || this.internalValue <= 100) {
            return this.parseDateIntoFormat(this.unixToDate(0));
        } else {
            return this.parseDateIntoFormat(
                this.unixToDate(this.internalValue)
            );
        }
    }

    private validDate(val: string): boolean {
        if (Date.parse(val)) {
            return true;
        } else {
            return false;
        }
    }

    private async manualDate(val: string): Promise<void> {
        if (this.validDate(val)) {
            const newVal = this.dateToUnix(val);
            this.internalValue = newVal;
            this.save();
        }
    }

    private deleteValue() {
        this.$emit("delete-value");
    }

    private changeDate(val: string) {
        this.internalValue = this.dateToUnix(val);
    }

    private save() {
        this.$emit("save", this.internalValue);
        this.open = false;
    }

    private cancel() {
        this.internalValue = this.backupValue;
        this.$emit("cancel");
        this.open = false;
    }

    private dateToUnix(date: string | null): number {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const timestamp = require("unix-timestamp");

        if (date != null) {
            return timestamp.fromDate(date);
        } else {
            return timestamp.now();
        }
    }

    private unixToDate(unixVal: number) {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const timestamp = require("unix-timestamp");
        if (unixVal <= 100) {
            return timestamp.toDate(timestamp.now());
        }
        return timestamp.toDate(unixVal);
    }

    // Can replace this with more in depth formatting options
    private parseDateIntoFormat(date: Date) {
        return `${date.getFullYear()}-${("0" + (date.getUTCMonth() + 1)).slice(
            -2
        )}-${("0" + date.getUTCDate()).slice(-2)}`;
    }

    mounted(): void {
        this.onChangeValue();
    }

    @Watch("value", { deep: true })
    private onChangeValue() {
        this.backupValue = this.value;
        if (this.value != null && this.value != undefined) {
            this.internalValue = this.value;
        } else {
            this.internalValue = 0;
        }
    }
}
