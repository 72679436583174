
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {},
})
export default class RecursiveModelFactorLabel extends Vue {
    @Prop()
    description!: string;
}
