import {
    Rule,
    ClassSet,
    CategoryClass,
    MatchItem,
    Class,
    Changes,
    Factor,
} from "@/graphql/API";
import FlashNotifications from "@/store/modules/FlashNotifications";
import Scoring from "@/store/modules/Scoring";
import { getModule } from "vuex-module-decorators";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Decisions from "@/store/modules/Decisions";
import { RuleRange } from "@/graphql/API";
import {
    AggregateRuleCreateInputL,
    ClassMappingIdInput,
    ClassMappingInput,
    ClassSetCreateInput,
    ComboClassMappingInput,
    ComboRuleMatchCreateInputL,
    ComboRuleRangeCreateInputL,
    CreateClassMapping,
    CreateRangeRuleL,
    CreateRuleMatchL,
    MatchRuleCreateInputL,
    RangeRuleCreateInputL,
    RangeRuleUpdateInputL,
    RuleMatchCreateInputL,
    RuleMatchIDInputL,
    RuleRangeCreateInputL,
    RuleRangeUpdateInputL,
    UpdateRangeRuleL,
    UpdateRuleRangeL,
} from "@/graphql/custom";
import Workspaces from "@/store/modules/Workspaces";

export async function saveRangeRules(payload: {
    rule: {
        ranges: {
            at_least: number;
            score: number;
            class_id: number;
            id?: number;
            class_range_id?: number;
            score_range_id?: number;
            displayname?: string;
        }[];
        min: number;
        minClass: number | null;
        deletedRanges: RuleRange[];
    };
    agFunction?: string;
    manual: boolean;
    factors: Factor[];
    decision_id: number;
    workspace_id: number;
    viewpoint_id: number;
    factorType: string;
}): Promise<any> {
    let aggRulePayload: AggregateRuleCreateInputL | undefined = undefined;
    aggRulePayload = {
        name: `${payload.factors[0].name} Agg Rule`,
        null_value_score: 0,
        decision_id: payload.decision_id,
        aggregate_function: payload.agFunction ? payload.agFunction : "wsum",
    };

    //create score rule payload, class rule payload for range rules
    const scoreRulePayload: RangeRuleCreateInputL = {
        name: `${payload.factors[0].name} Score Rule`,
        min_range_score: payload.rule.min,
        null_value_score: 0,
        decision_id: payload.decision_id,
        interpolate_range: !payload.manual,
        is_index_rule: false,
        index_uses_score: false,
    };

    const classRulePayload: RangeRuleCreateInputL = {
        name: `${payload.factors[0].name} Class Rule`,
        min_range_score: payload.rule.min,
        null_value_score: 0,
        decision_id: payload.decision_id,
        interpolate_range: false,
        is_index_rule: true,
        index_uses_score: payload.manual,
    };

    const manualRulePayload: RangeRuleCreateInputL = {
        name: `${payload.factors[0].name} Score Rule`,
        min_range_score: payload.rule.min,
        null_value_score: 0,
        decision_id: payload.decision_id,
        interpolate_range: false,
        is_index_rule: false,
        index_uses_score: false,
    };

    const manualClassRulePayload: RangeRuleCreateInputL = {
        name: `${payload.factors[0].name} Class Rule`,
        min_range_score: payload.rule.min,
        null_value_score: 0,
        decision_id: payload.decision_id,
        interpolate_range: false,
        is_index_rule: true,
        index_uses_score: true,
    };

    //Create score rule, class rule payload in case of other type factors
    const otherTypeScoreRulePayload: MatchRuleCreateInputL = {
        name: `${payload.factors[0].name} Score Rule`,
        no_match_score: 0,
        null_value_score: 0,
        decision_id: payload.decision_id,
        is_index_rule: false,
    };

    //Set no_match_score and null_value_score to be 1 so that unscored factors get class index 1 not 0
    const otherTypeClassRulePayload: MatchRuleCreateInputL = {
        name: `${payload.factors[0].name} Class Rule`,
        no_match_score: 1,
        null_value_score: 1,
        decision_id: payload.decision_id,
        is_index_rule: true,
    };

    const setPayload: ClassSetCreateInput = {
        name: `${payload.factors[0].name} Class Set`,
        workspace_id: payload.workspace_id,
    };

    const ranges = payload.rule.ranges;
    const scoreRanges: ComboRuleRangeCreateInputL[] = [];
    const classRanges: ComboRuleRangeCreateInputL[] = [];
    const otherTypeRanges: ComboRuleMatchCreateInputL[] = [];
    const otherTypeClassRanges: ComboRuleMatchCreateInputL[] = [];

    const maps: ComboClassMappingInput[] = [];
    if (payload.rule.minClass) {
        const classMap: ComboClassMappingInput = {
            class_id: payload.rule.minClass,
            index: 0,
            displayname: "",
            fail: false,
            json: "",
        };
        maps.push(classMap);
    }
    const scoreModule = getModule(Scoring);
    ranges.map((range, index) => {
        const scoreRuleRange: ComboRuleRangeCreateInputL = {
            score: range.score,
            at_least: range.at_least,
            label: "New Range",
            description: "",
            bigger_than: !payload.manual && index == ranges.length - 1,
        };
        scoreRanges.push(scoreRuleRange);
        const classRuleRange: ComboRuleRangeCreateInputL = {
            score: index + 1,
            at_least: payload.manual ? range.score : range.at_least,
            label: "New Range",
            description: "",
            bigger_than: !payload.manual && index == ranges.length - 1,
        };
        classRanges.push(classRuleRange);
        const otherTypeRuleMatch: ComboRuleMatchCreateInputL = {
            score: range.score,
            match: `empty-${range.at_least}`,
            label: "New Range",
            description: "",
        };
        otherTypeRanges.push(otherTypeRuleMatch);
        const otherTypeClassRange: ComboRuleMatchCreateInputL = {
            score: index + 1,
            match: `empty-${range.at_least}`,
            label: "New Range",
            description: "",
        };
        otherTypeClassRanges.push(otherTypeClassRange);

        if (range.class_id) {
            const classMap: ComboClassMappingInput = {
                class_id: range.class_id,
                index: index + 1,
                displayname: range.displayname ? range.displayname : "",
                fail: false,
                json: "",
            };
            maps.push(classMap);
        }
    });

    const factorGroupIds: number[] = [];
    const f_ids: number[] = [];
    const otherTypeFactorIds: number[] = [];
    const manualFactorIds: number[] = [];

    payload.factors.forEach((factor) => {
        if (factor.is_group || factor.is_table) {
            factorGroupIds.push(factor.id);
        } else {
            switch (factor.value_type.toLowerCase()) {
                case "category":
                    otherTypeFactorIds.push(factor.id);
                    break;
                case "number":
                    f_ids.push(factor.id);
                    break;
                case "date_time":
                    f_ids.push(factor.id);
                    break;
                default:
                    manualFactorIds.push(factor.id);
            }
        }
    });

    const groupVpmIds = factorGroupIds.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    const factorVpmIds = f_ids.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    const otherTypeFactorVpmIds = otherTypeFactorIds.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    const manualVpmIds = manualFactorIds.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    if (f_ids.length > 0) {
        await Promise.all([
            scoreModule.cRangeRuleL({
                vpm_ids: factorVpmIds,
                f_ids: [],
                rule: scoreRulePayload,
                ranges: scoreRanges,
            }),
            scoreModule.cRangeRuleL({
                vpm_ids: factorVpmIds,
                f_ids: [],
                rule: classRulePayload,
                ranges: classRanges,
            }),
        ]);
    }

    if (manualVpmIds.length > 0) {
        await Promise.all([
            scoreModule.cRangeRuleL({
                vpm_ids: manualVpmIds,
                f_ids: [],
                rule: manualRulePayload,
                ranges: scoreRanges,
            }),
            scoreModule.cRangeRuleL({
                vpm_ids: manualVpmIds,
                f_ids: [],
                rule: manualClassRulePayload,
                ranges: classRanges,
            }),
        ]);
    }

    if (factorGroupIds.length > 0 && aggRulePayload) {
        if (payload.agFunction === "none") {
            await Promise.all([
                await scoreModule.cRangeRuleL({
                    vpm_ids: groupVpmIds,
                    f_ids: [],
                    rule: scoreRulePayload,
                    ranges: [],
                }),
                scoreModule.cRangeRuleL({
                    vpm_ids: groupVpmIds,
                    f_ids: [],
                    rule: {
                        ...classRulePayload,
                        index_uses_score: true,
                    },
                    ranges: classRanges,
                }),
            ]);
        } else {
            await Promise.all([
                scoreModule.cAggRuleL({
                    vpm_ids: groupVpmIds,
                    f_ids: [],
                    rule: aggRulePayload,
                }),
                scoreModule.cRangeRuleL({
                    vpm_ids: groupVpmIds,
                    f_ids: [],
                    rule: {
                        ...classRulePayload,
                        index_uses_score: true,
                    },
                    ranges: classRanges,
                }),
            ]);
        }
    }

    if (otherTypeFactorIds.length > 0) {
        await Promise.all([
            scoreModule.cMatchRuleL({
                vpm_ids: otherTypeFactorVpmIds,
                f_ids: [],
                rule: otherTypeScoreRulePayload,
                matches: otherTypeRanges,
            }),
            scoreModule.cMatchRuleL({
                vpm_ids: otherTypeFactorVpmIds,
                f_ids: [],
                rule: otherTypeClassRulePayload,
                matches: otherTypeClassRanges,
            }),
        ]);
    }

    if (maps.length > 0) {
        const newClassSet = await scoreModule.cSetL({
            vpm_ids: factorVpmIds
                .concat(groupVpmIds)
                .concat(otherTypeFactorVpmIds)
                .concat(manualVpmIds),
            f_ids: [],
            set: setPayload,
            maps: maps,
        });
    }
    //Loop through rule ranges
}

export async function saveMatchRules(payload: {
    rule: {
        classList: { [index: number]: number };
        items: CategoryClass[];
        matches: {
            match: string;
            score: number;
            index: number;
            label: string;
        }[];
    };
    manual: boolean;
    factors: Factor[];
    decision_id: number;
    workspace_id: number;
    viewpoint_id: number;
    factorType: string;
}): Promise<any> {
    console.log(payload);
    //create score rule payload, class rule payload
    const scoreRulePayload: MatchRuleCreateInputL = {
        name: `${payload.factors[0].name} Score Rule`,
        no_match_score: 0,
        null_value_score: 0,
        decision_id: payload.decision_id,
        is_index_rule: false,
    };

    //Set no_match_score and null_value_score to be 1 so that unscored factors get class index 1 not 0
    const classRulePayload: MatchRuleCreateInputL = {
        name: `${payload.factors[0].name} Class Rule`,
        no_match_score: 1,
        null_value_score: 1,
        decision_id: payload.decision_id,
        is_index_rule: true,
    };

    const classRuleOtherTypePayload: RangeRuleCreateInputL = {
        name: `${payload.factors[0].name} Class Rule`,
        null_value_score: 0,
        decision_id: payload.decision_id,
        is_index_rule: true,
        index_uses_score: true,
        interpolate_range: false,
        min_range_score: 0,
    };

    const scoreRuleOtherTypePayload: RangeRuleCreateInputL = {
        name: `${payload.factors[0].name} Score Rule`,
        null_value_score: 0,
        decision_id: payload.decision_id,
        is_index_rule: false,
        index_uses_score: payload.manual,
        interpolate_range: !payload.manual,
        min_range_score: 0,
    };

    const setPayload: ClassSetCreateInput = {
        name: `${payload.factors[0].name} Class Set`,
        workspace_id: payload.workspace_id,
    };

    const matches = payload.rule.matches;
    const scoreMatches: ComboRuleMatchCreateInputL[] = [];
    const classMatches: ComboRuleMatchCreateInputL[] = [];
    const otherTypeClassRanges: ComboRuleRangeCreateInputL[] = [];
    const otherTypeScoreRanges: ComboRuleRangeCreateInputL[] = [];
    const maps: ComboClassMappingInput[] = [];
    // Might be able to delete - come back to this
    // if (payload.rule.minClass) {
    //     const classMap: ComboClassMappingInput = {
    //         class_id: payload.rule.minClass,
    //         index: 0,
    //         displayname: "",
    //         fail: false,
    //         json: "",
    //     };
    //     maps.push(classMap);
    // }
    const scoreModule = getModule(Scoring);
    matches.map((match, index) => {
        const scoreRuleMatch: ComboRuleMatchCreateInputL = {
            score: match.score,
            match: match.match,
            label: `Match - ${match.match}`,
            description: "",
        };
        scoreMatches.push(scoreRuleMatch);
        const classRuleMatch: ComboRuleMatchCreateInputL = {
            score: match.index,
            match: match.match,
            label: `Match - ${match.match}`,
            description: "",
        };
        classMatches.push(classRuleMatch);
        const otherTypeClassRange: ComboRuleRangeCreateInputL = {
            score: match.index,
            at_least: match.score,
            bigger_than: false,
            label: "",
            description: "",
        };
        otherTypeClassRanges.push(otherTypeClassRange);
        const otherTypeScoreRange: ComboRuleRangeCreateInputL = {
            score: match.score,
            at_least: match.score,
            bigger_than: false,
            label: "",
            description: "",
        };
        otherTypeScoreRanges.push(otherTypeScoreRange);
    });

    payload.rule.items.map((matchMap) => {
        const classMap: ComboClassMappingInput = {
            class_id: matchMap.class.id,
            index: matchMap.index,
            displayname: matchMap.displayname ? matchMap.displayname : "",
            fail: false,
            json: "",
        };
        maps.push(classMap);
    });
    const factorGroupIds: number[] = [];
    const f_ids: number[] = [];
    const otherTypeFactorIds: number[] = [];
    payload.factors.forEach((factor) => {
        if (factor.is_group || factor.is_table) {
            factorGroupIds.push(factor.id);
        }

        if (factor.value_type != payload.factorType) {
            otherTypeFactorIds.push(factor.id);
        } else {
            f_ids.push(factor.id);
        }
    });

    const groupVpmIds = factorGroupIds.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    const factorVpmIds = f_ids.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    const otherTypeVpmIds = otherTypeFactorIds.map((factor) => {
        return { viewpoint_id: payload.viewpoint_id, factor_id: factor };
    });

    if (f_ids.length > 0) {
        const newScoreRule = await scoreModule.cMatchRuleL({
            vpm_ids: factorVpmIds,
            f_ids: [],
            rule: scoreRulePayload,
            matches: scoreMatches,
        });
        if (!payload.manual) {
            //If manual is false then create a match rule to go from value to class index
            const newClassRule = await scoreModule.cMatchRuleL({
                vpm_ids: factorVpmIds,
                f_ids: [],
                rule: classRulePayload,
                matches: classMatches,
            });
        } else {
            //If manual score then create a numeric rule to go from manual score to class
            const newClassRule = await scoreModule.cRangeRuleL({
                vpm_ids: factorVpmIds,
                f_ids: [],
                rule: classRuleOtherTypePayload,
                ranges: otherTypeClassRanges,
            });
        }
    }

    if (otherTypeFactorIds.length > 0) {
        const newScoreRule = await scoreModule.cRangeRuleL({
            vpm_ids: otherTypeVpmIds,
            f_ids: [],
            rule: scoreRuleOtherTypePayload,
            ranges: otherTypeScoreRanges,
        });
        const newClassRule = await scoreModule.cRangeRuleL({
            vpm_ids: otherTypeVpmIds,
            f_ids: [],
            rule: classRuleOtherTypePayload,
            ranges: otherTypeClassRanges,
        });
    }

    if (maps.length > 0) {
        const newClassSet = await scoreModule.cSetL({
            vpm_ids: factorVpmIds.concat(groupVpmIds).concat(otherTypeVpmIds),
            f_ids: [],
            set: setPayload,
            maps: maps,
        });
    }

    // if (factorGroupIds.length > 0 && aggRulePayload) {
    //     const newAggRule = await scoreModule.cAggRuleL({
    //         vpm_ids: groupVpmIds,
    //         f_ids: [],
    //         rule: aggRulePayload,
    //     });
    //     classRulePayload.index_uses_score = true;
    //     const newClassRule = await scoreModule.cRangeRuleL({
    //         vpm_ids: groupVpmIds,
    //         f_ids: [],
    //         rule: classRulePayload,
    //         ranges: classRanges,
    //     });
    // }

    console.log({
        vpm_ids: factorVpmIds,
        set: setPayload,
        maps: maps,
    });
}
