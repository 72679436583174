
import { Component, Watch, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import {
    Score,
    ScoreClass,
    ScoreOptions,
    ViewpointMapping,
    ScoreClassSettings,
    Rule,
    EnhancedScore,
} from "@/graphql/API";
import Scoring from "@/store/modules/Scoring";
import ColourPalette from "@/components/mixins/ColourPalette";
import FactorClassScore from "@/components/scoring/FactorClassScore.vue";
import FactorClassDisplay from "@/components/scoring/FactorClassDisplay.vue";
import { createUpdateScoreHelper, getScore } from "@/helpers/ScoreHelper";
import IconDisplay from "@/components/values/IconDisplay.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        FactorClassScore,
        FactorClassDisplay,
        IconDisplay,
    },
})
export default class FactorScore extends mixins(ColourPalette) {
    private loading = false;
    private manualScoring = true;
    private inputScore = false;
    private internalScore = 0;
    private changeScore: number | null = null;
    private scoreLoading = false;
    private ruleLoading = false;

    @Prop({ default: -1, type: Number })
    choiceId!: number;

    @Prop({ default: -1, type: Number })
    viewpointId!: number;

    @Prop({ default: -1, type: Number })
    factorId!: number;

    @Prop()
    rowId!: string;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop({ default: false, type: Boolean })
    scoreRuleBtn!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    viewpointMap!: ViewpointMapping | null;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop()
    header!: string;

    @Prop({ default: true, type: Boolean })
    loadScore!: boolean;

    private autoScores = ["category", "number", "date_time", "group"];

    get score(): any {
        if (scoringModule.keyedScores[this.scoreIndex]) {
            return scoringModule.keyedScores[this.scoreIndex];
        }
        return null;
    }

    get scoreIndex(): string {
        if (this.rowId) {
            return `${this.viewpointId}-${this.choiceId}-${this.factorId}-${this.rowId}`;
        } else {
            return `${this.viewpointId}-${this.choiceId}-${this.factorId}`;
        }
    }

    get scoreValue(): number {
        if (this.score) {
            if (this.viewpointMap && this.viewpointMap.use_m_score) {
                return this.score.m_score ? this.score.m_score : 0;
            } else {
                return this.score.c_score ? this.score.c_score : 0;
            }
        }
        return 0;
    }

    get classes(): { [id: number]: ScoreClass } {
        return scoringModule.scoreClasses;
    }

    get scoreClass(): ScoreClass | null {
        if (this.score && this.score.class_id) {
            return this.classes[this.score.class_id];
        } else if (this.score && this.score.class) {
            return this.score.class;
        } else {
            return null;
        }
        /*
        if (this.score && this.score.class_id) {
            return this.classes[this.score.class_id];
        } else {
            return null;
        }
        */
    }

    get viewpointMapOptions(): any {
        if (this.viewpointMap && this.viewpointMap.json) {
            return JSON.parse(this.viewpointMap.json);
        } else {
            return null;
        }
    }

    get presentation(): string {
        if (this.viewpointMapOptions && this.viewpointMapOptions.presentation) {
            return this.viewpointMapOptions.presentation;
        } else {
            return "percent";
        }
    }

    get manualScore(): boolean {
        if (this.viewpointMap && this.viewpointMap.use_m_score != undefined) {
            return this.viewpointMap.use_m_score;
        }
        return true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get scoreClassStyle(): any {
        if (this.scoreClass) {
            return JSON.parse(this.scoreClass.json);
        } else {
            return {};
        }
    }

    get range(): number[] {
        if (this.scoreRule) {
            if (this.scoreRule.is_range && this.scoreRule.ranges.length > 0) {
                return [
                    ...this.scoreRule.ranges.map((item) => item.score),
                    this.scoreRule.min_range_score,
                ].sort((a, b) => a - b);
            } else if (
                this.scoreRule.is_aggregate &&
                this.indexRule &&
                this.indexRule.ranges.length > 0
            ) {
                return [
                    ...this.indexRule.ranges.map((item) => item.at_least),
                    this.indexRule.min_range_score,
                ].sort((a, b) => a - b);
            } else if (
                this.scoreRule.is_match &&
                this.scoreRule.matches.length > 0
            ) {
                return [
                    ...this.scoreRule.matches.map((item) => item.score),
                    this.scoreRule.no_match_score,
                ].sort((a, b) => a - b);
            } else {
                return [0, 1];
            }
        } else {
            return [0, 1];
        }
    }

    get scoreRule(): Rule | null {
        if (this.scoreRuleId && scoringModule.scoreRules[this.scoreRuleId]) {
            return scoringModule.scoreRules[this.scoreRuleId];
        } else {
            return null;
        }
    }

    get indexRuleId(): number | null {
        if (this.viewpointMap && this.viewpointMap.index_rule_id) {
            return this.viewpointMap.index_rule_id;
        } else {
            return null;
        }
    }

    get scoreRuleId(): number | null {
        if (this.viewpointMap && this.viewpointMap.score_rule_id) {
            return this.viewpointMap.score_rule_id;
        } else {
            return null;
        }
    }

    get indexRule(): Rule | null {
        if (this.indexRuleId) {
            return scoringModule.scoreRules[this.indexRuleId];
        } else {
            return null;
        }
    }

    get displayScoreVal(): number {
        return this.manualScore ? this.internalScore : this.scoreValue;
    }

    get formatScore(): number {
        if (this.presentation == "percent") {
            return this.roundNumber(
                (this.displayScoreVal / this.maxScore) * 100,
                4
            );
        } else if (this.presentation == "ratio") {
            return this.roundNumber(this.displayScoreVal, 3);
        } else if (this.presentation == "int") {
            return Math.floor(this.displayScoreVal);
            // return this.roundNumber(this.displayScoreVal, 0);
        } else {
            return this.displayScoreVal;
        }
    }

    get displayScore(): string {
        if (this.presentation == "percent") {
            return `${this.formatScore}%`;
        } else if (this.presentation == "ratio") {
            return `${this.formatScore} / ${this.maxScore}`;
        } else if (this.presentation == "int") {
            return `${Math.floor(this.displayScoreVal)}`;
            //return `${this.roundNumber(this.displayScoreVal, 0)}`;
        } else {
            return `${this.displayScoreVal}`;
        }
    }

    get maxScore(): number {
        return 1;
    }

    /*
    get indexRule(): Rule | null {
        if (this.viewpointMap && this.viewpointMap.index_rule_id) {
            return scoringModule.scoreRules[this.viewpointMap.index_rule_id];
        }
        return null;
    }
    */

    get showClassSelector(): boolean {
        return (
            this.selectorSettings &&
            this.classRating &&
            (this.selectorSettings.label ||
                this.selectorSettings.description ||
                this.selectorSettings.icons ||
                this.selectorSettings.colour)
        );
    }

    /* Redesign */
    get showScoreRule(): boolean {
        return this.$slots.hasOwnProperty("scoreRule");
    }

    get showWeights(): boolean {
        return this.$slots.hasOwnProperty("weights");
    }

    get showComments(): boolean {
        return this.$slots.hasOwnProperty("comments");
    }

    get showIcons(): boolean {
        if (this.scoreDisplay && this.scoreDisplay.class_icons) {
            if (this.scoreClass && this.scoreClass.json) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    get scoreVisible(): boolean {
        if (this.scoreDisplay && this.scoreDisplay.score) {
            return true;
        } else {
            return false;
        }
    }

    get editableScore(): boolean {
        return this.manualScore && this.editable && this.customScore;
    }

    get classColour(): string | null {
        if (
            this.scoreDisplay &&
            this.scoreDisplay.class_colour &&
            this.scoreClassStyle &&
            this.scoreClassStyle.bg_colour &&
            this.palette[this.scoreClassStyle.bg_colour]
        ) {
            return this.palette[this.scoreClassStyle.bg_colour].hex;
        } else {
            return null;
        }
    }

    get invertBtns(): boolean {
        if (this.classColour) {
            return true;
        } else {
            return false;
        }
    }

    /* End of Redesign */

    private toggleFactor(): void {
        this.$emit("factor-toggle");
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }

    private async setClassScore(classItem: {
        index: number;
        score: number;
        class_id: number;
        name: string;
    }) {
        if (classItem && classItem.score != null) {
            this.internalScore = classItem.score;
            await createUpdateScoreHelper({
                viewpoint_id: this.viewpointId,
                choice_id: this.choiceId,
                factor_id: this.factorId,
                m_score: classItem.score,
                row_id: this.rowId ? this.rowId : "",
            });
        }
    }

    private async setTextScore(val: number): Promise<void> {
        let newVal =
            this.presentation == "percent"
                ? this.roundNumber((val / 100) * this.maxScore, 4)
                : val;

        this.changeScore = newVal;
        this.loading = true;
        this.inputScore = false;
        if (this.viewpointId && this.choiceId && this.factorId) {
            await createUpdateScoreHelper({
                viewpoint_id: this.viewpointId,
                choice_id: this.choiceId,
                factor_id: this.factorId,
                m_score: newVal,
                row_id: this.rowId ? this.rowId : "",
            });
            this.internalScore = newVal;
        }
        this.loading = false;
    }

    private async setScore(val: number): Promise<void> {
        this.changeScore = val;
        this.loading = true;
        this.inputScore = false;
        if (this.viewpointId && this.choiceId && this.factorId) {
            await createUpdateScoreHelper({
                viewpoint_id: this.viewpointId,
                choice_id: this.choiceId,
                factor_id: this.factorId,
                m_score: val,
                row_id: this.rowId ? this.rowId : "",
            });
        }
        this.loading = false;
    }

    mounted(): void {
        this.onScoreIndexChange();
        this.onScoreValueChange();
        /*
        this.onIndexRuleChange();
        this.onScoreRuleChange();
        */
    }

    @Watch("indexRuleId")
    async onIndexRuleChange(): Promise<void> {
        if (this.indexRuleId) {
            if (!scoringModule.scoreRules[this.indexRuleId]) {
                this.ruleLoading = true;
                await scoringModule.getRule(this.indexRuleId);
                this.ruleLoading = false;
            }
        }
    }

    @Watch("scoreRuleId")
    async onScoreRuleChange(): Promise<void> {
        if (this.scoreRuleId) {
            if (!scoringModule.scoreRules[this.scoreRuleId]) {
                await scoringModule.getRule(this.scoreRuleId);
            }
        }
    }

    @Watch("scoreValue")
    onScoreValueChange(): void {
        if (this.manualScore && !this.loading) {
            this.internalScore = this.roundNumber(this.scoreValue, 4);
        }
    }

    // return `${this.viewpointId}-${this.choiceId}-${this.factorId}-${this.rowId}`;
    @Watch("scoreIndex")
    async onScoreIndexChange(): Promise<void> {
        if (this.loadScore) {
            // if (!scoringModule.keyedScores[this.scoreIndex]) {
            //     this.scoreLoading = true;
            //     await getScore({
            //         viewpoint_id: this.viewpointId,
            //         choice_id: this.choiceId,
            //         factor_id: this.factorId,
            //     });
            //     this.scoreLoading = false;
            // }
        }
    }
}
