import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import { Factor, Rule, DefaultClassSets, Enum } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Scoring from "@/store/modules/Scoring";
import Factors from "@/store/modules/Factors";
import _ from "lodash";
import { getNextOrderStrs, createFactorL } from "@/helpers/FactorHelper";
import PermissionCheck from "@/components/mixins/PermissionCheck";

const modelModule = getModule(Factors);
const decisionsModule = getModule(Decisions);
const scoringModule = getModule(Scoring);

@Component
export default class FactorAiBase extends mixins(PermissionCheck) {
    topicArea = "";
    persona = "";
    creativity = 1;

    factorTree: { [id: number]: Factor[] } = {};
    factorMap: { [id: number]: Factor } = {};
    optionTree: { [factorId: number]: string[] } = {};

    selectedGroup: number[] = [];

    testOptions = [
        {
            cat_id: 0,
            task_id: "8036ad48-b066-4d74-8964-4c91e11cce1f",
            category_name: "School District",
            options:
                '["Low ranking", "Average ranking", "Above average ranking", "High ranking", "Exceptional ranking"]',
        },
        {
            cat_id: 1,
            task_id: "8036ad48-b066-4d74-8964-4c91e11cce1f",
            category_name: "Home Security",
            options:
                '["Assessment of Burglary Statistics/Risks", "Proactive Measure Implementation (e.g. Fences and Locks)", "Electronic Security System/CCTV Installation", "Appliances Used for Home Security Improvements (e.g. Lights and Alarm Systems)", "Regular Maintenance Checks for Home Security Systems"]',
        },
        {
            cat_id: 2,
            task_id: "8036ad48-b066-4d74-8964-4c91e11cce1f",
            category_name: "Child-Friendly Amenities",
            options:
                '["Outdoor playgrounds", "Indoor playgrounds", "Youth centers", "Safe streets and sidewalks", "Parks and green spaces"]',
        },
        {
            cat_id: 3,
            task_id: "8036ad48-b066-4d74-8964-4c91e11cce1f",
            category_name: "Home Condition",
            options: '["Poor", "Fair", "Good", "Very good", "Excellent"]',
        },
        {
            cat_id: 4,
            task_id: "8036ad48-b066-4d74-8964-4c91e11cce1f",
            category_name: "Home Safety",
            options:
                '["Fire Safety", "Electrical Safety", "Structural Safety", "Plumbing Safety", "Security and Accessibility"]',
        },
    ];

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get orgIdToDefaultRuleMap(): { [org_id: number]: Rule } {
        return scoringModule.defaultRules;
    }

    get defaultClassSets(): DefaultClassSets {
        const workspaceClassSetsList = scoringModule.classClusters;
        return _.chain(workspaceClassSetsList).groupBy("org_id").value();
    }

    get defaultFactorSetId(): number | undefined {
        if (this.defaultClassSets && this.defaultClassSets[-1]) {
            return this.defaultClassSets[-1][0].id;
        } else {
            return undefined;
        }
    }

    get modelTree(): { [id: number]: Factor[] } {
        return modelModule.factorTree;
    }

    get factorIds(): number[] {
        return Object.values(this.factorMap).map((factor) => factor.id);
    }

    get modelMap(): { [id: number]: Factor } {
        return modelModule.factorMap;
    }

    get rootId(): number | null {
        if (this.selectedGroup.length) {
            return this.selectedGroup[0];
        } else {
            return null;
        }
    }

    private async getOrderStrings(
        factors: Factor[],
        length: number,
        parentId: number
    ): Promise<string[]> {
        return getNextOrderStrs(this.modelTree[parentId], length);
    }

    async saveFactors(
        factors: Factor[],
        parentId: number,
        root = false
    ): Promise<void> {
        let orderStrs: string[] = [];
        if (root) {
            orderStrs = await this.getOrderStrings(
                factors,
                factors.length,
                parentId
            );
        }
        await Promise.all(
            factors.map(async (factor, index) => {
                let factorEnum: Enum[] | null = null;
                let enum_id: number | undefined = undefined;
                if (factor.value_type == "category") {
                    factorEnum = await modelModule.createEnums({
                        decision_id: factor.decision_id,
                        names: [factor.name],
                    });
                    if (factorEnum && factorEnum.length) {
                        enum_id = factorEnum[0].id;

                        if (
                            this.optionTree[factor.id] &&
                            this.optionTree[factor.id].length
                        ) {
                            await modelModule.createEnumValues({
                                enum_id: enum_id,
                                values: this.optionTree[factor.id],
                            });
                        }
                    }
                }
                const newFactors = await createFactorL({
                    names: [factor.name],
                    order_str: root ? [orderStrs[index]] : [factor.order_str],
                    valueType: factor.value_type,
                    parentId: parentId,
                    isColumn: false,
                    format: factor.format,
                    useManualScore: factor.m_score,
                    useManualIndex: factor.m_index,
                    score_rule_id: factor.score_rule_id,
                    index_rule_id: factor.index_rule_id,
                    set_id: factor.set_id,
                    enum_ids: enum_id ? [enum_id] : undefined,
                    weight: factor.weight,
                    description: factor.description,
                    unit_id: factor.unit_id,
                    decision_id: factor.decision_id,
                });

                if (newFactors && newFactors.length) {
                    const newFactor = newFactors[newFactors.length - 1];

                    if (factor.value_type == "group") {
                        if (
                            this.factorTree[factor.id] &&
                            this.factorTree[factor.id].length
                        ) {
                            await this.saveFactors(
                                this.factorTree[factor.id],
                                newFactor.id,
                                false
                            );
                        }
                    }
                }
            })
        );
    }
}
