
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, Factor, Viewpoint, ViewpointMapping } from "@/graphql/API";
import WeightCounter from "@/components/weights/WeightCounter.vue";
import Viewpoints from "@/store/modules/Viewpoints";
import {
    createViewpointMappingHelper,
    updateViewpointMappingHelper,
    updateViewpointMappingWeight,
} from "@/helpers/ViewpointHelper";
import CircleIcon from "@/components/ui/CircleIcon.vue";
import WeightsCounterPopup from "@/components/weights/WeightsCounterPopup.vue";

const viewpointsModule = getModule(Viewpoints);
@Component({
    components: {
        WeightCounter,
        CircleIcon,
        WeightsCounterPopup,
    },
})
export default class WeightCounterMenu extends Vue {
    @Prop()
    indexes!: { [id: string]: string };

    @Prop()
    columnItem!: Factor | Choice | Viewpoint;

    @Prop()
    rowItem!: Factor | Choice | Viewpoint;

    @Prop()
    dataItem!: Factor | Choice | Viewpoint | null;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: true, type: Boolean })
    icon!: boolean;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop({ default: false, type: Boolean })
    invert!: boolean;

    @Prop({ default: false, type: Boolean })
    mainColumn!: boolean;

    private show = false;
    private changeLoading = false;

    get idTag(): string | null {
        if (this.displayItems["factors"] && this.displayItems["viewpoints"]) {
            return `${this.displayItems["viewpoints"].id}-${this.displayItems["factors"].id}`;
        } else {
            return null;
        }
    }

    get weight(): number {
        if (
            this.viewpointMapping != null &&
            this.viewpointMapping.weight != null &&
            this.viewpointMapping.weight >= 0
        ) {
            return this.viewpointMapping.weight;
        } else {
            return 1;
        }
    }

    get viewpointMappings(): {
        [viewpointId: number]: {
            [factorId: number]: ViewpointMapping;
        };
    } {
        return viewpointsModule.viewpointMappings;
    }

    get gridItems(): { [id: string]: Factor | Choice | Viewpoint | null } {
        return {
            columns: this.columnItem,
            rows: this.rowItem,
            data: this.dataItem,
        };
    }

    get displayItems(): { [id: string]: Factor | Choice | Viewpoint | null } {
        return Object.keys(this.indexes).reduce((a, v) => {
            return {
                ...a,
                [this.indexes[v]]: this.gridItems[v],
            };
        }, {});
    }

    get viewpointMapping(): ViewpointMapping | null {
        if (this.displayItems["factors"] && this.displayItems["viewpoints"]) {
            if (
                this.viewpointMappings &&
                this.viewpointMappings[this.displayItems["viewpoints"].id]
            ) {
                return this.viewpointMappings[
                    this.displayItems["viewpoints"].id
                ][this.displayItems["factors"].id];
            }
        }
        return null;
    }

    get percentage(): number {
        return 5;
    }

    private togglePopup(): void {
        this.show = !this.show;
        if (this.idTag) {
            document.body.dispatchEvent(
                new CustomEvent("vp-weights-toggle", {
                    detail: { id: this.idTag },
                })
            );
        }
    }

    private roundNumber(value: number, digits: number): number {
        return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
    }

    private async saveWeightChange(payload: {
        mapping: ViewpointMapping | null;
        weight: number;
    }): Promise<void> {
        const viewpointMapping = payload.mapping;
        const weight = payload.weight;
        const viewpoint_id = this.displayItems["viewpoints"]?.id;
        const factor_id = this.displayItems["factors"]?.id;
        this.changeLoading = true;
        this.show = false;
        if (viewpointMapping != null) {
            await updateViewpointMappingWeight({
                viewpoint_id: viewpointMapping.viewpoint_id,
                factor_id: viewpointMapping.factor_id,
                weight: weight,
            });
        } else if (viewpoint_id && factor_id) {
            await createViewpointMappingHelper({
                viewpoint_id: viewpoint_id,
                factor_id: factor_id,
                weight: weight,
                use_m_score: true,
                use_m_index: true,
            });
        }
        this.changeLoading = false;
    }
}
