
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, ChoiceItem } from "@/graphql/API";
import Choices from "@/store/modules/Choices";
import Decisions from "@/store/modules/Decisions";
import draggable from "vuedraggable";

const choiceModule = getModule(Choices);
const decisionsModule = getModule(Decisions);

@Component({
    components: {
        draggable,
    },
})
export default class ChoiceToggle extends Vue {
    /* Selected Choice IDS */
    @PropSync("choiceList")
    syncedChoiceList!: number[];

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: true, type: Boolean })
    isTab!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    @Prop({ default: false, type: Boolean })
    newLock!: boolean;

    @Prop({ default: false, type: Boolean })
    createToggle!: boolean;

    @PropSync("newLock")
    syncedLock!: boolean;

    @PropSync("userEnabled")
    syncedUserEnabled!: boolean;

    /* Available IDS */
    @PropSync("ids")
    syncedIds!: number[];

    @PropSync("sortDef")
    syncedSortDef!: boolean;

    @PropSync("selectUserChoices")
    syncedSelectUserChoices!: boolean;

    @Prop({ default: () => [], type: Array })
    userChoices!: number[];

    private drag = false;
    private loading = false;

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get choiceLabel(): string {
        return decisionsModule.choiceLabelSingular;
    }

    get showAutoSelect(): boolean {
        return (
            !this.allItemsSelected &&
            (!this.syncedLock || this.syncedUserEnabled)
        );
    }

    get choices(): { [id: number]: Choice } {
        return choiceModule.choiceList;
    }

    get unSelectedLength(): number {
        if (this.syncedIds) {
            return this.syncedIds.filter(
                (id) => !this.syncedChoiceList.includes(id)
            ).length;
        } else {
            return 0;
        }
    }

    get allItemsSelected(): boolean {
        return this.unSelectedLength == 0;
    }

    get dragDisabled(): boolean {
        return this.syncedSortDef;
    }

    private createNew(): void {
        this.$emit("choice-select", {
            choice: null,
            isCopy: false,
        });
    }

    private selectItem(val: Choice, isCopy: boolean): void {
        this.$emit("choice-select", {
            choice: val,
            isCopy: isCopy,
        });
    }

    private toggleItem(id: number): void {
        if (this.syncedChoiceList.includes(id)) {
            this.syncedChoiceList.splice(this.syncedChoiceList.indexOf(id), 1);
        } else {
            this.syncedChoiceList.push(id);
        }
    }

    private selectAllItems(val: boolean): void {
        if (val) {
            this.syncedIds.forEach((id) => {
                if (!this.syncedChoiceList.includes(id)) {
                    this.syncedChoiceList.push(id);
                }
            });
        } else {
            this.syncedChoiceList.splice(0);
        }
    }

    private selectNewChoices(choices: Choice[]): void {
        if (choices.length && (this.isTab || this.embed)) {
            choices.forEach((choice) => {
                if (choice && !this.syncedChoiceList.includes(choice.id)) {
                    this.syncedChoiceList.push(choice.id);
                }
            });
        }
    }
}
