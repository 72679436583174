
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, Comment, Factor, Thread, Viewpoint } from "@/graphql/API";
import Comments from "@/store/modules/Comments";
import RichTextEditor from "../ui/RichTextEditor.vue";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import Viewpoints from "@/store/modules/Viewpoints";
import Choices from "@/store/modules/Choices";
import Factors from "@/store/modules/Factors";

const commentModule = getModule(Comments);
const workspaceModule = getModule(Workspaces);
const decisionModule = getModule(Decisions);
@Component({
    components: { RichTextEditor },
})
export default class CommentDisplay extends Vue {
    @Prop()
    comment!: Comment;

    @Prop()
    activeCommentEdit!: number;

    @Prop({ default: null, type: Number })
    replyTo!: number;

    @Prop({ default: false, type: Boolean })
    isOtherComment!: boolean;

    private isEditActive = false;
    private showDeleteWarning = false;

    private getUserName(user_id: string): string {
        const workspaceUser = workspaceModule.workspaceUsers[user_id];

        if (workspaceUser) {
            return workspaceUser.user.first + " " + workspaceUser.user.last;
        }
        return "Unknown User";
    }

    private getTimeFromUnix(time: number): string {
        const d = new Date(time * 1000);
        return d.toDateString();
    }

    private async saveComment(msg: string): Promise<void> {
        this.$emit("update-comment", {
            comment: this.comment.id,
            message: msg,
        });
    }

    private async deleteComment(commentId: number) {
        await commentModule.deleteComment(commentId);
    }

    private updateComment(newMessage: string): void {
        this.$emit("update-comment", {
            comment: newMessage,
            id: this.comment.id,
        });
        this.isEditActive = false;
    }

    get factorLabelSingular(): string {
        return decisionModule.factorLabelSingular;
    }

    get choiceLabelSingular(): string {
        return decisionModule.choiceLabelSingular;
    }

    get viewpointLabelSingular(): string {
        return decisionModule.viewpointLabelSingular;
    }

    get threadDict(): { [id: string]: Thread } {
        return commentModule.threadDict;
    }

    get thread(): Thread {
        return commentModule.threadDict[this.comment.thread_id];
    }

    get viewpoint(): Viewpoint | null {
        if (this.thread) {
            return getModule(Viewpoints).viewpointList[
                this.thread.viewpoint_id
            ];
        }

        return null;
    }

    get choice(): Choice | null {
        if (this.thread) {
            return getModule(Choices).choiceList[this.thread.choice_id];
        }

        return null;
    }

    get factor(): Factor | null {
        if (this.thread) {
            return getModule(Factors).factorMap[this.thread.factor_id];
        }

        return null;
    }

    @Watch("isEditActive")
    private onWatchIsEdit() {
        if (this.isEditActive) {
            this.$emit("comment-edit-active", this.comment.id);
        }
    }

    @Watch("activeCommentEdit")
    private onWatchEditCommentActive() {
        if (this.comment.id != this.activeCommentEdit) {
            this.isEditActive = false;
        }
    }
}
