import { render, staticRenderFns } from "./CopyApp.vue?vue&type=template&id=53ddc094&scoped=true&"
import script from "./CopyApp.vue?vue&type=script&lang=ts&"
export * from "./CopyApp.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ddc094",
  null
  
)

export default component.exports