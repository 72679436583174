
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Rule, ClassSet, Factor, ScoreClass, RuleRange } from "@/graphql/API";
import Scoring from "@/store/modules/Scoring";
import NumberEditor from "@/components/viewpoints/viewpointMapping/editors/NumberEditor.vue";
import ScoreClassCreator from "@/components/scoring/scoreClass/ScoreClassCreator.vue";
import VpDialog from "@/components/ui/VpDialog.vue";

const scoringModule = getModule(Scoring);

@Component({
    components: {
        NumberEditor,
        ScoreClassCreator,
        VpDialog,
    },
})
export default class NumberRule extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    scoreRule!: Rule;

    @Prop()
    classRule!: Rule;

    @Prop()
    cluster!: ClassSet;

    @Prop()
    scoreRuleId!: number | null;

    @Prop({ default: null, type: Number })
    viewpointId!: number;

    @Prop({ default: null, type: Number })
    forcedMin!: number;

    @Prop({ default: null, type: Number })
    forcedMax!: number;

    @Prop({ default: false, type: Boolean })
    manualScore!: boolean;

    @Prop({ default: true, type: Boolean })
    factorsSynced!: boolean;

    @Prop({ default: false, type: Boolean })
    saving!: boolean;

    @Prop()
    agFunctionChanged!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    private editModal = false;
    private selectedScoreClass: ScoreClass | null = null;
    private classIndex: number | null = null;
    private deletedRanges: RuleRange[] = [];

    private internalItems: {
        at_least: number;
        score: number;
        class_id: number | null;
        score_range_id?: number;
        class_range_id: number;
        id?: number | null;
    }[] = [];
    // private internalClassList: { [index: number]: number } = {};
    private minRangeScore = 0;
    private minRangeClass: number | null = null;
    private loading = false;
    private useSlider = false;

    get currentScores(): number[] {
        if (this.internalItems) {
            return this.internalItems.map((item) => item.score);
        }
        return [];
    }

    get duplicateScores(): boolean {
        if (this.manualScore) {
            return (
                new Set(this.currentScores).size !== this.currentScores.length
            );
        } else {
            return false;
        }
    }

    get classSet(): { [index: number]: number } {
        if (this.cluster) {
            return this.cluster.mappings.reduce((obj, item) => {
                return {
                    ...obj,
                    [item.index]: item.class_id,
                };
            }, {});
        } else {
            return {};
        }
    }

    get classBelowMin(): number | null {
        if (this.classSet && this.classSet[0]) {
            return this.classSet[0];
        } else {
            return null;
        }
    }

    get ruleChanged(): boolean {
        return (
            this.rangesChanged ||
            this.classesChanged ||
            this.minRangeChange ||
            this.agFunctionChanged
        );
    }

    get btnLabel(): string {
        if (this.saving) {
            return "Saving...";
        } else if (this.ruleChanged) {
            return "Save";
        } else {
            return this.factorsSynced ? "Save" : "Sync Factors";
        }
    }

    get btnMsg(): string {
        if (this.loading) {
            return "Loading...";
        } else if (!(this.rangesChanged || this.classesChanged)) {
            return "Make a change to save";
        } else if (this.duplicateScores) {
            return "Cannot have duplicate scores";
        } else {
            return "Click to save";
        }
    }

    get internalClassList(): { [index: number]: number | null } {
        if (this.minRangeClass != null) {
            return this.internalItems.reduce(
                (obj, item, index) => {
                    if (item.class_id) {
                        return {
                            ...obj,
                            [index + 1]: item.class_id,
                        };
                    } else {
                        return obj;
                    }
                },
                { 0: this.minRangeClass }
            );
        } else {
            return this.internalItems.reduce((obj, item, index) => {
                if (item.class_id) {
                    return {
                        ...obj,
                        [index + 1]: item.class_id,
                    };
                } else {
                    return obj;
                }
            }, {});
        }
    }

    get ruleIds(): string {
        return `${this.scoreRule ? this.scoreRule.id : "null"}-${
            this.classRule ? this.classRule.id : "null"
        }-${this.cluster ? this.cluster.id : "null"}`;
    }

    get factorRule(): string {
        return `${this.factor ? this.factor.id.toString() : "null"}-${
            this.viewpointId ? this.viewpointId : "null"
        }`;
    }

    get scoreItems(): {
        at_least: number;
        score: number;
        class_id: number | null;
        score_range_id?: number;
        class_range_id: number;
    }[] {
        let classRuleRanges: RuleRange[] = [];
        let scoreRuleRanges: RuleRange[] = [];
        if (this.classRule) {
            classRuleRanges = this.classRule.ranges.sort(
                (a, b) => a.at_least - b.at_least
            );
        }
        if (this.scoreRule) {
            scoreRuleRanges = this.scoreRule.ranges.sort(
                (a, b) => a.at_least - b.at_least
            );
        }

        if (
            (this.manualScore || this.factor.is_group) &&
            this.classRule &&
            this.classRule.index_uses_score
        ) {
            return classRuleRanges.map((range, index) => {
                return {
                    at_least: range.at_least,
                    score: range.at_least,
                    class_id: this.classSet[range.score],
                    class_range_id: range.id,
                    score_range_id:
                        scoreRuleRanges && scoreRuleRanges[index]
                            ? scoreRuleRanges[index].id
                            : undefined,
                };
            });
        } else if (
            this.scoreRule &&
            this.scoreRule.is_range &&
            this.scoreRule.ranges.length > 0
        ) {
            return scoreRuleRanges.map((range, index) => {
                return {
                    at_least: range.at_least,
                    score: range.score,
                    class_id: this.classSet[index + 1]
                        ? this.classSet[index + 1]
                        : null,
                    score_range_id: range.id,
                    class_range_id: classRuleRanges[index].id,
                };
            });
        } else {
            return [];
        }
    }

    get classList(): { [index: number]: number } {
        if (this.cluster?.mappings) {
            return [...this.cluster.mappings].reduce((obj, item) => {
                return {
                    ...obj,
                    [item.index]: item.class_id,
                };
            }, {});
        } else {
            return {};
        }
    }

    /* Properties for detecting changes */
    get minRangeChange(): boolean {
        return (
            this.scoreRule &&
            !(this.minRangeScore == this.scoreRule.min_range_score)
        );
    }

    get rangesChanged(): boolean {
        if (this.internalItems.length == this.scoreItems.length) {
            return !this.internalItems.every((item, index) => {
                return (
                    item.at_least == this.scoreItems[index].at_least &&
                    item.score == this.scoreItems[index].score
                );
            });
        } else {
            return true;
        }
    }

    get belowMinChange(): boolean {
        return false;
    }

    get classesChanged(): boolean {
        return (
            JSON.stringify(this.internalClassList) !=
            JSON.stringify(this.classList)
        );
    }

    get unsavedChanges(): boolean {
        return this.ruleChanged && !this.loading && !this.saving;
    }

    private async toggleClass(e: {
        class: number;
        range: number;
    }): Promise<void> {
        if (e && e.class && scoringModule.scoreClasses[e.class]) {
            this.selectedScoreClass = scoringModule.scoreClasses[e.class];
            this.classIndex = e.range;
            this.editModal = true;
        }
    }

    private async setNewClass(newClass: ScoreClass) {
        if (this.classIndex != null) {
            this.editModal = false;
            this.selectedScoreClass = null;
            if (this.classIndex > -1 && newClass) {
                Vue.set(
                    this.internalItems[this.classIndex],
                    "class_id",
                    newClass.id
                );
            } else {
                this.minRangeClass = newClass.id;
            }
            this.classIndex = null;
            await this.saveRanges();
        }
    }

    private async saveRanges(): Promise<void> {
        this.$emit("save-ranges", {
            ranges: this.internalItems,
            min: this.minRangeScore ? this.minRangeScore : 0,
            minClass: this.minRangeClass,
            deletedRanges: this.deletedRanges,
        });
    }

    private changeMinValue(val: number) {
        this.$emit("save-min", val);
    }

    private changeMaxValue(val: number) {
        this.$emit("save-max", val);
    }

    private deleteRange(delRange: RuleRange) {
        this.deletedRanges.push(delRange);
    }

    mounted(): void {
        this.onclassBelowMinChange();
        this.internalItems = this.scoreItems.map((a) => Object.assign({}, a));
        if (this.manualScore) {
            this.internalItems.sort((a, b) => {
                return a.score - b.score;
            });
        }
        this.minRangeScore = this.scoreRule
            ? this.scoreRule.min_range_score
            : 0;
    }

    @Watch("ruleIds", { deep: true })
    onRuleChange(): void {
        this.internalItems = this.scoreItems.map((a) => Object.assign({}, a));
        if (this.manualScore) {
            this.internalItems.sort((a, b) => {
                return a.score - b.score;
            });
        }
        this.minRangeScore = this.scoreRule
            ? this.scoreRule.min_range_score
            : 0;
    }

    @Watch("manualScore")
    onManualScoreChange(): void {
        this.onRuleChange();
    }

    @Watch("classBelowMin")
    onclassBelowMinChange(): void {
        this.minRangeClass = this.classBelowMin;
    }

    @Watch("unsavedChanges")
    onUnsavedChangesChanged(): void {
        scoringModule.setUnsavedRuleChanges(this.unsavedChanges);
    }
}
