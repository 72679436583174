
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Choice,
    Viewpoint,
    Locks,
    AppOptions,
    ViewpointItem,
    ChoiceItem,
} from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import ListSelector from "@/components/ui/ListSelector.vue";
import FactorNav from "@/components/model/FactorNav.vue";
import FactorToggleGroup from "@/components/model/FactorToggle/FactorToggleGroup.vue";
import FactorGroup from "@/components/model/FactorNav/FactorGroup.vue";
import ViewpointToggle from "@/components/ui/ViewpointToggle.vue";
import ChoiceToggle from "@/components/ui/ChoiceToggle.vue";
import ChoiceCreator from "@/components/choices/ChoiceCreator.vue";
import ViewpointCreator from "@/components/viewpoints/ViewpointCreator.vue";

const decisionsModule = getModule(Decisions);

@Component({
    components: {
        FactorToggleGroup,
        ListSelector,
        FactorNav,
        FactorGroup,
        ViewpointToggle,
        ChoiceToggle,
        ChoiceCreator,
        ViewpointCreator,
    },
})
export default class CompareObjects extends Vue {
    @PropSync("choices")
    syncedChoices!: Choice[];

    @PropSync("viewpoints")
    syncedViewpoints!: Viewpoint[];

    @PropSync("choicesAll")
    syncedChoicesAll!: boolean;

    @PropSync("viewpointsAll")
    syncedViewpointsAll!: boolean;

    @PropSync("choicesSelected")
    syncedChoicesSelected!: number[];

    @PropSync("viewpointsSelected")
    syncedViewpointsSelected!: number[];

    @PropSync("factorsAll")
    syncedFactorsAll!: boolean;

    @PropSync("factorsSelected")
    syncedFactorsSelected!: number[];

    @PropSync("locks")
    syncedLocks!: Locks;

    @PropSync("rootFactor")
    syncedRootFactor!: number;

    @PropSync("choiceList")
    syncedChoiceList!: number[];

    @PropSync("viewpointList")
    syncedViewpointList!: number[];

    @PropSync("choiceIds")
    syncedChoiceIds!: number[];

    @PropSync("viewpointIds")
    syncedViewpointsIds!: number[];

    @PropSync("choiceDef")
    syncedChoiceDef!: boolean;

    @PropSync("viewpointDef")
    syncedViewpointDef!: boolean;

    @Prop({ default: () => [], type: Array })
    hiddenFactors!: number[];

    @Prop({ default: () => [], type: Array })
    tabFactors!: number[];

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop({ default: true, type: Boolean })
    isTab!: boolean;

    @Prop({ default: () => [], type: Array })
    userChoices!: number[];

    @Prop({ default: () => [], type: Array })
    userViewpoints!: number[];

    @PropSync("userChoicesEnabled")
    syncedUserChoicesEnabled!: boolean;

    @PropSync("userViewpointsEnabled")
    syncedUserViewpointsEnabled!: boolean;

    @PropSync("selectUserChoices")
    syncedSelectUserChoices!: boolean;

    @PropSync("selectUserViewpoints")
    syncedSelectUserViewpoints!: boolean;

    @PropSync("hideChoices")
    syncedHideChoices!: boolean;

    @PropSync("hideViewpoints")
    syncedHideViewpoints!: boolean;

    @Prop()
    appOptions!: AppOptions;

    private index: string | null = null;

    private selectedChoice: Choice | null = null;
    private copyChoice = false;
    private selectedViewpoint: Viewpoint | null = null;
    private copyViewpoint = false;

    private open: number | null = null;

    get choicesLabel(): string {
        return decisionsModule.choiceLabelPlural;
    }

    get createChoice(): boolean {
        if (this.appOptions && this.appOptions.userChoice) {
            return true;
        } else {
            return false;
        }
    }

    get createViewpoint(): boolean {
        if (this.appOptions && this.appOptions.userViewpoint) {
            return true;
        } else {
            return false;
        }
    }

    get showChoices(): boolean {
        if (this.embed) {
            if (this.syncedHideChoices) {
                return false;
            } else {
                return (
                    this.syncedChoiceIds.length > 0 ||
                    !this.syncedLocks.newChoices
                );
            }
        } else {
            return true;
        }
    }

    get showViewpoints(): boolean {
        if (this.embed) {
            if (this.syncedHideViewpoints) {
                return false;
            } else {
                return (
                    this.syncedViewpointsIds.length > 0 ||
                    !this.syncedLocks.newViewpoints
                );
            }
        } else {
            return true;
        }
    }

    private selectChoice(payload: {
        choice: Choice | null;
        isCopy: boolean;
    }): void {
        this.index = "choices";
        this.selectedChoice = payload.choice;
        this.copyChoice = payload.isCopy;
    }

    private selectViewpoint(payload: {
        viewpoint: Viewpoint | null;
        isCopy: boolean;
    }): void {
        this.index = "viewpoints";
        this.selectedViewpoint = payload.viewpoint;
        this.copyViewpoint = payload.isCopy;
    }

    private goBack(): void {
        this.index = null;
    }

    private selectNewChoices(choices: Choice[]): void {
        if (choices.length && (this.isTab || this.embed)) {
            choices.forEach((choice) => {
                if (choice && !this.syncedChoiceList.includes(choice.id)) {
                    this.syncedChoiceList.push(choice.id);
                }
            });
        }
        this.goBack();
    }

    private selectNewViewpoints(viewpoints: Viewpoint[]): void {
        if (viewpoints.length && (this.isTab || this.embed)) {
            viewpoints.forEach((viewpoint) => {
                if (
                    viewpoint &&
                    !this.syncedViewpointList.includes(viewpoint.id)
                ) {
                    this.syncedViewpointList.push(viewpoint.id);
                }
            });
        }
        this.goBack();
    }

    private toggleViewpoints(): void {
        if (this.syncedHideViewpoints) {
            this.syncedHideViewpoints = false;
        } else {
            this.syncedHideViewpoints = true;
            this.syncedViewpointList = [];
        }
    }

    private toggleChoices(): void {
        if (this.syncedHideChoices) {
            this.syncedHideChoices = false;
        } else {
            this.syncedHideChoices = true;
            this.syncedChoiceList = [];
        }
    }
}
