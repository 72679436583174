import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { App, Tab, TableTabFlags } from "@/graphql/API";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import Decisions from "@/store/modules/Decisions";
import PermissionCheck from "@/components/mixins/PermissionCheck";

const decisionsModule = getModule(Decisions);

@Component
export default class TableBase extends mixins(PermissionCheck) {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    tabSettings!: Tab;

    @Prop()
    app!: App;

    @Prop({ default: false, type: Boolean })
    embed!: boolean;

    @Prop()
    header!: string;

    @Prop()
    bannerImage!: string;

    @Prop()
    bannerAlign!: string;

    loadingTabSettings = false;
    appCreateOpen = false;
    tabEditFlags: TableTabFlags = {
        edit: {
            headers: [],
            tools: [],
            actions: [],
        },
        locks: {
            headers: [],
            tools: [],
            actions: [],
        },
    };

    newTab = false;
    editFlagsLoading = false;
    tabCheckboxes = false;

    get decisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get tabHeaders(): string[] {
        return this.tabEditFlags.edit.headers;
    }

    get tabTools(): string[] {
        return this.tabEditFlags.edit.tools;
    }

    get tabActions(): string[] {
        return this.tabEditFlags.edit.actions;
    }

    get tabHeaderLocks(): string[] {
        return this.tabEditFlags.locks.headers;
    }

    get tabToolLocks(): string[] {
        return this.tabEditFlags.locks.tools;
    }

    get tabActionLocks(): string[] {
        return this.tabEditFlags.locks.actions;
    }

    get isMainTool(): boolean {
        return !this.embed && !this.isEdit;
    }

    get tabEditFlagString(): string {
        if (this.tabSettings) {
            return this.tabSettings.edit_flags;
        } else {
            return "";
        }
    }

    private async loadTabEditOptions(flags: TableTabFlags): Promise<void> {
        if (flags) {
            if (flags.showAll) {
                this.newTab = true;
            } else {
                if (flags.edit) {
                    Vue.set(
                        this.tabEditFlags.edit,
                        "headers",
                        flags.edit.headers ? flags.edit.headers : []
                    );
                    Vue.set(
                        this.tabEditFlags.edit,
                        "tools",
                        flags.edit.tools ? flags.edit.tools : []
                    );
                    Vue.set(
                        this.tabEditFlags.edit,
                        "actions",
                        flags.edit.actions ? flags.edit.actions : []
                    );
                }
                if (flags.locks) {
                    Vue.set(
                        this.tabEditFlags.locks,
                        "headers",
                        flags.locks.headers ? flags.locks.headers : []
                    );
                    Vue.set(
                        this.tabEditFlags.locks,
                        "tools",
                        flags.locks.tools ? flags.locks.tools : []
                    );
                    Vue.set(
                        this.tabEditFlags.locks,
                        "actions",
                        flags.locks.actions ? flags.locks.actions : []
                    );
                }
                if (flags.checkBoxes) {
                    this.tabCheckboxes = true;
                } else {
                    this.tabCheckboxes = false;
                }
            }
        }
    }

    private async loadTabOptions(): Promise<void> {
        if (this.tabSettings) {
            await Promise.all([
                this.loadTabEditOptions(
                    this.tabSettings.edit_flags != ""
                        ? JSON.parse(this.tabSettings.edit_flags)
                        : null
                ),
            ]);
        }
    }

    async mounted(): Promise<void> {
        this.loadingTabSettings = true;
        await this.loadTabOptions();
        this.loadingTabSettings = false;
    }

    @Watch("tabEditFlagString")
    async onEditFlagChange(): Promise<void> {
        console.log("Tab Edit Flabg String Change!!!!!!!!!!!!!!!!!")
        this.loadingTabSettings = true;
        await this.loadTabOptions();
        this.loadingTabSettings = false;
    }
}
