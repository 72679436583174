var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.factor)?_c('div',{staticClass:"vp-factor-item",class:{
        'vp-factor-item--active': _vm.active,
        'vp-factor-item--disabled': _vm.disabled,
    }},[(_vm.isGroup)?_c('div',{staticClass:"vp-factor-item__offset-wrapper"},_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"vp-factor-item__offset",class:{ 
                'vp-factor-item__offset--last': _vm.last,
                'vp-factor-item__offset--parent-last': _vm.parentLast,
                'vp-factor-item__offset--hidden': _vm.hiddenDepth.includes(index),
            }})}),0):_vm._e(),_vm._t("default"),_c('v-btn',{staticClass:"btn-plain btn-text-left btn-unstyled vp-factor-item__btn",attrs:{"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.clickFactor($event, _vm.factor, false)}}},[(!_vm.isGroup)?_c('div',{staticClass:"vp-factor-item__offset-wrapper"},_vm._l((_vm.depth),function(index){return _c('div',{key:index,staticClass:"vp-factor-item__offset",class:{ 
                    'vp-factor-item__offset--last': _vm.last,
                    'vp-factor-item__offset--parent-last': _vm.parentLast,
                    'vp-factor-item__offset--hidden': _vm.hiddenDepth.includes(index),
                }})}),0):_vm._e(),_c('RecursiveModelFactorLabel',{attrs:{"description":_vm.factor.description}},[_c('div',{staticClass:"vp-factor-item__icon"},[(_vm.type)?_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.Icons[_vm.type])+" ")]):_vm._e()],1),_c('span',{staticClass:"vp-factor-item__label"},[_vm._v(" "+_vm._s(_vm.factor.name)+" ")])])],1),(_vm.editModel && !_vm.hideDrag)?[(!_vm.disabled && _vm.factor.parent_id != null)?_c('div',{staticClass:"vp-factor-item__drag"},[_c('v-icon',[_vm._v("mdi-drag")])],1):_c('div',{staticClass:"vp-factor-item__drag--disabled"},[_c('v-icon',[_vm._v("mdi-drag")])],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }