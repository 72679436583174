
import { Component, Vue, Model, Watch, Prop } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import { getModule } from "vuex-module-decorators";
import Workspaces from "@/store/modules/Workspaces";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import Image from "@tiptap/extension-image";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Link from "@tiptap/extension-link";
import { v4 as uuidv4 } from "uuid";
import { uploadMediaFile, getS3FileUrl } from "@/helpers/MediaUploadHelper";

const workspaceModule = getModule(Workspaces);

@Component({ name: "RichTextEditor", components: { EditorContent } })
export default class RichTextEditor extends Vue {
    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: Boolean, default: false })
    isDescription!: string;

    @Prop({ type: Number })
    factorsIndex!: number;

    @Prop({ default: null, type: Number })
    factorMin!: number;

    @Prop({ default: null, type: Number })
    factorMax!: number;

    @Prop({ type: String, default: "/assets" })
    folderKey!: string;

    @Prop({default: false, type: Boolean})
    showCommentBtn!: boolean;

    @Prop({default: false, type: Boolean})
    showCancelButton!: boolean;

    private editor: any = null;
    private imageUrl = "";
    private file: File | null = null;
    private uploadedFile: any = null;
    private uploadingFile = false;
    private newContent = this.value;
    private pasteImage = false;
    private pasteTable = false;
    private showMenu = false;

    get workspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    private rules = {
        isStringMinValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ) => {
            const re = /<\/*[a-z|:]+>/g;
            const taglessStr = val.replaceAll(re, "");
            if (min != undefined && taglessStr) {
                return taglessStr.length >= min
                    ? true
                    : `Length must be greater than ${min} characters.`;
            } else {
                return true;
            }
        },
        isStringMaxValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ) => {
            const re = /<\/*[a-z|:]+>/g;
            const taglessStr = val.replaceAll(re, "");
            if (max != undefined && taglessStr) {
                return taglessStr.length <= max
                    ? true
                    : `Length must be less than ${max} characters`;
            } else {
                return true;
            }
        },
    };

    get errMessages(): string {
        const minVal = this.rules.isStringMinValid(
            this.editor.getHTML(),
            this.factorMin,
            this.factorMax
        );
        const maxVal = this.rules.isStringMaxValid(
            this.editor.getHTML(),
            this.factorMin,
            this.factorMax
        );
        if (minVal != true) {
            return minVal;
        }

        if (maxVal != true) {
            return maxVal;
        }

        return "";
    }

    @Watch("value")
    onValueChange(value: string) {
        const isSame = this.editor.getHTML() === value;
        if (isSame) {
            return;
        }
        this.newContent = this.value;
        this.editor.commands.setContent(value, false);
    }

    @Watch("showMenu")
    onEditorFocusChange() {
        if (!this.editorFocus)
            this.$emit("saveRichText", this.newContent, this.factorsIndex);
    }

    mounted() {
        this.editor = this.getEditor();
        this.editor.on("update", () => {
            this.newContent = this.editor.getHTML();
        });
        this.editor.on("focus", ({}) => {
            this.showMenu = true;
        });
    }

    get editorFocus(): boolean {
        return this.editor.isFocused && this.showMenu;
    }

    private getEditor() {
        return new Editor({
            content: this.newContent,
            extensions: [
                StarterKit,
                Heading,
                Underline,
                Strike,
                Image.configure({ allowBase64: true }),
                Table.configure({ resizable: true }),
                TableRow,
                TableHeader,
                TableCell,
                Link.configure({ autolink: true }),
            ],
        });
    }
    //TODO fix issues with expired link form S3 bucket, right now image btn is disabled
    private async addImage() {
        if (this.imageUrl) {
            const fileName = uuidv4() + ".jpeg";
            let response = await fetch(this.imageUrl);
            let data = await response.blob();
            let metadata = {
                type: "image/jpeg",
            };
            let file = new File([data], fileName, metadata);
            this.file = file;

            // await this.uploadMediaFile();

            // let result = "";

            // if (this.file) {
            //     result = await uploadMediaFile({
            //         file: this.file,
            //         type: "image",
            //         name: fileName,
            //     });
            // }
            // if (result) {
            //     this.imageUrl = await getS3FileUrl({
            //         fileName: fileName,
            //         type: "image",
            //     });
            // }

            this.editor.chain().focus().setImage({ src: this.imageUrl }).run();
        }
        this.pasteImage = false;
    }

    private setLink() {
        const previousUrl = this.editor.getAttributes("link").href;
        const url = window.prompt("URL", previousUrl);

        // cancelled
        if (url === null) {
            return;
        }

        // empty
        if (url === "") {
            this.editor
                .chain()
                .focus()
                .extendMarkRange("link")
                .unsetLink()
                .run();

            return;
        }

        // update link
        this.editor
            .chain()
            .focus()
            .extendMarkRange("link")
            .setLink({ href: url })
            .run();
    }
    //TODO fix issues with expired link form S3 bucket, right now image btn is disabled
    private async uploadMediaFile(): Promise<void> {
        console.log("startUpload");
        let result = "";
        const fileName = uuidv4() + ".jpeg";

        if (this.file) {
            result = await uploadMediaFile({
                file: this.file,
                type: "image",
                name: fileName,
                srcFileName: this.file.name,
                key: this.workspaceId
                    ? `${this.workspaceId}/assets`
                    : "/assets",
            });
        }

        this.imageUrl = await getS3FileUrl({ fileName: result, type: "image" });
    }
}
