var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-score-display",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY} ${
        _vm.classColour ? 'vp-score-display--shadow' : ''
    }`,style:(_vm.classColour ? `background-color: ${_vm.classColour}` : '')},[(_vm.header)?_c('div',{staticClass:"vp-score-display__header"},[_vm._v(" "+_vm._s(_vm.header)+" ")]):_vm._e(),_c('div',{staticClass:"vp-score-display__content"},[_c('div',{staticClass:"vp-score-display__top"},[_c('div',{staticClass:"vp-score-display__icons"},[(_vm.showIcons)?[_c('IconDisplay',{attrs:{"json":_vm.scoreClass.json}})]:_vm._e()],2),_c('div',{staticClass:"vp-score-display__buttons",class:{ 'vp-score-btns--invert': _vm.invertBtns }},[(_vm.showWeights)?[_vm._t("weights")]:_vm._e(),(_vm.showScoreRule)?[_vm._t("scoreRule")]:_vm._e(),(_vm.showComments)?[_vm._t("comments")]:_vm._e()],2)]),_c('div',{staticClass:"vp-score-display__score"},[_c('FactorClassDisplay',{attrs:{"score-class":_vm.scoreClass,"score-display":_vm.scoreDisplay,"viewpoint-map":_vm.viewpointMap,"show-class-selector":_vm.showClassSelector,"show-class-description":_vm.scoreDisplay.class_description,"disable-selector":!_vm.classRating,"selector-settings":_vm.selectorSettings},on:{"class-select":_vm.setClassScore},scopedSlots:_vm._u([(_vm.scoreVisible)?{key:"score",fn:function(){return [(_vm.editableScore)?[(_vm.inputScore)?_c('v-text-field',{staticClass:"text-center vp-score-input",attrs:{"type":"number","value":_vm.formatScore,"dense":"","outlined":"","hide-details":""},on:{"change":_vm.setTextScore}}):_c('span',{staticClass:"vp-score-display__score__value",on:{"click":function($event){_vm.inputScore = true}}},[_vm._v(" "+_vm._s(_vm.displayScore)+" ")])]:[_vm._v(" "+_vm._s(_vm.displayScore)+" ")]]},proxy:true}:null],null,true)},[(_vm.manualScore && _vm.scoreDisplay && _vm.scoreDisplay.bar)?_c('v-slider',{staticClass:"vp-slider vp-scoing-class__slider",class:_vm.scoreClassStyle.slider_size
                            ? `vp-slider--${_vm.scoreClassStyle.slider_size}`
                            : 'temp',attrs:{"min":_vm.range[0] ? _vm.range[0] : 0,"max":_vm.range[_vm.range.length - 1]
                            ? _vm.range[_vm.range.length - 1]
                            : 1,"step":_vm.presentation != 'percent' ? '0.001' : '.01',"color":_vm.scoreClassStyle.slider_colour
                            ? _vm.palette[_vm.scoreClassStyle.slider_colour].hex
                            : '',"track-color":_vm.scoreClassStyle.slider_colour
                            ? _vm.palette[_vm.scoreClassStyle.slider_colour].hex
                            : '',"dense":"","thumb-label":"","hide-details":"","readonly":!_vm.editable},on:{"change":_vm.setScore,"click":function($event){$event.stopPropagation();$event.preventDefault();}},model:{value:(_vm.internalScore),callback:function ($$v) {_vm.internalScore=$$v},expression:"internalScore"}}):_vm._e(),(
                        !_vm.manualScore &&
                        _vm.scoreValue != null &&
                        _vm.scoreDisplay &&
                        _vm.scoreDisplay.bar
                    )?_c('v-slider',{staticClass:"vp-slider vp-scoing-class__slider",class:_vm.scoreClassStyle.slider_size
                            ? `vp-slider--${_vm.scoreClassStyle.slider_size}`
                            : 'temp',attrs:{"min":_vm.range[0],"max":_vm.range[_vm.range.length - 1],"step":_vm.presentation != 'percent' ? '0.001' : '.0001',"color":_vm.scoreClassStyle.slider_colour
                            ? _vm.palette[_vm.scoreClassStyle.slider_colour].hex
                            : '',"track-color":_vm.scoreClassStyle.slider_colour
                            ? _vm.palette[_vm.scoreClassStyle.slider_colour].hex
                            : '',"value":_vm.scoreValue,"readonly":"","dense":"","hide-details":""}}):_vm._e()],1)],1),_c('div',[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }