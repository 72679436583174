
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Decision, Workspace } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import FlashNotifications from "@/store/modules/FlashNotifications";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const flashModule = getModule(FlashNotifications);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {},
})
export default class CopyApp extends Vue {
    @Prop()
    app!: App;

    @Prop({ default: false, type: Boolean })
    isCopy!: boolean;

    private loading = false;
    private destName = "";
    private destDecisionId: number | null = null;

    get appId(): number | null {
        return this.app ? this.app.id : null;
    }

    get currentDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get title(): string {
        return this.app ? `Copy ${this.app.title}` : "Copy App";
    }

    get decisions(): Decision[] {
        // remove decisions with negative id
        let filteredDecisions = decisionsModule.allUserDecisionsList.filter(
            (decision) => decision.workspace_id > 0
        );

        return filteredDecisions;
    }

    get workspaces(): Workspace[] {
        return workspaceModule.workspaceList;
    }

    get copyDisabled(): boolean {
        return !this.destName || !this.destDecisionId || this.loading;
    }

    private close(): void {
        this.destName = "";
        this.destDecisionId = null;
    }

    private workspaceTitle(decision: Decision): string {
        return `${workspaceModule.workspaces[decision.workspace_id].name}`;
    }

    private async copyApp(): Promise<void> {
        if (this.destName && this.destDecisionId && this.appId) {
            this.loading = true;
            try {
                await appsModule.copyApp({
                    app_id: this.appId,
                    dest_name: this.destName,
                    dest_d_id: this.destDecisionId,
                });
                if (this.destDecisionId === this.currentDecisionId) {
                    await appsModule.getApps(this.destDecisionId);
                    this.destDecisionId = null;
                }
                flashModule.success({
                    message: `${this.destName} copied`,
                    duration: 3000,
                });
                this.destName = "";
                this.$emit("copied-app", true);
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: message,
                    duration: 3000,
                });
            }
            this.loading = false;
        }
    }

    async mounted(): Promise<void> {
        await decisionsModule.fetchDecisions();
    }
}
