
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Rule,
    ClassSet,
    Factor,
    CategoryClass,
    EnumValue,
    Enum,
    categoryOption,
    RuleMatch,
    ScoreClass,
    ClassMapping,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Scoring from "@/store/modules/Scoring";
import OptionEditor from "@/components/viewpoints/viewpointMapping/editors/OptionEditor.vue";
import ScoreClassCreator from "@/components/scoring/scoreClass/ScoreClassCreator.vue";
import VpDialog from "@/components/ui/VpDialog.vue";

const modelModule = getModule(Factors);
const scoreModule = getModule(Scoring);

@Component({
    components: {
        OptionEditor,
        ScoreClassCreator,
        VpDialog,
    },
})
export default class OptionRule extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    factors!: Factor[];

    @Prop()
    scoreRule!: Rule;

    @Prop()
    cluster!: ClassSet;

    @Prop()
    scoreRuleId!: number | null;

    @Prop({ default: null, type: Number })
    viewpointId!: number;

    @Prop()
    classRule!: Rule;

    @Prop({ default: false, type: Boolean })
    manualScore!: boolean;

    @Prop({ default: true, type: Boolean })
    factorsSynced!: boolean;

    @Prop({ default: false, type: Boolean })
    saving!: boolean;

    @Prop({ default: true, type: Boolean })
    editable!: boolean;

    private internalClassList: CategoryClass[] = [];
    private internalOptions: EnumValue[] = [];
    private loading = false;

    private editModal = false;
    private selectedScoreClass: ScoreClass | null = null;
    private classIndex: number | null = null;

    get currentScores(): number[] {
        return this.internalClassList.map((item) => item.score);
    }

    get duplicateScores(): boolean {
        if (this.manualScore) {
            return (
                new Set(this.currentScores).size !== this.currentScores.length
            );
        } else {
            return false;
        }
    }

    get btnLabel(): string {
        return this.saving ? "Saving..." : "Save";
    }

    get btnMsg(): string {
        if (this.duplicateScores) {
            return "Cannot have duplicate scores";
        } else {
            return "Click to save";
        }
    }

    get factorRule(): string {
        return `${this.factor ? this.factor.id.toString() : "null"}-${
            this.viewpointId ? this.viewpointId : "null"
        }`;
    }

    get ruleIds(): string {
        return `${this.scoreRule ? this.scoreRule.id : "null"}-${
            this.classRule ? this.classRule.id : "null"
        }-${this.cluster ? this.cluster.id : "null"}`;
    }

    get factorEnum(): Enum | null {
        if (this.factor.enum_id && modelModule.enums[this.factor.enum_id]) {
            return modelModule.enums[this.factor.enum_id];
        }
        return null;
    }

    get enums(): EnumValue[] {
        if (this.factorEnum && this.factorEnum.values) {
            return this.factorEnum.values;
        }
        return [];
    }

    get enumsIndex(): { [id: string]: EnumValue } {
        return this.enums.reduce((acc, value) => {
            return {
                ...acc,
                [value.id.toString()]: value,
            };
        }, {});
    }

    get categories(): categoryOption[] {
        if (this.factor && this.factor.value_type == "category") {
            if (this.factor.json) {
                try {
                    let options = JSON.parse(this.factor.json);
                    return options.categories.map(
                        (item: string, index: number) => {
                            return {
                                id: `category-${index}`,
                                label: item,
                            };
                        }
                    );
                } catch {
                    return [];
                }
            }
        }
        return [];
    }

    get selectedEnums(): string[] {
        if (this.classRule) {
            return this.classRule.matches.map((item) => item.match);
        } else {
            return [];
        }
    }

    /* Objects to save */
    get selectedClasses(): { [index: number]: number } {
        return this.internalClassList.reduce((obj, item) => {
            return {
                ...obj,
                [item.index]: item.id,
            };
        }, {});
    }

    get classRuleMap(): { [index: number]: number } {
        if (this.classRule.is_range && this.classRule.ranges) {
            return this.classRule.ranges.reduce((obj, item) => {
                return {
                    ...obj,
                    [item.score]: item.at_least,
                };
            }, {});
        }
        return {};
    }

    get mappings(): ClassMapping[] {
        if (this.cluster?.mappings) {
            return this.cluster.mappings;
        } else {
            return [];
        }
    }

    get matches(): {
        match: string;
        score: number;
        index: number;
        label: string;
    }[] {
        return this.internalClassList
            .map((item) => {
                if (item.bin.length) {
                    return item.bin
                        .filter((option) => option != null)
                        .map((option) => {
                            return {
                                match: option.id.toString(),
                                score: item.score,
                                index: item.index,
                                label: option.value,
                            };
                        });
                } else {
                    return [
                        {
                            match: `empty-${
                                item.class ? item.class.id : item.index
                            }`,
                            score: item.score,
                            index: item.index,
                            label: `empty-${
                                item.class ? item.class.id : item.index
                            }`,
                        },
                    ];
                }
            })
            .reduce((a, b) => {
                return a.concat(b);
            }, []);
    }

    get classChanged(): boolean {
        return this.internalClassList.length !== this.classRule?.matches.length;
    }

    get optionsApplied(): boolean {
        return this.enums.length != this.internalOptions.length;
    }

    get scoreChanged(): boolean {
        return !this.scoreRule.matches
            .sort((a, b) => b.score - a.score)
            .every((item, index) => {
                return item.score == this.currentScores[index];
            });
    }

    get unsavedChanges(): boolean {
        return (
            (this.classChanged || this.optionsApplied || this.scoreChanged) &&
            !this.loading &&
            !this.saving
        );
    }

    private async toggleClass(e: {
        class: number;
        range: number;
    }): Promise<void> {
        if (e && e.class && scoreModule.scoreClasses[e.class]) {
            console.log(e);
            this.selectedScoreClass = scoreModule.scoreClasses[e.class];
            this.classIndex = e.range;
            this.editModal = true;
        }
    }

    private async setNewClass(newClass: ScoreClass) {
        if (this.classIndex != null) {
            this.editModal = false;
            this.selectedScoreClass = null;

            if (newClass) {
                Vue.set(
                    this.internalClassList[this.classIndex],
                    "id",
                    newClass.id
                );
                Vue.set(
                    this.internalClassList[this.classIndex],
                    "class",
                    newClass
                );
                await this.saveOptions();
            }
        }
        this.classIndex = null;
    }

    /* Load Data Functions */
    private loadClassScore(match: RuleMatch): number {
        let scoreMatches = this.scoreRule.matches.filter(
            (item) => item.match == match.match
        );
        if (scoreMatches.length) {
            return scoreMatches[0].score;
        }
        return 0;
    }

    private loadMatches(index: number): RuleMatch[] {
        if (this.classRule && this.classRule.matches) {
            return this.classRule.matches.filter((item) => {
                return item.score == index;
            });
        }
        return [];
    }

    private loadData(): CategoryClass[] {
        if (this.cluster?.mappings) {
            if (this.manualScore && this.classRule.is_range) {
                return this.cluster.mappings.map((item) => {
                    return {
                        id: item.class_id,
                        class: scoreModule.scoreClasses[item.class_id],
                        score: this.classRuleMap[item.index]
                            ? this.classRuleMap[item.index]
                            : 0,
                        index: item.index,
                        bin: [],
                    };
                });
            } else {
                return this.cluster.mappings.map((item) => {
                    let matches = this.loadMatches(item.index);
                    let score = 0;

                    if (matches.length) {
                        score = this.loadClassScore(matches[0]);
                    }
                    return {
                        id: item.class_id,
                        class: scoreModule.scoreClasses[item.class_id],
                        score: matches.length ? score : 0,
                        index: item.index,
                        bin: matches
                            .filter(
                                (item: RuleMatch) =>
                                    !item.match.startsWith("empty")
                            )
                            .map((item: RuleMatch) => {
                                return this.enumsIndex[item.match];
                            }),
                    };
                });
            }
        } else {
            return [];
        }
    }

    private loadOptions(classList: CategoryClass[]): EnumValue[] {
        if (classList.length) {
            return this.enums.filter((item) => {
                return !this.selectedEnums.includes(item.id.toString());
            });
        } else {
            return [...this.enums];
        }
    }

    /* Saving Function */
    private async saveOptions(): Promise<void> {
        this.$emit("save-matches", {
            classList: this.selectedClasses,
            matches: this.matches,
            items: this.internalClassList,
        });
    }

    async mounted(): Promise<void> {
        this.onScoreRuleIdChange();
    }

    @Watch("manualScore")
    async onManualChange(): Promise<void> {
        if (this.manualScore) {
            this.loading = true;
            Object.assign(this.internalClassList, await this.loadData());
            this.loading = false;
        } else {
            this.onScoreRuleIdChange();
        }
    }

    @Watch("ruleIds")
    async onScoreRuleIdChange(): Promise<void> {
        const manual = this.manualScore;
        const classList = this.loadData();
        const options = this.loadOptions(classList);
        if (!this.loading || manual == this.manualScore) {
            this.internalClassList = classList;
            this.internalOptions = options;
            this.internalClassList.sort((a, b) => b.score - a.score);
        }
    }

    @Watch("mappings", { deep: true })
    onMappingChange(): void {
        this.onScoreRuleIdChange();
    }

    @Watch("unsavedChanges")
    onUnsavedChangesChanged(): void {
        scoreModule.setUnsavedRuleChanges(this.unsavedChanges);
    }
}
