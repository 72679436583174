
import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { TableHeader, TableAction } from "@/graphql/API";
import TableManagerItem from "@/components/managers/TableManagerItem.vue";
import TableManagerHeader from "@/components/managers/TableManagerHeader.vue";
import draggable from "vuedraggable";

@Component({
    components: {
        TableManagerItem,
        TableManagerHeader,
        draggable,
    },
})
export default class TableManager extends Vue {
    /* Items require an ID property */
    @Prop({ default: () => [], type: Array })
    items!: [];

    @Prop({ default: () => [], type: Array })
    headers!: TableHeader[];

    @Prop({ default: () => [], type: Array })
    childHeaders!: TableHeader[];

    @Prop({ default: () => [], type: Array })
    actions!: TableAction[];

    @Prop({ default: "No Items", type: String })
    emptyMessage!: string;

    @Prop({ type: Boolean, default: false })
    multiSelect!: boolean;

    @Prop({ type: Boolean, default: false })
    expand!: boolean;

    @Prop({ type: Boolean, default: false })
    checkboxes!: boolean;

    @Prop({ type: Boolean, default: false })
    loading!: boolean;

    @Prop({ type: Boolean, default: true })
    sortable!: boolean;

    @Prop({ type: Boolean, default: false })
    draggable!: boolean;

    @Prop({ type: String, default: "id" })
    idField!: string;

    @Prop({ default: false, type: Boolean })
    bubbleEvent!: boolean;

    @Prop({ type: Boolean, default: true })
    write!: boolean;

    @PropSync("selectedIds")
    syncedSelected!: any[];

    private sortIndex: null | string = null;
    private desc = true;
    private showMenu = false;

    private childDesc = false;
    private childSortIndex: null | string = null;

    private selectedItem: null | any = null;

    private x = 0;
    private y = 0;

    private drag = false;

    private open: { [id: number]: boolean } = {};

    get menuId(): number | null {
        if (this.selectedItem && this.showMenu) {
            return this.selectedItem.id;
        } else {
            return null;
        }
    }

    get itemList() {
        if (this.sortIndex) {
            return [...this.items].sort((a, b) => {
                if (this.sortIndex) {
                    return (
                        this.sortItems(a, b, this.sortIndex) *
                        (this.desc ? 1 : -1)
                    );
                } else {
                    return 1;
                }
            });
        } else {
            return this.items;
        }
    }

    get hasItems(): boolean {
        return this.itemList && this.itemList.length > 0;
    }

    get columns(): TableHeader[] {
        if (this.headers) {
            return this.headers.filter((item) => item.visible);
        } else {
            return [];
        }
    }

    get childrenColumns(): TableHeader[] {
        if (this.childHeaders) {
            return this.childHeaders.filter((item) => item.visible);
        } else {
            return [];
        }
    }

    get menuActions(): TableAction[] {
        return this.actions.filter((item) => item.active);
    }

    mounted(): void {
        if (this.headers.length && this.sortIndex == null) {
            this.sortIndex = this.headers[0].property;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private selectId(item: any, multiple: boolean): void {
        if (item && item[this.idField]) {
            if (this.syncedSelected.includes(item[this.idField])) {

                /* Id needs to be removed */
                this.emitEvent("untoggle", item);
                if (this.multiSelect || multiple) {
                    this.syncedSelected.splice(
                        this.syncedSelected.indexOf(item[this.idField]),
                        1
                    );
                } else {
                    this.syncedSelected = [];
                }
            } else {
                /* Add Id to list */
                this.emitEvent("toggle", item);
                if (this.multiSelect || multiple) {
                    this.syncedSelected.push(item[this.idField]);
                } else {
                    this.syncedSelected = [item[this.idField]];
                }
            }
        }
    }

    private sortItems(a: any, b: any, prop: string): number {
        if (a.hasOwnProperty(prop) && b.hasOwnProperty(prop)) {
            /* Compare the two */
            if (typeof a[prop] == "string") {
                return this.compareStrings(a[prop], b[prop]);
            } else if (typeof a[prop] == "number") {
                return a[prop] > b[prop] ? 1 : -1;
            }
            return 1;
        } else if (a.hasOwnProperty(prop)) {
            return -1;
        } else if (b.hasOwnProperty(prop)) {
            return 1;
        } else {
            return -1;
        }
    }

    private compareStrings(a: string, b: string): number {
        if (a && a.length && b && b.length) {
            return a.localeCompare(b, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        } else if (a && a.length) {
            return -1;
        } else if (b && b.length) {
            return 1;
        } else {
            return -1;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private doubleClick(item: any): void {
        this.emitEvent("double-click", item);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private rightClick(event: { x: number; y: number; item: any }): void {
        this.selectedItem = event.item;
        this.x = event.x;
        this.y = event.y;

        this.$nextTick(() => {
            this.showMenu = true;
        });
    }

    private emitEvent(eventId: string, item: any): void {
        if (this.bubbleEvent) {
            this.$emit("table-click", { id: eventId, item: item });
        } else {
            this.$emit(eventId, item);
        }
    }
}
