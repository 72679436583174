import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Users from "@/store/modules/Users";
import { Permission } from "@/graphql/API";

const usersModule = getModule(Users);

@Component
export default class PermissionCheck extends Vue {
    get userPermission(): Permission | null {
        return usersModule.currentPermissions;
    }

    get permissionsLoading(): boolean {
        return usersModule.loading;
    }

    get adminAccess(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.admin;
        } else {
            return false;
        }
    }

    get factorWrite(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.fc_w;
        } else {
            return false;
        }
    }

    get factorRead(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.fc_r;
        } else {
            return false;
        }
    }

    get choiceWrite(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.ch_w;
        } else {
            return false;
        }
    }

    get choiceRead(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.ch_r;
        } else {
            return false;
        }
    }

    get viewpointWrite(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.vp_w;
        } else {
            return false;
        }
    }

    get viewpointRead(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.vp_r;
        } else {
            return false;
        }
    }

    get choiceValueWrite(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.va_w;
        } else {
            return false;
        }
    }

    get choiceValueRead(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.va_r;
        } else {
            return false;
        }
    }

    get scoreRuleRead(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.sc_r;
        } else {
            return false;
        }
    }

    get scoreRuleWrite(): boolean {
        if (this.userPermission && !this.permissionsLoading) {
            return this.userPermission.sc_w;
        } else {
            return false;
        }
    }
}
