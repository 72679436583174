import { render, staticRenderFns } from "./FactorAIView.vue?vue&type=template&id=49303970&scoped=true&"
import script from "./FactorAIView.vue?vue&type=script&lang=ts&"
export * from "./FactorAIView.vue?vue&type=script&lang=ts&"
import style0 from "./FactorAIView.vue?vue&type=style&index=0&id=49303970&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49303970",
  null
  
)

export default component.exports