import {
    DefaultClassSets,
    Factor,
    FactorCreateInputL,
    ModelFilters,
} from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Factors from "@/store/modules/Factors";
import Scoring from "@/store/modules/Scoring";
import Viewpoints from "@/store/modules/Viewpoints";
import { getModule } from "vuex-module-decorators";

export function reorderFactors(
    factor: Factor,
    parentId: number,
    newIndex: number,
    oldIndex: number,
    currentTreeLevel: Factor[]
): string {
    //Define variables for 2 string values which new order_str will be between
    let beforeFactor = null;
    let afterFactor = null;

    //If factor is moved to 0 index - left string will be null and right string will be 0 index order_str
    if (newIndex === 0) {
        beforeFactor = "0";
        afterFactor = currentTreeLevel[1]?.order_str;
    }
    // If factor moved to last index of group - right side order_str will be null, left string will be last factor.order_str in group
    else if (newIndex === currentTreeLevel.length - 1) {
        afterFactor = null;
        beforeFactor = currentTreeLevel[newIndex - 1]?.order_str;
    }
    //If factor is moved to earlier index in group - new order_str needs to be between newIndex -1 and newIndex + 1 since newIndex will have the moved Factors order_str
    //These multi use cases might not need to be separated from each other but I'll keep them separate in case the functionality get's more complicated
    else if (newIndex < oldIndex) {
        beforeFactor = currentTreeLevel[newIndex - 1]?.order_str;
        afterFactor = currentTreeLevel[newIndex + 1]?.order_str;
    }
    //If factor is moved to later index in group - new order_str needs to be between newIndex -1 and newIndex + 1 since newIndex will have the moved Factors order_str
    else if (oldIndex < newIndex) {
        beforeFactor = currentTreeLevel[newIndex - 1]?.order_str;
        afterFactor = currentTreeLevel[newIndex + 1]?.order_str;
    }
    //If newIndex == oldIndex - factor has been moved been moved from a different group
    else if (oldIndex === newIndex) {
        beforeFactor = currentTreeLevel[newIndex - 1]?.order_str;
        afterFactor = currentTreeLevel[newIndex + 1]?.order_str;
    }
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const mudder = require("mudder");
    console.log(
        `BEFORE ${oldIndex} After ${newIndex} LEngth ${currentTreeLevel.length}`
    );
    console.log(`BEFORE ${beforeFactor} After ${afterFactor}`);
    //Generate a new order_str value between beforeFactor and afterFactor order_strings
    const order = mudder.base62.mudder(
        beforeFactor,
        afterFactor,
        1,
        undefined,
        20
    )[0];
    return order;
}

export function updateOrderParentId(
    factor: Factor,
    parentId: number,
    orderStr: string,
    changeParentId: boolean,
): void {
    const modelModule = getModule(Factors);
    if (factor.is_group) {
        modelModule.updateGroupL({
            id: factor.id,
            name: factor.name,
            ordering: orderStr,
            parent_id: changeParentId ? parentId : undefined,
            // m_index: factor.m_index,
            // m_score: factor.m_score,
            // score_rule_id: factor.score_rule_id,
            // index_rule_id: factor.index_rule_id,
            // weight: factor.weight,
        });
        return;
    } else if (factor.is_table) {
        modelModule.updateTableL({
            id: factor.id,
            name: factor.name,
            ordering: orderStr,
            parentId: changeParentId ? parentId : undefined,
            // m_index: factor.m_index,
            // m_score: factor.m_score,
            // score_rule_id: factor.score_rule_id,
            // index_rule_id: factor.index_rule_id,
            // weight: factor.weight,
        });
    } else if (factor.is_column) {
        modelModule.updateColumnL({
            factorId: factor.id,
            name: factor.name,
            ordering: orderStr,
            // m_index: factor.m_index,
            // m_score: factor.m_score,
            // score_rule_id: factor.score_rule_id,
            // index_rule_id: factor.index_rule_id,
            // weight: factor.weight,
        });
    } else {
        modelModule.updateFactorL({
            id: factor.id,
            name: factor.name,
            // value_type: factor.value_type,
            ordering: orderStr,
            parent_id: changeParentId ? parentId : undefined,
            // m_index: factor.m_index,
            // m_score: factor.m_score,
            // m_score: 0,
            // score_rule_id: factor.score_rule_id,
            // index_rule_id: factor.index_rule_id,
            // weight: factor.weight,
            // unit_id: factor.unit_id,
        });
    }
}

export async function updateFactorL(payload: {
    factor: Factor;
    name?: string;
    parentId?: number;
    valueType?: string;
    options?: string;
    format?: string;
    description?: string;
    m_score?: number;
    m_index?: number;
    weight?: number;
    score_rule_id?: number;
    index_rule_id?: number;
    set_id?: number;
    unit_id?: number;
    min?: number;
    max?: number;
}): Promise<void> {
    const factor = payload.factor;
    const name = payload.name;
    const parentId = payload.parentId;
    const valueType = payload.valueType;
    const options = payload.options;
    const format = payload.format;
    const m_score = payload.m_score;
    const m_index = payload.m_index;
    const weight = payload.weight;
    const score_rule_id = payload.score_rule_id;
    const index_rule_id = payload.index_rule_id;
    const set_id = payload.set_id;
    const unit_id = payload.unit_id;
    const description = payload.description;
    const min = !payload.min && payload.min != 0 ? null : payload.min;
    const max = !payload.max && payload.max != 0 ? null : payload.max;
    const modelModule = getModule(Factors);
    if (factor.is_group) {
        await modelModule.updateGroupL({
            id: factor.id,
            description: description,
            name: name,
            ordering: factor.order_str,
            parent_id: parentId,
            json: options,
            m_index: m_index,
            m_score: m_score,
            weight: weight,
            score_rule_id: score_rule_id,
            index_rule_id: index_rule_id,
            set_id: set_id,
        });
        return;
    } else if (factor.is_table) {
        await modelModule.updateTableL({
            id: factor.id,
            name: name,
            description: description,
            ordering: factor.order_str,
            parentId: parentId,
            json: options,
            m_index: m_index,
            m_score: m_score,
            weight: weight,
            score_rule_id: score_rule_id,
            index_rule_id: index_rule_id,
            set_id: set_id,
        });
    } else if (factor.is_column) {
        await modelModule.updateColumnL({
            factorId: factor.id,
            name: name,
            description: description,
            ordering: factor.order_str,
            json: options,
            m_index: m_index,
            m_score: m_score,
            weight: weight,
            score_rule_id: score_rule_id,
            index_rule_id: index_rule_id,
            set_id: set_id,
        });
    } else {
        await modelModule.updateFactorL({
            id: factor.id,
            name: name,
            description: description,
            value_type: valueType,
            ordering: factor.order_str,
            parent_id: parentId,
            json: options,
            format: format,
            m_index: m_index,
            m_score: m_score,
            weight: weight,
            score_rule_id: score_rule_id,
            index_rule_id: index_rule_id,
            set_id: set_id,
            unit_id: unit_id,
            min: min,
            max: max,
        });
    }
}

export async function createFactorL(payload: {
    names: string[];
    order_str: string[];
    valueType: string;
    parentId: number;
    isColumn: boolean;
    description?: string;
    options?: string;
    format?: string;
    useManualScore?: number;
    useManualIndex?: number;
    unit_id?: number;
    enum_ids?: number[];
    weight: number;
    score_rule_id?: number;
    index_rule_id?: number;
    set_id?: number;
    decision_id?: number;
    min?: number;
    max?: number;
}): Promise<Factor[] | null> {
    const modelModule = getModule(Factors);
    const names = payload.names;
    const order_str = payload.order_str;
    const valueType = payload.valueType;
    const description = payload.description;
    const parentId = payload.parentId ? payload.parentId : -1;
    const options = payload.options;
    const format = payload.format;
    const isColumn = payload.isColumn;
    const m_score = payload.useManualScore;
    const m_index = payload.useManualIndex;
    const unit_id = payload.unit_id;
    const enum_ids = payload.enum_ids;
    const weight = payload.weight;
    const defaultIds = getDefaultIds(valueType);
    // const decision_id = getModule(Decisions).selectedDecisionId;
    const score_rule_id = payload.score_rule_id
        ? payload.score_rule_id
        : defaultIds.score_rule_id;
    const index_rule_id = payload.index_rule_id
        ? payload.index_rule_id
        : defaultIds.index_rule_id;
    const set_id = payload.set_id ? payload.set_id : defaultIds.set_id;
    let fac = null;
    const decision_id = payload.decision_id
        ? payload.decision_id
        : getModule(Decisions).selectedDecisionId;
    //0 is a 'falsy' value but we want to allow it to be set so check if min and max are undefined accounting for possible valid 0
    const min = !payload.min && payload.min != 0 ? null : payload.min;
    const max = !payload.max && payload.max != 0 ? null : payload.max;
    switch (valueType) {
        case "table":
            fac = await modelModule.createTableL({
                parentId: parentId,
                names: names,
                description: description,
                ordering: order_str,
                json: options,
                m_score: m_score,
                m_index: m_index,
                weight: weight,
                score_rule_id: score_rule_id,
                index_rule_id: index_rule_id,
                set_id: set_id,
            });
            break;

        case "group":
            fac = await modelModule.createGroupL({
                parentId: parentId,
                names: names,
                description: description,
                ordering: order_str,
                json: options,
                m_score: m_score,
                m_index: m_index,
                weight: weight,
                score_rule_id: score_rule_id,
                index_rule_id: index_rule_id,
                set_id: set_id,
            });
            break;
        default:
            if (isColumn) {
                fac = await modelModule.createColumnL({
                    parentId: parentId,
                    names: names,
                    description: description,
                    ordering: order_str,
                    value_type: valueType,
                    json: options,
                    format: format,
                    unit_id: unit_id,
                    m_score: m_score,
                    m_index: m_index,
                    weight: weight,
                    score_rule_id: score_rule_id,
                    index_rule_id: index_rule_id,
                    set_id: set_id,
                });
            } else {
                fac = await modelModule.createFactorL({
                    parentId: parentId,
                    names: names,
                    description: description,
                    ordering: order_str,
                    value_type: valueType,
                    json: options,
                    format: format,
                    unit_id: unit_id,
                    m_score: m_score,
                    m_index: m_index,
                    enum_ids: enum_ids,
                    weight: weight ?? 1,
                    score_rule_id: score_rule_id,
                    index_rule_id: index_rule_id,
                    set_id: set_id,
                    min: min,
                    max: max,
                });
            }
    }
    //Fetch and get viewpointMappings for viewpoint that is loaded in state
    // if (fac != null) {
    //     const vpModule = getModule(Viewpoints);
    //     if (vpModule.viewpointMappings) {
    //         vpModule.fetchViewpointMappings(vpModule.selectedViewpointId);
    //     }
    // }

    return fac;
}

export function getNextOrderStrs(
    factorTree: Factor[],
    numberOfOrderStrs: number
): string[] {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const mudder = require("mudder");
    if (factorTree) {
        const endFactor = factorTree.at(-1);
        if (endFactor != null) {
            const order = mudder.base62.mudder(
                endFactor.order_str,
                "",
                numberOfOrderStrs,
                undefined,
                100
            );
            return order;
        } else {
            return mudder.base62.mudder(
                "0",
                undefined,
                numberOfOrderStrs,
                undefined,
                100
            );
        }
    }
    return mudder.base62.mudder(
        "0",
        undefined,
        numberOfOrderStrs,
        undefined,
        20
    );
}

export function filterFactor(factor: Factor, filters: ModelFilters): boolean {
    let type = true;
    let search = true;

    if (filters.hasOwnProperty("search") && filters.search.length > 0) {
        search = factor.name
            .toLowerCase()
            .includes(filters.search.toLowerCase());
    }

    if (filters.hasOwnProperty("type") && filters.type.length > 0) {
        if (factor.is_table && filters.type.includes("table")) {
            type = true;
        } else if (factor.is_group && filters.type.includes("group")) {
            type = true;
        } else {
            type = filters.type.includes(factor.type);
        }
    }
    return type && search;
}

export function getAttributeFromJson(
    json: string,
    valueToRead: string,
    defaultValue: string
): string {
    if (json) {
        try {
            const jsonString = JSON.parse(json);
            return jsonString[valueToRead];
        } catch (e) {
            // Try / Catch block in case there is an issue with the JSON string loaded from Backend
            return defaultValue;
        }
    }

    return defaultValue;
}

async function createRuleForFactor(
    factorName: string,
    type: string,
    decision_id: number,
    is_index_rule: boolean,
    is_index_score: boolean
): Promise<number> {
    const name = `${factorName} - Rule`;
    const scoringModule = getModule(Scoring);
    let rule = null;
    switch (type) {
        case "table":
            rule = await scoringModule.createScoreRuleAggregate({
                name: name,
                null_value_score: 0,
                aggregate_function: "wsum",
                decision_id: decision_id,
            });
            break;

        case "group":
            rule = await scoringModule.createScoreRuleAggregate({
                name: name,
                null_value_score: 0,
                aggregate_function: "wsum",
                decision_id: decision_id,
            });

            break;
        case "number":
            rule = await scoringModule.createRangeRuleL({
                name: name,
                null_value_score: 0,
                min_range_score: 0,
                decision_id: decision_id,
                index_uses_score: false,
                interpolate_range: true,
                is_index_rule: is_index_rule,
            });
            break;
        default:
            rule = await scoringModule.createMatchRuleL({
                name: name,
                null_value_score: 0,
                no_match_score: 0,
                decision_id: decision_id,
                is_index_rule: is_index_rule,
            });
            break;
    }
    return rule ? rule.id : -1;
}

function getDefaultIds(factorValueType: string) {
    // we are using rule ids from decision with id:-2;
    const orgIdToDefaultRuleMap = getModule(Scoring).defaultRules;
    let newScoreRuleId: undefined | number;
    let newIndexRuleId: undefined | number;
    let newSetId: undefined | number;
    switch (factorValueType) {
        case "number":
            newIndexRuleId = orgIdToDefaultRuleMap[-6].id;
            newScoreRuleId = orgIdToDefaultRuleMap[-5].id;
            newSetId = getDefaultGroupSetId() ?? -1;
            break;
        case "date_time":
            newIndexRuleId = orgIdToDefaultRuleMap[-10].id;
            newScoreRuleId = orgIdToDefaultRuleMap[-7].id;
            newSetId = getDefaultDateAndTimeSetId() ?? -1;
            break;
        case "category":
            newIndexRuleId = orgIdToDefaultRuleMap[-4].id;
            newScoreRuleId = orgIdToDefaultRuleMap[-3].id;
            newSetId = getDefaultCategorySetId() ?? -1;
            break;
        case "group":
            newIndexRuleId = orgIdToDefaultRuleMap[-9].id;
            newScoreRuleId = orgIdToDefaultRuleMap[-8].id;
            newSetId = getDefaultGroupSetId() ?? -1;
            break;
        case "table":
            newIndexRuleId = orgIdToDefaultRuleMap[-9].id;
            newScoreRuleId = orgIdToDefaultRuleMap[-8].id;
            newSetId = getDefaultCategorySetId() ?? -1;
            break;
        default:
            newIndexRuleId = orgIdToDefaultRuleMap[-2].id;
            newScoreRuleId = orgIdToDefaultRuleMap[-1].id;
            newSetId = getDefaultFactorSetId() ?? -1;
            break;
    }
    return {
        score_rule_id: newScoreRuleId,
        index_rule_id: newIndexRuleId,
        set_id: newSetId,
    };
}

function getDefaultFactorSetId(): number | undefined {
    const scoreModule = getModule(Scoring);
    const defaultClassSets = scoreModule.defaultClassSets;
    if (defaultClassSets && defaultClassSets[-1]) {
        return defaultClassSets[-1][0].id;
    } else {
        return undefined;
    }
}

function getDefaultGroupSetId(): number | undefined {
    const scoreModule = getModule(Scoring);
    const defaultClassSets = scoreModule.defaultClassSets;
    if (defaultClassSets && defaultClassSets[-2]) {
        return defaultClassSets[-2][0].id;
    } else {
        return undefined;
    }
}

function getDefaultCategorySetId(): number | undefined {
    const scoreModule = getModule(Scoring);
    const defaultClassSets = scoreModule.defaultClassSets;
    if (defaultClassSets && defaultClassSets[-3]) {
        return defaultClassSets[-3][0].id;
    } else {
        return undefined;
    }
}

function getDefaultDateAndTimeSetId(): number | undefined {
    const scoreModule = getModule(Scoring);
    const defaultClassSets = scoreModule.defaultClassSets;
    if (defaultClassSets && defaultClassSets[-4]) {
        return defaultClassSets[-4][0].id;
    } else {
        return undefined;
    }
}
