import { render, staticRenderFns } from "./WorkspaceEditor.vue?vue&type=template&id=7a08a3e0&scoped=true&"
import script from "./WorkspaceEditor.vue?vue&type=script&lang=ts&"
export * from "./WorkspaceEditor.vue?vue&type=script&lang=ts&"
import style0 from "./WorkspaceEditor.vue?vue&type=style&index=0&id=7a08a3e0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a08a3e0",
  null
  
)

export default component.exports