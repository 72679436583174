import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { API, Auth } from "aws-amplify";
import {
    getAllUsers,
    getMyPermissions,
} from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import Vue from "vue";
import { Permission, PermissionIdInput, User } from "@/graphql/API";
import {
    CreatePermission,
    CreatePermissionInput,
    DeletePermission,
    GetMyPermissions,
    InviteUser,
    InviteUserInput,
    InviteUserL,
    InviteUserLInput,
    RedeemPermission,
    UpdatePermission,
    UpdatePermissionInput,
} from "@/graphql/custom";
import {
    createPermission,
    deletePermission,
    inviteUser,
    inviteUserL,
    redeemPermission,
    updatePermission,
} from "@/graphql/mutations";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";

config.rawError = true;

const name = "users";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class UsersModule extends VuexModule {
    currentUserId: string | null = null;
    currentUser: User | null = null;
    currentPermissions: Permission | null = null;
    loading = false;

    @Mutation
    setLoading(val: boolean): void {
        this.loading = val;
    }

    @Mutation
    setCurrentUser(user: any | undefined): void {
        this.currentUserId = user.sub;
        this.currentUser = {
            id: user.sub,
            first: user.given_name,
            last: user.family_name,
            email: user.email,
        };
    }

    @Mutation
    setMyPermissions(permission: Permission | null): void {
        this.currentPermissions = permission;
    }

    @Action
    async updateLoading(val: boolean): Promise<void> {
        this.setLoading(val);
    }

    @Action
    async selectUser(user: any | undefined): Promise<string | undefined> {
        this.setCurrentUser(user);
        return user;
    }

    @Action
    async fetchAllUsers(): Promise<undefined> {
        await GRAPHQL_API.graphqlQueryRequest({
            query: getAllUsers,
            variables: {},
        });
        return;
    }

    @Action
    async updateUser(payload: {
        inputFirst: string;
        inputLast: string;
    }): Promise<void> {
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
            given_name: payload.inputFirst,
            family_name: payload.inputLast,
        });
        user = await Auth.currentAuthenticatedUser();
        console.log(user);
        this.setCurrentUser(user.attributes);
    }

    @Action
    async updateUserPassword(payload: {
        oldPassword: string;
        newPassword: string;
    }): Promise<void> {
        const user = await Auth.currentAuthenticatedUser();
        try {
            await Auth.changePassword(
                user,
                payload.oldPassword,
                payload.newPassword
            );
        } catch (e: any) {
            throw e;
        }

        console.log(user);
    }

    @Action
    async createPermissions(
        input: CreatePermissionInput
    ): Promise<Permission | null | undefined> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: createPermission,
            variables: { input },
        })) as GraphQLResult<CreatePermission>;
        return res.data?.createPermission;
    }

    @Action
    async inviteUser(input: InviteUserInput): Promise<User | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: inviteUser,
            variables: { input },
        })) as GraphQLResult<InviteUser>;
        const user = res.data?.inviteUser;
        if (user) {
            return user;
        } else {
            return null;
        }
    }

    @Action
    async inviteUserL(input: InviteUserLInput): Promise<boolean | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: inviteUserL,
            variables: { ...input },
        })) as GraphQLResult<InviteUserL>;
        const user = res.data?.inviteUserL;
        if (user) {
            return user;
        } else {
            return null;
        }
    }

    @Action
    async redeemPermission(code: string): Promise<boolean | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: redeemPermission,
            variables: { code },
        })) as GraphQLResult<RedeemPermission>;
        const user = res.data?.redeemPermission;
        if (user) {
            return user;
        } else {
            return null;
        }
    }

    @Action
    async updatePermissions(payload: {
        id: PermissionIdInput;
        input: UpdatePermissionInput;
    }): Promise<Permission | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: updatePermission,
            variables: { ...payload },
        })) as GraphQLResult<UpdatePermission>;
        const permission = res.data?.updatePermission;
        if (permission) {
            return permission;
        } else {
            return null;
        }
    }

    @Action
    async removePermissions(id: PermissionIdInput): Promise<Permission | null> {
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: deletePermission,
            variables: { id },
        })) as GraphQLResult<DeletePermission>;
        const permission = res.data?.deletePermission;
        if (permission) {
            return permission;
        } else {
            return null;
        }
    }

    @Action
    async fetchMyPermissions(
        workspace_id: number | null
    ): Promise<Permission | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getMyPermissions,
            variables: {},
        })) as GraphQLResult<GetMyPermissions>;
        const permissions = res.data?.getMyPermissions;

        if (permissions && workspace_id) {
            const currWorkspacePermission = permissions.filter(
                (permission) => (permission.workspace_id === workspace_id)
            );
            if (currWorkspacePermission.length > 0) {
                this.setMyPermissions(currWorkspacePermission[0]);
                return currWorkspacePermission[0];
            }
        } else {
            return null;
        }
        return null;
    }
}
