
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, Comment, Factor, Viewpoint } from "@/graphql/API";
import Comments from "@/store/modules/Comments";
import UsersModule from "@/store/modules/Users";
import RichTextEditor from "../ui/RichTextEditor.vue";
import CommentDisplay from "@/components/comments/CommentDisplay.vue";

const commentModule = getModule(Comments);
const usersModule = getModule(UsersModule);
@Component({
    components: { RichTextEditor, CommentDisplay },
})
export default class CommentList extends Vue {
    @Prop()
    thread_id!: number;

    @Prop()
    factor!: Factor;

    @Prop()
    choice!: Choice;

    @Prop()
    viewpoint!: Viewpoint;

    private show = false;
    private changeLoading = false;
    private internalComments: Comment[] = [];

    private newCommentMessage = "";
    private commentEditId: number | null = null;
    private replyToActive: number | null = null;
    private abc: any = null;
    private otherComments: Comment[] = [];

    async mounted() {
        this.fetchComments();
    }

    private getUserName(user_id: string): string {
        return "Chris Ellard";
    }

    get comments(): Comment[] {
        return commentModule.keyedComments[this.thread_id];
    }

    private async saveComment(
        val: string,
        replyTo: number | null
    ): Promise<void> {
        this.newCommentMessage = " ";
        const newComment = await commentModule.createComment({
            thread_id: this.thread_id,
            comment: val,
            reply_to: replyTo,
        });
        this.newCommentMessage = "";
    }

    private async deleteComment(commentId: number) {
        await commentModule.deleteComment(commentId);
    }

    private async updateComment(payload: {
        comment: string;
        id: number;
    }): Promise<void> {
        const comment = payload.comment;
        const id = payload.id;

        await commentModule.updateComment({ comment: comment, id: id });
    }

    private updateEditCommentId(val: number): void {
        this.commentEditId = val;
    }

    @Watch("thread_id")
    private async fetchComments() {
        this.changeLoading = true;
        this.internalComments = await commentModule.fetchComments(
            this.thread_id
        );
        this.changeLoading = false;
        /*
        this.otherComments = await commentModule.fetchOtherComments({
            thread_id: this.thread_id,
            factor_id: this.factor.id,
            choice_id: this.choice.id,
        });
        */
    }

    private activateReplyTo(replyToId: number, index: number): void {
        this.replyToActive = replyToId;
        this.abc = index;
    }

    private cancelReplyTo(): void {
        this.replyToActive = null;
        this.newCommentMessage = "";
    }
}
